import React from "react";
import styled from "styled-components";
import logo from "../../resources/svgs/Logo";
import { getParentOrigin, useQueryParams } from "../../utils/utils";
import { HowItWorksLink } from "./HowItWorksLink";

const Logo = styled.a`
  position: absolute;
  left: -6px;
  bottom: 10px;
  cursor: pointer;
`;

const HowItWorks = styled.div`
  position: absolute;
  right: 9px;
  bottom: 10px;
  cursor: pointer;

  a {
    textdecoration: none;
  }
`;

export function Footer() {
  const { helpLink } = useQueryParams();
  return (
    <>
      <Logo href="https://rewindprotocol.com/" target="_blank">{logo}</Logo>
      {getParentOrigin() !== helpLink && helpLink && helpLink !== 'null' && (
        <HowItWorks>
          <HowItWorksLink />
        </HowItWorks>
      )}
    </>
  );
}
