import React, { useEffect } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { ActionRequered } from "../../../shared/components/ActionRequired";
import { Text } from "../../../shared/components/Text";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import Install from "../../../resources/images/installMetamask.png";
import { Footer } from "../../../shared/components/Footer";
import { useQueryParams } from "../../../utils/utils";
import { HeaderTitle } from "../../../shared/components/HeaderTitle";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
`;

const ButtonWithMargin = styled(Button)`
  margin-top: 16px;
`;

export function InstallMetamask() {
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const addWalletListener = async () => {
    //@ts-ignore
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      //@ts-ignore
      window.ethereum.on("accountsChanged", (accounts) => {
        //@ts-ignore
        setWalletAddress(accounts[0]);
        //@ts-ignore
        console.log(accounts[0]);
      });
      navigateWithSearchParams({}, RoutesTypes.dashboard);
    } else {
      /* MetaMask is not installed */

      // setWalletAddress("");
      console.log("Please install MetaMask");
    }
  };

  useEffect(() => {
    addWalletListener();
  }, []);
  const { t } = useCustomTranslation();

  return (
    <Wrapper>
      <HeaderTitle title={t("installMetaMask")}/>
      <ActionRequered
        actionMessage="Metamask Offline"
        extraMessage={
          "Your Metamask on this device is switched off or unavailable. Please switch on or reconnect"
        }
        image={Install}
        imageSize={128}
        height={'440px'}
      >
        <a
          style={{ textDecoration: "none", width: "100%" }}
          href="https://metamask.io/download/"
          target="_blank"
          rel="noreferrer"
        >
          <Button size={ButtonSize.XL} fullWidth={true}>
            <Text
              size={TextSize.L}
              color={Color.SECONDARY}
              weight={TextWeight.BOLD}
            >
              Install Metamask
            </Text>
          </Button>
        </a>

        <ButtonWithMargin
          buttonType={ButtonType.WHITE_BORDER}
          size={ButtonSize.XL}
          onClick={() => {
            window.location.reload();
          }}
          fullWidth={true}
        >
          <Text
            size={TextSize.L}
            color={Color.SECONDARY}
            weight={TextWeight.BOLD}
          >
            Refresh
          </Text>
        </ButtonWithMargin>
      </ActionRequered>
      <Footer />
    </Wrapper>
  );
}
