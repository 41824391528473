import React, { useEffect, useMemo, useRef, useState } from "react";
import { ethers } from "ethers";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { Loading } from "../../../constants/Loading";
import TextAlign from "../../../constants/TextAlign";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { AppError } from "../../../shared/components/AppError";
import { AppLoading } from "../../../shared/components/AppLoader";
import { AppSuccess } from "../../../shared/components/AppSuccess";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { HashCopy } from "../../../shared/components/HashCopy";
import { Input, InputHeight } from "../../../shared/components/Input";
import { RewindLoader } from "../../../shared/components/RewindLoader";
import { Text } from "../../../shared/components/Text";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import {
  RedirectParentToIframeUrlIfSafari,
  convertStatus,
  formatPriceNumberWithCurrency,
  useQueryParams,
} from "../../../utils/utils";
import { ProcessStatus } from "../../cashback/components/Cashback";
import { ClaimTokens } from "../../cashback/components/ClaimTokens";
import { ConnectWallet } from "../../cashback/components/ConnectWallet";
import { ProcessOptions } from "../../cashback/components/ProcessOptions";
import {
  setBalanceIsPending,
  setShowSubmiTxMessage,
  TxHashEnum,
  WalletTypeEnum,
} from "../../cashback/redux/userInfo.slice";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { getAmount, getContractDetails, getOperatorAmount, TxStatus } from "../redux/actions";
import {
  setAmountForExchangeFreeze,
  setAmountResultFreeze,
} from "../redux/bonus.slice";
import { TagsList } from "./TagsList";
import { IncorrectAddressAction } from "../../SwitchWallet/components/IncorrectAddressActtion";
import { exchangeFWDtoUSDT, exchangeTokensToOperator } from "../utils/spentWithSponsorship";
import {
  DEFAULT_EIP155_METHODS,
  DEFAULT_EIP155_OPTIONAL_METHODS,
} from "../../../constants";
import { getNonceUrlProvider } from "../../../helpers/getNonceUrlProvider";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { getTxStatusBySid } from "../../cashback/redux/actions";
import { HeaderTitle } from "../../../shared/components/HeaderTitle";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../cashback/components/connectors";
import { ActionRequeredCB } from "../../../shared/components/ActionRequiredCB";
import { useDetectCoinbase } from "../../../shared/hooks/useDetectCoinbase";
import { ActionRequeredWC2 } from "../../../shared/components/ActionRequiredWC2";
import { CancelTx } from "./CancelTx";
import { apiGetAccountNonce } from "../../../helpers";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { signTypedData } from "@wagmi/core";
import { config } from "../../../layouts/config";
import { useConnect, useAccount } from "wagmi";

const ExchangetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0 4px;
  margin-top: 20px;
  height: 100%;
`;

export enum ProcessStatusExchange {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  CANCEL = "CANCEL",
  WAITING_SIGN = "WAITING_SIGN",
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
  INCORRECT_ADDRESS = "INCORRECT_ADDRESS",

  CONNECT_WALLET_INSTALL_METAMASK = "CONNECT_WALLET_INSTALL_METAMASK",
  CONNECT_WALLET_ERROR = "CONNECT_WALLET_ERROR",
  CONNECT_WALLET_NETWORK_ERROR = "CONNECT_WALLET_NETWORK_ERROR",
  CONNECT_WALLET_LOADING = "CONNECT_WALLET_LOADING",
  CONNECT_WALLET_SUCCESS = "CONNECT_WALLET_SUCCESS",

  CONNECT_WALLET = "CONNECT_WALLET",
}

export enum ExchangeType {
  OFFCHAIN = "OFFCHAIN",
  ONCHAIN = "ONCHAIN",
}

interface ExchangetWrapperProps {}

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    bottom: -12px; /* отступ вниз на 11 пикселей */
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
`;

const ButtonStyled = styled(Button)`
  margin-top: 16px;
`;

const ButtonWithMargin = styled(Button)`
  margin-top: 16px;
`;

const TextSecond = styled(Text)`
  line-height: 28px;
`;

const InputStyled = styled(Input)`
  margin-top: 16px;

  input {
    height: 46px;
    background: rgba(90, 171, 229, 0.1);
    border-radius: 4px;

    font-size: 16px;
    color: ${({ error }) =>
      error ? `${Color.RED_HOT_HOOD}` : `${Color.SECONDARY}`};
    font-weight: 700;
    border-color: none;

    &:hover,
    &:focus {
      border-color: rgba(111, 195, 255, 0.5);
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

const CurrencyTag = styled(Text)`
  position: absolute;
  top: 28px;
  right: 16px;
`;

const MaxButton = styled(Button)`
  position: absolute;
  top: 23px;
  right: 8px;
  background-color: ${Color.SECONDARY05};

  span {
    text-decoration: underline;
  }

  &:hover {
    background-color: ${Color.SECONDARY10};
  }

`;

const TextWithTopMargin = styled(Text)`
  margin-top: 28px;
`;

const TextWithTopMargin8 = styled(Text)`
  margin-top: 8px;
`;

const ButtomManageBlock = styled.div`
  position: absolute;
  bottom: 84px;
  background-color: ${Color.NAVIGATION_WRAPPER};
  padding: 12px 16px 12px 16px;
  border-radius: 12px;

  box-sizing: border-box;

  width: calc(100% - 8px);
`;

const LineInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background-color: rgba(37, 41, 61, 1);


`;

const TextInButton = styled(Text)`
  
`;

const ConnectWalletWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  flex-grow: 1;
  margin-bottom: 84px;
`;

export enum PercentTagsEnum {
  TEN = "10%",
  TWENTY_FIVE = "25%",
  FIFTY = "50%",
  SEVENTY_FIVE = "75%",
  ONE_HUNDRED = "100%",
  NULL = "",
}

export const DECIMALS_CONST = 10e17;

export function Exchange({}: ExchangetWrapperProps) {
  const { result: amountResult, loading: amountLoading } = useAppSelector(
    (state) => state.bonuses.amount
  );
  const [amountForExchange, setAmountForExchange] = useState(0);
  const { t } = useCustomTranslation();

  const amountForExchangeFreeze = useAppSelector(
    (state) => state.bonuses.exchange.amountForExchangeFreeze
  );
  const amountResultFreeze = useAppSelector(
    (state) => state.bonuses.exchange.amountResultFreeze
  );
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const commonInfo = useAppSelector((state) => state.userInfo.commonInfo);

  const tagsList = [
    PercentTagsEnum.TEN,
    PercentTagsEnum.TWENTY_FIVE,
    PercentTagsEnum.FIFTY,
    PercentTagsEnum.SEVENTY_FIVE,
    PercentTagsEnum.ONE_HUNDRED,
    // PercentTagsEnum.TEN
    "0",
  ];

  const TagsObject = {
    [PercentTagsEnum.TEN]: {
      value: 0.1,
    },
    [PercentTagsEnum.TWENTY_FIVE]: {
      value: 0.25,
    },
    [PercentTagsEnum.FIFTY]: {
      value: 0.5,
    },
    [PercentTagsEnum.SEVENTY_FIVE]: {
      value: 0.75,
    },
    [PercentTagsEnum.ONE_HUNDRED]: {
      value: 1,
    },
    [PercentTagsEnum.NULL]: {
      value: 0,
    },
  };
  const validateExchangeError = useMemo(
    () =>
      userInfo?.onChainBalance &&
      +amountForExchange > +userInfo?.onChainBalance / DECIMALS_CONST
        ? t("exchangeValidation")
        : "",
    [userInfo?.onChainBalance, amountForExchange]
  );

  const validateExchangeErrorOffchain = useMemo(
    () =>
      userInfo?.offChainBalance &&
      +amountForExchange > +userInfo?.offChainBalance / DECIMALS_CONST
        ? t("exchangeValidation")
        : "",
    [userInfo?.offChainBalance, amountForExchange]
  );


  const validateExchangeIfNoAddress = useMemo(
    () =>
      userInfo?.offChainBalance &&
      Number(userInfo?.offChainBalance) &&
      !Number(userInfo?.onChainBalance),
    [userInfo?.offChainBalance, amountForExchange]
  );

  const [activeTag, setActiveTag] = useState<PercentTagsEnum>(
    PercentTagsEnum.FIFTY
  );

  const [processStatus, setProcessStatus] = useState<ProcessStatusExchange>(
    ProcessStatusExchange.INITIAL
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  useEffectSkipFirst(() => {
    let amount;
    if (activeTagFromUrl !== ExchangeType.OFFCHAIN && userInfo?.onChainBalance && Number(userInfo?.onChainBalance)) {
      console.log(userInfo?.onChainBalance, "USER_BAL_ONCHAIN");
      if (Number(userInfo?.onChainBalance / DECIMALS_CONST) < 0.0001) {
        amount = 0;
        setAmountForExchange(amount);
        if (inputRef && inputRef.current) {
          inputRef.current.value = String(amount);
        }
      } else {
        amount = (userInfo?.onChainBalance || 0) * TagsObject[activeTag].value;
      }
    } else if (activeTagFromUrl === ExchangeType.OFFCHAIN && userInfo?.offChainBalance && Number(userInfo?.offChainBalance)) {
      console.log(userInfo?.offChainBalance, "USER_BAL_OFFCHAIN");
      if (Number(userInfo?.offChainBalance / DECIMALS_CONST) < 0.0001) {
        amount = 0;
        setAmountForExchange(amount);
        if (inputRef && inputRef.current) {
          inputRef.current.value = String(amount);
        }
      } else {
        amount = (userInfo?.offChainBalance || 0) * TagsObject[activeTag].value;
      }
    }

    if (amount) {
      const amountRound = Math.floor((amount / DECIMALS_CONST) * 10000) / 10000;

      setAmountForExchange(amountRound);
      if (inputRef && inputRef.current) {
        inputRef.current.value = String(amountRound);
      }
    }
  }, [activeTag, userInfo?.onChainBalance, userInfo?.offChainBalance]);

  useEffectSkipFirst(() => {
    const deadline = new Date(Date.now() + 86400000);
    if (
      amountForExchange &&
      (activeTagFromUrl === ExchangeType.OFFCHAIN
        ? !validateExchangeErrorOffchain
        : !validateExchangeError || validateExchangeIfNoAddress)
    ) {
      // dispatch(
      //   getAmount({
      //     amount: +amountForExchange,
      //     deadline,
      //     sid,
      //   })
      // );
      activeTagFromUrl === ExchangeType.OFFCHAIN
        ? dispatch(
            getOperatorAmount({
              amount: +amountForExchange,
              sid,
            })
          )
        : dispatch(
            getAmount({
              amount: +amountForExchange,
              deadline,
              sid,
            })
          );
    }
  }, [amountForExchange]);

  //TO DO - separate hook useTxHashSpy
  const activeTxHash = useAppSelector((state) => state.userInfo.activeTxHash);
  const activeTxHashStatus = useAppSelector(
    (state) => state.userInfo.activeTxHashStatus
  );
  const activeTxHashUsage = useAppSelector(
    (state) => state.userInfo.activeTxHashUsage
  );
  const exchangeString = `${formatPriceNumberWithCurrency({
    number: String(amountForExchangeFreeze),
  })} FWD → ${formatPriceNumberWithCurrency({
    number: `${
      amountResultFreeze && Math.round(+amountResultFreeze * 10000) / 10000
    }`,
  })} USDT`;

  useEffect(() => {
    if (processStatus !== ProcessStatusExchange.INITIAL) {
      if (
        activeTxHash &&
        activeTxHashStatus === TxStatus.MINED &&
        (activeTxHashUsage === TxHashEnum.EXCHANGE || activeTxHashUsage === TxHashEnum.EXCHANGE_OFFCHAIN)
      ) {
        setProcessStatus(ProcessStatusExchange.SUCCESS);
      }

      if (
        activeTxHashStatus === TxStatus.FAILED &&
        (activeTxHashUsage === TxHashEnum.EXCHANGE || activeTxHashUsage === TxHashEnum.EXCHANGE_OFFCHAIN)
      ) {
        setProcessStatus(ProcessStatusExchange.ERROR);
      }
    }

    if (processStatus === ProcessStatusExchange.SUCCESS) {
      if (!activeTxHash && !activeTxHashStatus && !activeTxHashUsage) {
        setProcessStatus(ProcessStatusExchange.INITIAL);
      }
    }

    if (processStatus === ProcessStatusExchange.ERROR) {
      if (!activeTxHash && !activeTxHashStatus && !activeTxHashUsage) {
        setProcessStatus(ProcessStatusExchange.ERROR);
      }
    }
  }, [activeTxHash, activeTxHashUsage, activeTxHashStatus, processStatus]);

  useEffect(() => {
    if (
      processStatus === ProcessStatusExchange.WAITING_CONFIRMATION &&
      hashCreated
    ) {
      dispatch(
        setBalanceIsPending({
          status: true,
          txHash: hashCreated,
          txHashUsage: activeTagFromUrl === ExchangeType.OFFCHAIN ? TxHashEnum.EXCHANGE_OFFCHAIN : TxHashEnum.EXCHANGE,
          txString: exchangeString,
        })
      );
    }
  }, [processStatus]);

  const { sid, isMobile } = useQueryParams();
  const [hashCreated, setHashCreated] = useState();

  const connectAndSign = async () => {
    if (!userInfo?.address) {
      return;
    }
    setProcessStatus(ProcessStatusExchange.WAITING_SIGN);
  };

  // const { client, session, connect, accounts } = useWalletConnectClient();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const { library, account, activate } = useWeb3React();

  const sign2 = async () => {
    if (amountForExchange === 0) {
      return;
    }

    RedirectParentToIframeUrlIfSafari();

    if (address?.toLowerCase() !== userInfo?.address?.toLowerCase()) {
      console.log('NAVIGATE TO SWITCH')
      navigateWithSearchParams({}, RoutesTypes.switchWallet);
      return;
    }
    setProcessStatus(ProcessStatusExchange.LOADING);
    try {
      dispatch(setAmountForExchangeFreeze({ amount: amountForExchange }));
      dispatch(setAmountResultFreeze({ amount: amountResult || 0 }));
      const plusDay = Math.floor(Date.now() / 1000) + 86400;
      const deadline = ethers.toQuantity(plusDay);

      const {
        Operator: { address: operatorContractAddress },
        Core: { address: tokenContractAddress, abi },
        ProtocolName: protocolName,
      } = await getContractDetails({ sid });

      const nonce = await getNonceUrlProvider(
        userInfo?.address || "",
        tokenContractAddress,
        abi
      );

      const message2 = {
        owner: userInfo?.address,
        spender: operatorContractAddress,
        value: ethers.parseEther(String(amountForExchange)).toString(),
        nonce,
        deadline,
      };

      const typedData = {
        types: {
          EIP712Domain: [
            {
              name: "name",
              type: "string",
            },
            {
              name: "version",
              type: "string",
            },
            {
              name: "chainId",
              type: "uint256",
            },
            {
              name: "verifyingContract",
              type: "address",
            },
          ],
          Permit: [
            {
              name: "owner",
              type: "address",
            },
            {
              name: "spender",
              type: "address",
            },
            {
              name: "value",
              type: "uint256",
            },
            {
              name: "nonce",
              type: "uint256",
            },
            {
              name: "deadline",
              type: "uint256",
            },
          ],
        },
        primaryType: "Permit",
        domain: {
          name: protocolName,
          version: "1",
          chainId: 137,
          verifyingContract: tokenContractAddress,
        },
        message: message2,
      };

      const message = JSON.stringify(typedData);
      let signature = "";
      setProcessStatus(ProcessStatusExchange.WAITING_SIGN);

      try {
        //@ts-ignore
        signature = await signTypedData(config, typedData);
      } catch (err) {
        setProcessStatus(ProcessStatusExchange.CANCEL);
      }

      const { r, s, v } = ethers.Signature.from(signature);
      console.log(r, s, v, "r, s, v");
      setProcessStatus(ProcessStatusExchange.LOADING);
      let res;
      try {
        dispatch(setShowSubmiTxMessage(true));
        res = await exchangeFWDtoUSDT({
          r,
          v: v,
          s,
          amount: amountForExchange,
          sid,
          deadline: plusDay,
        });
      } catch (err) {
        setProcessStatus(ProcessStatusExchange.ERROR);
      } finally {
        setTimeout(() => {
          dispatch(getTxStatusBySid({ sid })).then((result) => {
            if (
              !(
                result.type === "userInfo/getClaimStatusBySid/fulfilled" &&
                Array.isArray(result.payload) &&
                result.payload.length > 0
              )
            ) {
              setProcessStatus(ProcessStatusExchange.ERROR);
            }
          });
        }, 1000);
      }

      if (res) {
        setProcessStatus(ProcessStatusExchange.WAITING_CONFIRMATION);
        setHashCreated(res);
      }
    } catch (error) {
      setProcessStatus(ProcessStatusExchange.CANCEL);
      console.error("An error occurred:", error);
    } finally {
    }
  };

  // example of switching or adding network with Harmony Mainnet
  // const switchNetwork = async () => {
  //   try {
  //     await library.provider.request({
  //       method: "wallet_switchEthereumChain",
  //       params: [{ chainId: "0x63564c40" }],
  //     });
  //   } catch (switchError) {
  //     // 4902 error code indicates the chain is missing on the wallet
  //     //@ts-ignore
  //     if (switchError.code === 4902) {
  //       try {
  //         await library.provider.request({
  //           method: "wallet_addEthereumChain",
  //           params: [
  //             {
  //               chainId: "0x63564c40",
  //               rpcUrls: ["https://api.harmony.one"],
  //               chainName: "Harmony Mainnet",
  //               nativeCurrency: { name: "ONE", decimals: 18, symbol: "ONE" },
  //               blockExplorerUrls: ["https://explorer.harmony.one"],
  //               iconUrls: [
  //                 "https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png",
  //               ],
  //             },
  //           ],
  //         });
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //   }
  // };

  const sign3 = async () => {
    if (isMobile === "true") {
      navigateWithSearchParams({}, RoutesTypes.mobileCoinbase);
      return;
    }

    if (!account) {
      await activate(connectors.coinbaseWallet);
      return;
    }

    if (account?.toLowerCase() !== userInfo?.address?.toLowerCase()) {
      navigateWithSearchParams({}, RoutesTypes.switchWallet);
      return;
    }

    setProcessStatus(ProcessStatusExchange.LOADING);
    try {
      dispatch(setAmountForExchangeFreeze({ amount: amountForExchange }));
      dispatch(setAmountResultFreeze({ amount: amountResult || 0 }));
      const plusDay = Math.floor(Date.now() / 1000) + 86400;
      //@ts-ignore
      const deadline = ethers.hexlify(plusDay);

      const {
        Operator: { address: operatorContractAddress },
        Core: { address: tokenContractAddress, abi },
        ProtocolName: protocolName,
      } = await getContractDetails({ sid });

      // const nonce = await getNonceUrlProvider(
      //   account || "",
      //   tokenContractAddress,
      //   abi
      // );
      const nonce = await apiGetAccountNonce(
        userInfo?.address || "",
        "eip155:137"
      );

      const message2 = {
        owner: account,
        spender: operatorContractAddress,
        value: ethers.parseEther(String(amountForExchange)).toString(),
        nonce,
        deadline,
      };

      const typedData = {
        types: {
          EIP712Domain: [
            {
              name: "name",
              type: "string",
            },
            {
              name: "version",
              type: "string",
            },
            {
              name: "chainId",
              type: "uint256",
            },
            {
              name: "verifyingContract",
              type: "address",
            },
          ],
          Permit: [
            {
              name: "owner",
              type: "address",
            },
            {
              name: "spender",
              type: "address",
            },
            {
              name: "value",
              type: "uint256",
            },
            {
              name: "nonce",
              type: "uint256",
            },
            {
              name: "deadline",
              type: "uint256",
            },
          ],
        },
        primaryType: "Permit",
        domain: {
          name: protocolName,
          version: "1",
          chainId: 137,
          verifyingContract: tokenContractAddress,
        },
        message: message2,
      };

      setProcessStatus(ProcessStatusExchange.WAITING_SIGN);

      // await requestToChangeChain2(client, session!.topic);

      await library.provider
        .request({
          method: DEFAULT_EIP155_METHODS.ETH_SIGN_TYPED_DATA_V4,
          params: [account, typedData],
        })
        .then(async (signature: string) => {
          const { r, s, v } = ethers.Signature.from(signature);
          console.log(r, s, v, "r, s, v");
          setProcessStatus(ProcessStatusExchange.LOADING);
          let res;
          try {
            res = await exchangeFWDtoUSDT({
              r,
              v: v,
              s,
              amount: amountForExchange,
              sid,
              deadline: plusDay,
            });
          } catch (err) {
            setProcessStatus(ProcessStatusExchange.ERROR);
          } finally {
            setTimeout(() => {
              dispatch(getTxStatusBySid({ sid })).then((result) => {
                if (
                  !(
                    result.type === "userInfo/getClaimStatusBySid/fulfilled" &&
                    Array.isArray(result.payload) &&
                    result.payload.length > 0
                  )
                ) {
                  setProcessStatus(ProcessStatusExchange.ERROR);
                }
              });
            }, 1000);
          }

          if (res) {
            setProcessStatus(ProcessStatusExchange.WAITING_CONFIRMATION);
            setHashCreated(res);
          }
        });
    } catch (error) {
      setProcessStatus(ProcessStatusExchange.CANCEL);
      console.error("An error occurred:", error);
    } finally {
    }
  };

  const balanceIsPending = useAppSelector(
    (state) => state.userInfo.balanceIsPending
  );
  const [processStatusConverted, setProcessStatusConverted] =
    useState<ProcessStatus>(ProcessStatus.INITIAL);

  const processOptionsConvertStatus = (status: ProcessStatus) => {
    setProcessStatusConverted(status);
    const statusConverted = convertStatus(status);
    //@ts-ignore
    setProcessStatus(statusConverted);
  };

  const [showButton, setShowButton] = useState(true);

  // useEffectSkipFirst(() => {
  //   client &&
  //     client.on("session_event", (event) => {
  //       if (
  //         event.params.event.name === "chainChanged" &&
  //         event.params.event.data === 137
  //       ) {
  //         setTimeout(() => {
  //           setShowButton(true);
  //           sign2();
  //         }, 300);
  //       }
  //       console.log(event, "EVENT");
  //     });
  // }, []);

  const [toggleSign3, setToggleSign3] = useState(false);

  useEffect(() => {
    if (toggleSign3) {
      sign3();
    }
  }, [toggleSign3]);

  const { isCoinbase } = useDetectCoinbase();

  // const { open } = useWeb3Modal();
  const { connectors: connectors2, connect } = useConnect();
  const { open } = useWeb3Modal();
  const selectMethod = () => {
    if (!userInfo) return;


      if (activeTagFromUrl === ExchangeType.OFFCHAIN) {
        offchainExchange();

        return;
      }

      if (!address) {
        open();
        // connect({ connector: injected() });
        return;
      }

      sign2();
  
  };

  const [hideExchange, setHideExchange] = useState(false);
  const showSubmiTxMessage = useAppSelector(
    (state) => state.userInfo.showSubmiTxMessage
  );
  const { address } = useAccount();
  const acc = useAccount();

  useEffect(() => {
    console.log(acc, 'ACCOUNT')
  }, [acc])

  const { activeTag: activeTagFromUrl } = useQueryParams();

  // useEffect(() => {
  //   console.log(activeTagFromUrl, 'activeTagFromUrl');

  //   if (activeTagFromUrl !== ExchangeType.OFFCHAIN && !userInfo?.address) {
  //     setProcessStatus(ProcessStatusExchange.CONNECT_WALLET);
  //   }
  // }, [activeTagFromUrl])

  const offchainExchange = () => {
    console.log('PROCEED OFFCHAIN');
    setProcessStatus(ProcessStatusExchange.LOADING);

    exchangeTokensToOperator({ sid, amount: transformScientificNotation(`${amountForExchange * 10e17}`) }).then((res) => {
      dispatch(setAmountForExchangeFreeze({ amount: amountForExchange }));
      dispatch(setAmountResultFreeze({ amount: amountResult || 0 }));
      setProcessStatus(ProcessStatusExchange.WAITING_CONFIRMATION);
      setHashCreated(res.txHash);

      setTimeout(() => {
        dispatch(getTxStatusBySid({ sid })).then((result) => {
          if (
            !(
              result.type === "userInfo/getClaimStatusBySid/fulfilled" &&
              Array.isArray(result.payload) &&
              result.payload.length > 0
            )
          ) {
            setProcessStatus(ProcessStatusExchange.ERROR);
          }
        });
      }, 1000);

    }).catch((err) => {
      console.log(err, 'ERROR')
      setProcessStatus(ProcessStatusExchange.ERROR);
    })
  }

  function transformScientificNotation(input: string): string {
    const number: number = parseFloat(input);
    const stringWithZeroes: string = number.toLocaleString('fullwide', { useGrouping: false });
    return stringWithZeroes;
  }

  return (
    <>
      <HeaderTitle
        withoutBack={processStatus === ProcessStatusExchange.LOADING}
        title={
          activeTagFromUrl === ExchangeType.OFFCHAIN
            ? t("withdrawTokens")
            : t("getCrypto")
        }
        toMainPage={true}
      />
      {(processStatus === ProcessStatusExchange.INITIAL && (
        <>
          <ClaimTokens
            setShowNavLinks={() => {}}
            hideExchange={setHideExchange}
            exchangeType={activeTagFromUrl}
          />
          {/* {userInfo?.address ? (
            <ClaimTokens
              setShowNavLinks={() => {}}
              hideExchange={setHideExchange}
            />
          ) : (
            //@ts-ignore
            <ConnectWallet setProcessStatus={processOptionsConvertStatus} />
          )} */}
          {userInfo?.address || activeTagFromUrl === ExchangeType.OFFCHAIN ? (
            <ExchangetWrapper>
              <Flex>
                <Text
                  size={TextSize.XS}
                  color={Color.SECONDARY}
                  weight={TextWeight.BOLD}
                >
                  {t("withdrawTokensRate")}:
                </Text>

                <Text size={TextSize.XS} color={Color.SECONDARY60}>
                  1 FWD =
                  {activeTagFromUrl === ExchangeType.OFFCHAIN ? " $" : " "}
                  {Math.floor(
                    Number(userInfo?.poolRate) *
                      (activeTagFromUrl !== ExchangeType.OFFCHAIN
                        ? 5000000
                        : 10000000)
                  ) / 10000000}
                  {activeTagFromUrl === ExchangeType.OFFCHAIN ? "" : " USDT"}
                </Text>
              </Flex>

              <TextWithTopMargin
                size={TextSize.XS}
                color={Color.SECONDARY60}
                weight={TextWeight.REGULAR}
                align={TextAlign.LEFT}
              >
                {t("choosePercentageOrTypeAmount")}:
              </TextWithTopMargin>

              {/* <TagsList
                tagsList={tagsList}
                activeTag={activeTag}

                setActiveTag={(tag: string) => setActiveTag(tag)}
                withoutShadow={true}
                isCovered={true}
              ></TagsList> */}

              <InputWrapper>
                <InputStyled
                  ref={inputRef}
                  defaultValue={String(amountForExchange)}
                  onChange={(value) => {
                    setActiveTag(PercentTagsEnum.NULL);
                    setAmountForExchange(+value.target.value);
                  }}
                  type={"number"}
                  allowDecimals={true}
                  height={InputHeight.M}
                  error={
                    activeTagFromUrl === ExchangeType.OFFCHAIN
                      ? validateExchangeErrorOffchain
                      : validateExchangeError
                  }
                ></InputStyled>
                {/* <CurrencyTag size={TextSize.L} color={Color.SECONDARY50}>
                  FWD
                </CurrencyTag> */}
                <MaxButton
                  onClick={() => {
                    console.log(
                      userInfo?.onChainBalance,
                      "userInfo?.onChainBalance || 0"
                    );
                    if (activeTagFromUrl === ExchangeType.OFFCHAIN) {
                      if (!userInfo?.offChainBalance) return;

                      const amountRound =
                        Math.floor(
                          (userInfo?.offChainBalance / DECIMALS_CONST) * 10000
                        ) / 10000;

                      setAmountForExchange(amountRound);
                      if (inputRef && inputRef.current) {
                        inputRef.current.value = String(amountRound);
                      }
                    } else {
                      if (!userInfo?.onChainBalance) return;

                      const amountRound =
                        Math.floor(
                          (userInfo?.onChainBalance / DECIMALS_CONST) * 10000
                        ) / 10000;

                      setAmountForExchange(amountRound);
                      if (inputRef && inputRef.current) {
                        inputRef.current.value = String(amountRound);
                      }
                    }
                  }}
                  buttonType={ButtonType.SECONDARY}
                  disabled={
                    activeTagFromUrl === ExchangeType.OFFCHAIN
                      ? !!userInfo?.offChainBalance &&
                        amountForExchange ===
                          (Math.floor(
                            (userInfo?.offChainBalance / DECIMALS_CONST) * 10000
                          ) /
                            10000)
                      : !!userInfo?.onChainBalance &&
                        (amountForExchange ===
                          Math.floor(
                            (userInfo?.onChainBalance / DECIMALS_CONST) * 10000
                          ) /
                            10000)
                  }
                >
                  <TextInButton
                    size={TextSize.XS}
                    color={Color.SECONDARY80}
                    underline={true}
                  >
                    Max
                  </TextInButton>
                </MaxButton>
              </InputWrapper>

              <ButtomManageBlock>
                <LineInfo>
                  <Text
                    color={Color.SECONDARY60}
                    size={TextSize.XS}
                    weight={TextWeight.REGULAR}
                  >
                    {t("tokensToWithdraw")}:
                  </Text>

                  <Text
                    color={Color.SECONDARY80}
                    size={TextSize.XS}
                    weight={TextWeight.MEDIUM}
                  >
                    {formatPriceNumberWithCurrency({
                      number: `${amountForExchange}`,
                    })}{" "}
                    FWD
                  </Text>
                </LineInfo>

                <Separator />

                <LineInfo>
                  <Text
                    color={Color.SECONDARY60}
                    size={TextSize.XS}
                    weight={TextWeight.REGULAR}
                  >
                    {t("willRecieve")}:
                  </Text>

                  <Text
                    color={Color.SECONDARY80}
                    size={TextSize.XS}
                    weight={TextWeight.MEDIUM}
                  >
                    {activeTagFromUrl === ExchangeType.OFFCHAIN ? "$" : ""}
                    {amountResult
                      ? formatPriceNumberWithCurrency({
                          number: `${
                            Math.floor(amountResult * 1000000) / 1000000
                          }`,
                        })
                      : "0.00"}

                    {activeTagFromUrl === ExchangeType.OFFCHAIN ? "" : " USDT"}
                  </Text>
                </LineInfo>

                <ButtonWithMargin
                  size={ButtonSize.XL}
                  fullWidth={true}
                  onClick={selectMethod}
                  disabled={
                    activeTagFromUrl === ExchangeType.OFFCHAIN
                      ? !!validateExchangeErrorOffchain ||
                        !amountForExchange ||
                        balanceIsPending ||
                        showSubmiTxMessage
                      : !!validateExchangeError ||
                        !amountForExchange ||
                        balanceIsPending ||
                        showSubmiTxMessage
                  }
                >
                  {amountLoading === Loading.PENDING ? (
                    <RewindLoader
                      color={Color.SECONDARY}
                      size={68}
                      sizeUnit={"px"}
                    />
                  ) : (
                    <Text
                      size={TextSize.L}
                      color={Color.SECONDARY}
                      weight={TextWeight.BOLD}
                    >
                      {t("recieve")}{" "}
                      {formatPriceNumberWithCurrency({
                        number: `${
                          amountResult
                            ? Math.floor(amountResult * 1000000) / 1000000
                            : 0
                        }`,
                      })}{" "}
                      USDT
                    </Text>
                  )}
                </ButtonWithMargin>
              </ButtomManageBlock>
              {/* {!!(
                amountResult &&
                amountForExchange &&
                !validateExchangeError
              ) && (
                <TextWithMargin size={TextSize.XS} color={Color.SECONDARY50}>
                  {t("youWillRecieve", {
                    usdAmount: formatPriceNumberWithCurrency({
                      number: `${
                        amountResult && Math.round(amountResult * 10000) / 10000
                      }`,
                    }),
                    fwdAmount: formatPriceNumberWithCurrency({
                      number: `${amountForExchange}`,
                    }),
                  })}
                </TextWithMargin>
              )} */}
            </ExchangetWrapper>
          ) : (
            <ConnectWalletWrapper>
              {
                //@ts-ignore
                <ConnectWallet setProcessStatus={processOptionsConvertStatus} />
              }
            </ConnectWalletWrapper>
          )}
          {/* <button onClick={connect}>Connect</button> */}
        </>
      )) ||
        (processStatus === ProcessStatusExchange.LOADING && (
          <AppLoading
            fullWidth={true}
            message={t("loading")}
            fullHeight={true}
          ></AppLoading>
        )) ||
        (processStatus === ProcessStatusExchange.WAITING_SIGN &&
          (userInfo?.addressMemo === WalletTypeEnum.COINBASE ? (
            <ActionRequeredCB
              fullWidth={true}
              height={"calc(100vh - 100px)"}
            ></ActionRequeredCB>
          ) : (
            <ActionRequeredWC2
              fullWidth={true}
              height={"calc(100vh - 100px)"}
              // withWalletConnect={showButton}
            ></ActionRequeredWC2>
          ))) ||
        (processStatus === ProcessStatusExchange.SUCCESS && (
          <AppSuccess
            fullWidth={true}
            message={t("success")}
            extraMessage={exchangeString}
          >
            {hashCreated && <HashCopy hash={hashCreated} />}
            <ButtonStyled
              size={ButtonSize.XL}
              onClick={() => {
                setProcessStatus(ProcessStatusExchange.INITIAL);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("OK")}
              </Text>
            </ButtonStyled>
          </AppSuccess>
        )) ||
        (processStatus === ProcessStatusExchange.WAITING_CONFIRMATION && (
          <AppLoading
            fullWidth={true}
            message={t("purchaseInProgress")}
            fullHeight={true}
          >
            <TextSecond size={TextSize.M} color={Color.SECONDARY}>
              {t("waitingConfirmation")}
            </TextSecond>
            {hashCreated && <HashCopy hash={hashCreated} />}
            <ButtonStyled
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                setProcessStatus(ProcessStatusExchange.INITIAL);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonStyled>
          </AppLoading>
        )) ||
        (processStatus === ProcessStatusExchange.ERROR && (
          <AppError
            fullWidth={true}
            errorMessage={t("error")}
            extraMessage={t("errorCreatingTransaction")}
          >
            <ButtonStyled
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                setProcessStatus(ProcessStatusExchange.INITIAL);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonStyled>
          </AppError>
        )) ||
        (processStatus === ProcessStatusExchange.CANCEL && (
          <CancelTx
            addressMemo={userInfo?.addressMemo}
            selectMethod={selectMethod}
            setInitProcessStatus={() =>
              setProcessStatus(ProcessStatusExchange.INITIAL)
            }
          />
        )) ||
        ((processStatus === ProcessStatusExchange.CONNECT_WALLET_ERROR ||
          processStatus ===
            ProcessStatusExchange.CONNECT_WALLET_INSTALL_METAMASK ||
          processStatus === ProcessStatusExchange.CONNECT_WALLET_LOADING ||
          processStatus ===
            ProcessStatusExchange.CONNECT_WALLET_NETWORK_ERROR ||
          processStatus === ProcessStatusExchange.CONNECT_WALLET_SUCCESS) && (
          <ProcessOptions
            processStatus={processStatusConverted}
            //@ts-ignore
            setProcessStatus={processOptionsConvertStatus}
          />
        )) ||
        (processStatus === ProcessStatusExchange.INCORRECT_ADDRESS && (
          <IncorrectAddressAction
            callback={async () => {
              console.log("disconnect");
              //@ts-ignore
              // await walletConnectProvider.disconnect();
              connectAndSign();
            }}
          />
        ))}
      {/* {
          connectors2.map((connector) => (
            <button key={connector.uid} onClick={() => connect({ connector })}>
              {connector.name}
            </button>
          ))
        } */}
      {/* <button onClick={() => open()}>Connect</button>
       */}
      {/* {!!validateExchangeIfNoAddress && (
        <TextWithTopMargin8 size={TextSize.XS} color={Color.SECONDARY60}>
          {t("connectYourWalletToRecieveMoney")}
        </TextWithTopMargin8>
      )} */}
    </>
  );
}
