import styled from "styled-components";
import { Color } from "../../../../constants/ColorEnum";
import { RoutesTypes } from "../../../../constants/RoutesTypes";
import TextAlign from "../../../../constants/TextAlign";
import { TextSize } from "../../../../constants/TextSize";
import { TextWeight } from "../../../../constants/TextWeight";
import { Text } from "../../../../shared/components/Text";
import Toggle from "../../../../shared/components/Toggle";
import { Outlet } from "react-router";
import { useNavigateWithSearchParams } from "../../../../shared/hooks/useNavigateWithSearchParams";
import { useLocation } from "react-router";
import { useCustomTranslation } from "../../../locale/hooks/useCustomTranslation";
import { useParams } from "react-router";

const BreadcrumbsWrap = styled.div`
  display: flex;
  margin: 16px auto 16px 0;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: ${Color.SECONDARY80};
`;

const Link = styled.div`
  cursor: pointer;

  &:hover span {
    text-decoration: underline;
    color: ${Color.SECONDARY};
  }
`;

const TextStyled2 = styled(Text)`
  margin-right: 4px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ToggleWrap = styled.div`
  display: flex;
`;

export const CashbackSecondLevel = () => {
  const { navigateWithSearchParams } = useNavigateWithSearchParams();

  const location = useLocation();
  const { t } = useCustomTranslation();
  const { date } = useParams();

  return (
    <>
      <Header>
        <BreadcrumbsWrap>
          <Link
            onClick={() => {
              navigateWithSearchParams({}, RoutesTypes.historyCashbackFirst);
            }}
          >
            <Text
              align={TextAlign.LEFT}
              weight={TextWeight.BOLD}
              color={Color.SECONDARY80}
              size={TextSize.XS}
            >
              {t("cashback")}
            </Text>
          </Link>
          &nbsp;/&nbsp;
          <div>
            <Text
              align={TextAlign.LEFT}
              weight={TextWeight.BOLD}
              color={Color.SECONDARY80}
              size={TextSize.XS}
            >
              {date}
            </Text>
          </div>
        </BreadcrumbsWrap>

        <ToggleWrap>
          {/* <TextStyled2 color={Color.SECONDARY60} size={TextSize.XS}>
            {t("detailedView")}
          </TextStyled2>
          <Toggle
            checked={
              location.pathname === RoutesTypes.historyCashbackSecondDetailed
            }
            onToggleChanged={() => {
              // navigateWithSearchParams(
              //   {},
              //   location.pathname === RoutesTypes.historyCashbackSecondDetailed ? RoutesTypes.historyCashbackSecondSimplified: RoutesTypes.historyCashbackSecondDetailed
              // );
            }}
          /> */}
        </ToggleWrap>
      </Header>
      <Outlet />
    </>
  );
};
