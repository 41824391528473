import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import TooltipPlacement from '../../constants/TooltipPlacement';

interface HiddenTooltipProps {
  content: JSX.Element;
  timerDuration: number;
  children: JSX.Element;
  position?: TooltipPlacement
}

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

`;

const TooltipWrapper = styled.div<{ tooltipPlacement?:  TooltipPlacement}>`
  position: absolute;
  top: -51px;
  left: ${({tooltipPlacement}) => tooltipPlacement === TooltipPlacement.LEFT ? '-30px': '50%'};
  transform: translateX(-50%);
  z-index: 1;
  width: 100%;
`;

const HiddenTooltip: React.FC<HiddenTooltipProps> = ({ content, timerDuration, children, position }) => {
  const [showElement, setShowElement] = useState(false);
  const [hovered, setHovered] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (!hovered) {
      setHovered(true);
      setShowElement(true);
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setShowElement(false);
  };

  useEffect(() => {
    if (hovered) {
      timerRef.current = setTimeout(() => {
        setShowElement(false);
      }, timerDuration);
    } else {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [hovered, timerDuration]);

  return (
    <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {showElement && <TooltipWrapper tooltipPlacement={position}>{content}</TooltipWrapper>}
    </Wrapper>
  );
};

export default HiddenTooltip;
