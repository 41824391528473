import React from "react";

export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M23.5385 16H28C29.1046 16 30 15.1046 30 14V5C30 3.89543 29.1046 3 28 3H4C2.89543 3 2 3.89543 2 5V14C2 15.1046 2.89543 16 4 16H8.46154"
        stroke="white"
        stroke-width="2"
      />
      <path
        d="M9 11H23V27C23 28.1046 22.1046 29 21 29H11C9.89543 29 9 28.1046 9 27V11Z"
        stroke="white"
        stroke-width="2"
      />
      <path
        d="M6 11H26"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M16 16V24.5M16 24.5L19.5 20.5M16 24.5L12.5 20.5"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
