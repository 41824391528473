import React from 'react';
import styled from 'styled-components';
import { TextSize } from '../../constants/TextSize';
import { TextWeight } from '../../constants/TextWeight';
import { Text } from './Text';

const Label = styled(Text)`
    margin-bottom: 4px;
    display: flex;
`;

const RequiredAsterix = styled.div`
    color: #ff3000;
`;

export const InputLabel = ({
    textSize = TextSize.S,
    textWeight = TextWeight.SEMIBOLD,
    text,
    required = false,
}: {
    text: string;
    required?: boolean;
    textSize?: TextSize;
    textWeight?: TextWeight;
}) => (
    <Label size={textSize} weight={textWeight}>
        {text}
        {required && <RequiredAsterix>&nbsp;*</RequiredAsterix>}
    </Label>
);
