import React from "react";

export default (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.34375 5V3.65625H7.65625V5H6.34375ZM3.21875 10.7812C4.28125 11.8229 5.54167 12.3438 7 12.3438C8.45833 12.3438 9.70833 11.8229 10.75 10.7812C11.8125 9.71875 12.3438 8.45833 12.3438 7C12.3438 5.54167 11.8125 4.29167 10.75 3.25C9.70833 2.1875 8.45833 1.65625 7 1.65625C5.54167 1.65625 4.28125 2.1875 3.21875 3.25C2.17708 4.29167 1.65625 5.54167 1.65625 7C1.65625 8.45833 2.17708 9.71875 3.21875 10.7812ZM2.28125 2.3125C3.59375 1 5.16667 0.34375 7 0.34375C8.83333 0.34375 10.3958 1 11.6875 2.3125C13 3.60417 13.6562 5.16667 13.6562 7C13.6562 8.83333 13 10.4062 11.6875 11.7188C10.3958 13.0104 8.83333 13.6562 7 13.6562C5.16667 13.6562 3.59375 13.0104 2.28125 11.7188C0.989583 10.4062 0.34375 8.83333 0.34375 7C0.34375 5.16667 0.989583 3.60417 2.28125 2.3125ZM6.34375 10.3438V6.34375H7.65625V10.3438H6.34375Z"
      fill="white"
      fillOpacity="0.4"
    />
  </svg>
);
