import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { ActionRequered } from "../../../shared/components/ActionRequired";
import { Text } from "../../../shared/components/Text";
import { Button, ButtonSize } from "../../../shared/components/Button";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { requestToChangeChain } from "../../../utils/utils";
import NetworkError from "../../../resources/images/NetworkError.png";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { Footer } from "../../../shared/components/Footer";
import { HeaderTitle } from "../../../shared/components/HeaderTitle";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
`;

const ButtonWithMargin = styled(Button)`
  margin-top: 16px;
`;

export function SwithcNetwork() {
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  // const addChainIdListener = async () => {
  //   //@ts-ignore
  //   window.ethereum.on("chainChanged", (chainId) => {
  //     console.log(`ChainId changes. Current connected chainId is ${chainId}`);
  //     if (chainId == Number(process.env.REACT_APP_CHAIN_ID)) {
  //       console.log(`You are in the correct network.`);
  //       navigateWithSearchParams({}, RoutesTypes.dashboard);
  //     } else {
  //       console.log(
  //         `You are in the wrong network. Please switch to the network with chainId ${chainId}`
  //       );
  //     }
  //   });
  // };

  // useEffect(() => {
  //   addChainIdListener();
  // }, []);




  

  const { t } = useCustomTranslation();

  return (
    <Wrapper>
      <HeaderTitle title={t("switchNetwork")} withoutBack={true} />
      <ActionRequered
        actionMessage={t("wrongNetwork")}
        extraMessage={t("wrongNetworkText")}
        image={NetworkError}
        imageSize={180}
        height={"440px"}
      >
        <ButtonWithMargin
          size={ButtonSize.XL}
          onClick={() => {
            requestToChangeChain();
          }}
          fullWidth={true}
        >
          <Text
            size={TextSize.L}
            color={Color.SECONDARY}
            weight={TextWeight.BOLD}
          >
            {t("reconnect")}
          </Text>
        </ButtonWithMargin>
      </ActionRequered>
      <Footer />
    </Wrapper>
  );
}
