import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { useAppSelector } from "../../../app/hooks";
import { Text } from "../../../shared/components/Text";
import { Button, ButtonSize } from "../../../shared/components/Button";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { TagsList } from "./TagsList";
import {
  ScrollArea,
  ScrollAreaSize,
} from "../../../shared/components/ScrollArea";
import Scrollbars from "react-custom-scrollbars-2";
import { useQueryParams } from "../../../utils/utils";
import { BonusSchema, DefaultTagsEnum, TagsSchema } from "../redux/bonus.slice";
import { DECIMALS_CONST, Exchange } from "./Exchange";
import { DoubleCurrency } from "../../../shared/components/DoubleCurrency";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import TextAlign from "../../../constants/TextAlign";
import { SimpleBalance2 } from "../../../shared/components/SimpleBalance2";
import { whiteListForHistory } from "../../cashback/components/ClaimTokens";

const BonusListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: calc(100% - 0px);
  margin-top: 16px;

  ::-webkit-scrollbar {
    display: none;
  }
  margin-bottom: 35px;
`;

// height: ${({ balanceIsPending, headerCollapsed, availableForClaim }) =>
// balanceIsPending
//   ? (headerCollapsed ?`calc(100% - 235px)` :  `calc(100% - 300px)`)
//   : headerCollapsed
//     ? "calc(100% - 230px)"
//     : availableForClaim
//       ? "calc(100% - 365px)"
//       : "calc(100% - 290px)"};

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  background-color: ${Color.NAVIGATION_WRAPPER};
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 4px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;

  ::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
  }
`;

const ButtonWithMargin = styled(Button)`
  width: auto;
  min-width: 120px;
`;

interface BonusListProps {
  bonusList: BonusSchema[];
  tagsList: string[];
  setOpenItem: (bonus: BonusSchema) => void;
  emitBuyNow: (bonus: BonusSchema) => void;
}

const BonusListImage = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;

  background-color: ${Color.NAVIGATION_WRAPPER};
  border-radius: 8px;
  
  img:not([src]) {
    border-color: transparent;
  }
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  height: 100%;
  padding: 0 16px;
  text-align: left;
  width: calc(100% - 96px);
`;

const TextWithMarginTop = styled(Text)`
  margin-top: 8px;
  margin-right: auto;
`;

const TextWithMargin = styled(Text)`
  margin-bottom: 4px;
`;

const BottomPriceBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
`;

const HeaderWrap = styled.div`
  width: 100%;
`;

export function BonusList({
  bonusList,
  tagsList,
  setOpenItem,
  emitBuyNow,
}: BonusListProps) {
  const { activeTag: activeTagFromUrl } = useQueryParams();
  const balanceIsPending = useAppSelector(
    (state) => state.userInfo.balanceIsPending
  );

  useEffect(() => {
    if (activeTagFromUrl === DefaultTagsEnum.REAL_MONEY) {
      setActiveTag(activeTagFromUrl);
    }
  }, [activeTagFromUrl]);

  const [activeTag, setActiveTag] = useState<string>("All");
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const scrollRef = useRef<Scrollbars>(null);
  const { t } = useCustomTranslation();
  const commonInfo = useAppSelector((state) => state.userInfo.commonInfo);
  const [headerCollapsedOnScroll, setHeaderCollapsedOnScroll] = useState<undefined | boolean>();
  const [headerCollapsedOnClick, setHeaderCollapsedOnClick] = useState<undefined | boolean>();
  const [headerCollapsed, setHeaderCollapsed] = useState(false);

  useEffect(() => {
    setHeaderCollapsed(!!headerCollapsedOnScroll);
  }, [headerCollapsedOnScroll]);

  useEffect(() => {
    setHeaderCollapsed(!!headerCollapsedOnClick);
  }, [headerCollapsedOnClick]);

  const showSubmiTxMessage = useAppSelector(state => state.userInfo.showSubmiTxMessage);

  return (
    <>
    <HeaderWrap onClick={() => setHeaderCollapsedOnClick(headerCollapsedOnClick === undefined ? true : !headerCollapsedOnClick)}>
      <SimpleBalance2 headerCollapsed={headerCollapsed} />
    </HeaderWrap>

      <TextWithMarginTop
        color={Color.SECONDARY80}
        weight={TextWeight.BOLD}
        size={TextSize.XS}
        align={TextAlign.LEFT}
      >
        {t("buyBonuses")}
      </TextWithMarginTop>
      {/* <TagsList
        tagsList={tagsList}
        activeTag={activeTag}
        setActiveTag={setActiveTag}
        isCovered={false}
      /> */}
      <BonusListWrapper>
        {activeTag === DefaultTagsEnum.REAL_MONEY ? (
          <>
            <Exchange />
          </>
        ) : (
          <ScrollArea
            size={ScrollAreaSize.S}
            ref={scrollRef}
            hideHorizontalTrack={true}
            onUpdate={(data) => setHeaderCollapsedOnScroll(data.scrollTop > 1)}
          >
            {bonusList
              ?.filter((bonus) => bonus.isActive)
              ?.filter((bonus) => whiteListForHistory.find((el) => el === userInfo?.userId) ? true : bonus.title !== "test")
              ?.filter((bonus) =>
                activeTag === DefaultTagsEnum.ALL
                  ? true
                  : bonus?.tags?.find((tag) => tag.name === activeTag)
              )
              .map((bonusItem) => (
                <CardWrapper
                  onClick={() => setOpenItem(bonusItem)}
                  key={"item_" + bonusItem.id}
                >
                  <Wrapper>
                    <BonusListImage src={bonusItem.iconUrl} />
                    <InfoColumn>
                      <TextWithMargin
                        color={Color.COLOR_BLUE_TAG}
                        size={TextSize.XXS}
                      >
                        {bonusItem.tags
                          ?.filter(
                            (tag: TagsSchema) =>
                              tag.name !== DefaultTagsEnum.ALL
                          )
                          .map((tag: TagsSchema) => tag.name)
                          .join(", ")}
                      </TextWithMargin>
                      <Text
                        size={TextSize.M}
                        weight={TextWeight.MEDIUM}
                        color={Color.SECONDARY}
                      >
                        {bonusItem.title}
                      </Text>
                    </InfoColumn>
                  </Wrapper>
                  <BottomPriceBlock>
                    <DoubleCurrency
                      value={String(
                        commonInfo.info?.poolRate
                          ? bonusItem.usdPrice / +commonInfo.info?.poolRate
                          : "0"
                      )}
                      valueUsd={String(bonusItem.usdPrice)}
                      texSizeFwd={TextSize.XS}
                      textSizeUsd={TextSize.XXS}
                      textHeightFWD={'16px'}
                      withFwdSign={true}
                      margin={0}
                      textColorUsd={Color.COLOR_BLUE_TAG}
                      marginRight={"auto"}
                    ></DoubleCurrency>

                    <ButtonWithMargin
                      size={ButtonSize.M}
                      disabled={
                        (
                          // userInfo?.address
                          // ? !!userInfo?.usdtOnChainBalance &&
                          //   +userInfo.usdtOnChainBalance / DECIMALS_CONST <
                          //     +bonusItem.usdPrice
                          // : 
                          !!userInfo?.offChainBalance &&
                            (+userInfo.offChainBalance / DECIMALS_CONST) *
                            //@ts-ignore
                              userInfo?.poolRate <
                              +bonusItem.usdPrice) ||
                        !bonusItem.isActive ||
                        balanceIsPending || showSubmiTxMessage
                      }
                      onClick={() => {
                        emitBuyNow(bonusItem);
                      }}
                    >
                      <Text
                        color={Color.SECONDARY}
                        weight={TextWeight.SEMIBOLD}
                        size={TextSize.M}
                      >
                        {t("buyNow")}
                      </Text>
                    </ButtonWithMargin>
                  </BottomPriceBlock>
                </CardWrapper>
              ))}
          </ScrollArea>
        )}
      </BonusListWrapper>
    </>
  );
}
