import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { LangEnum } from "./locale.slice";

export type TranslationResult = {key: string, value: string}[];

export const getTranslations = createAsyncThunk(
  "locale/fetchTranslation",
  async ({ locale } : { locale: LangEnum }): Promise<TranslationResult> => {
    const {
      data
    } = await axios.get<Promise<TranslationResult>>(`${process.env.REACT_APP_API_URL}/language/get-translations-by-language?lang=${locale.toLowerCase()}`);

    return data;
  }
);
