import React from "react";

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        d="M14 7L9.00002 12L14 17"
        stroke="white"
        stroke-width="1.6"
        stroke-linecap="round"
      />
    </g>
  </svg>
);
