import styled from "styled-components";

interface ShadowScrollWrapperProps {
  children?: string | JSX.Element;
  showTopShadow: boolean;
}

const Gradient = styled.div`
  position: absolute;
  top: -185px;
  pointer-events: none;
  z-index: 1;
  width: 32px;
  height: 100vw;
  left: 44vw;
  transform: rotate(270deg);
  background: linear-gradient(
    270deg,
    #161925 0%,
    #161925 13.89%,
    rgba(22, 25, 37, 0) 100%
  );
`;

const Gradient2 = styled.div`
  position: absolute;

  bottom: -190px;
  pointer-events: none;
  z-index: 1;
  width: 32px;
  height: 100vw;
  left: 44vw;
  transform: rotate(90deg);
  background: linear-gradient(
    270deg,
    #161925 0%,
    #161925 13.89%,
    rgba(22, 25, 37, 0) 100%
  );
`;

const ListWrapper = styled.div`
  height: calc(100% - 220px);
  width: 100%;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ShadowScrollWrapper = ({
  showTopShadow,
  children,
}: ShadowScrollWrapperProps) => {
  return (
    <ListWrapper>
      {showTopShadow && <Gradient />}
      {children}
      {<Gradient2 />}
    </ListWrapper>
  );
};
