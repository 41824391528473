import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/auth/redux/auth.slice';
import modalHubReducer from '../shared/redux/modalHub.slice';
import bonusesReducer from '../features/BuyBonuses/redux/bonus.slice';
import userInfoReducer from '../features/cashback/redux/userInfo.slice';
import localeReducer from '../features/locale/redux/locale.slice';
import historyReducer from '../features/History/redux/history.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    counter: counterReducer,
    modalHub: modalHubReducer,
    bonuses: bonusesReducer,
    userInfo: userInfoReducer,
    locale: localeReducer,
    history: historyReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
