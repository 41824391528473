import { polygon } from "wagmi/chains";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { DEFAULT_PROJECT_ID } from "../constants";

const metadata = {
  name: "Rewind",
  description: "Rewind protocol",
  url: "",
  icons: [],
};

export const config = defaultWagmiConfig({
  chains: [polygon],
  projectId: DEFAULT_PROJECT_ID,
  metadata,
});
