import React from "react";

export default (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="12" r="12" fill="#238FDC" />
    <path
      d="M8.99048 7.21388H13.3087C13.8378 7.21388 14.3141 7.2974 14.7375 7.46445C15.1608 7.63151 15.5207 7.86642 15.817 8.16921C16.1239 8.46155 16.3568 8.81131 16.5155 9.2185C16.6849 9.61525 16.7696 10.0433 16.7696 10.5027C16.7696 11.2545 16.5685 11.8705 16.1663 12.3507C15.7747 12.8206 15.2032 13.1703 14.4517 13.4L18.5 18.3333H15.7218L11.8481 13.6506H11.2924V18.3333H8.99048V13.6506H6.926L6.5 11.7086H8.99048V7.21388ZM13.0388 11.7086C13.4727 11.7086 13.822 11.6042 14.0866 11.3954C14.3512 11.1761 14.4835 10.8786 14.4835 10.5027C14.4835 10.1164 14.3512 9.81885 14.0866 9.61003C13.822 9.40122 13.4727 9.29681 13.0388 9.29681H11.2924V11.7086H13.0388Z"
      fill="white"
    />
    <path
      d="M8.90251 6.39167C8.712 6.39167 8.53208 6.35512 8.36274 6.28204C8.20398 6.20895 8.0611 6.11499 7.93409 6.00014C7.80709 5.87485 7.70654 5.72868 7.63246 5.56163C7.55837 5.39457 7.52133 5.21708 7.52133 5.02915C7.52133 4.84121 7.55837 4.66372 7.63246 4.49667C7.70654 4.32961 7.80709 4.18866 7.93409 4.07382C8.0611 3.94853 8.20398 3.84934 8.36274 3.77625C8.53208 3.70317 8.712 3.66663 8.90251 3.66663C9.29411 3.66663 9.62221 3.80236 9.8868 4.07382C10.162 4.33484 10.2996 4.65328 10.2996 5.02915C10.2996 5.40501 10.162 5.72868 9.8868 6.00014C9.62221 6.26116 9.29411 6.39167 8.90251 6.39167Z"
      fill="white"
    />
  </svg>
);
