import React from "react";

export default (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="56" rx="28" />
    <g opacity="0.1">
      <path
        d="M40 40H22.4C20.1598 40 19.0397 40 18.184 39.564C17.4314 39.1805 16.8195 38.5686 16.436 37.816C16 36.9603 16 35.8402 16 33.6V16M20 32L25.3333 26.6667L30.6667 32L38.6667 24M38.6667 24V29.3333M38.6667 24H33.3333"
        stroke="white"
        strokeWidth="1.77778"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
