import React, { ReactNode } from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";
import { Icon } from "./Icon";
import { Text } from "./Text";

export type SuccessProps = {
  message: string;
  extraMessage?: string;
  code?: string;
  children?: ReactNode;
  fullWidth?: boolean;
};

const Wrapper = styled.div<{fullWidth?: boolean}>`
  width: ${({fullWidth}) => fullWidth ? '100%' : 'calc(100% - 16px)'};
  box-sizing: border-box;
  height: calc(100% - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
`;

const TextStyled = styled(Text)`
  margin-top: 16px;
  margin-bottom: 8px;
`;

const IconStyled = styled(Icon)`
  svg path {
    fill: ${Color.BACKGROUND_COLOR};
  }

  svg circle {
    fill: ${Color.BACKGROUND_COLOR};
  }
`;

export function AppSuccess({
  message,
  extraMessage,
  children,
  fullWidth,
}: SuccessProps) {
  return (
    <Wrapper fullWidth={fullWidth}>
      <IconStyled icon={IconType.success} size={100}/>
      <TextStyled size={TextSize.XXL} weight={TextWeight.BOLD} color={Color.SECONDARY}>{message}</TextStyled>
      <Text size={TextSize.M} color={Color.SECONDARY50}>{extraMessage}</Text>
      {children}
    </Wrapper>
  );
}
