import React, { useState } from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";
import { TextSize } from "../../constants/TextSize";
import { truncateMiddle } from "../../utils/utils";
import { Icon, IconSize } from "./Icon";
import { Text } from "./Text";

export type HashCopyProps = {
  hash: string;
  isAddress?: boolean;
  contentFit?: boolean;
  textColor?: Color;
  tooltipLeft?: string;
  tooltipTop?: string;
};

const HashLinkWrapper = styled.div<{ contentFit?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  width: ${({ contentFit }) => (contentFit ? "auto" : "280px")};
  padding-left: 15px;
  justify-content: center;
`;

const IconCopy = styled(Icon)`
  cursor: pointer;
  margin-top: 6px;
  margin-left: 8px;

  svg path {
    fill: none;
  }
`;

const HashTooltip = styled.div<{ left?: string; top?: string }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 280px;
  height: 60px;

  background: rgba(18, 20, 30, 0.9);
  border-radius: 4px;
  z-index: 3;
  box-sizing: border-box;
  top: ${({ top }) => (top ? top : "-60px")};
  left: ${({ left }) => (left ? left : "15px")};
`;

const TextStyled = styled(Text)`
  text-decoration: underline;
`;

export function HashCopy({
  hash,
  isAddress,
  textColor,
  contentFit,
  tooltipLeft,
  tooltipTop,
}: HashCopyProps) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <HashLinkWrapper contentFit={contentFit}>
      {isAddress ? (
        <a
          target="_blank"
          href={`${process.env.REACT_APP_ADDRESS_URL + hash}`}
          rel="noreferrer"
        >
          <TextStyled
            size={TextSize.M}
            color={textColor ? textColor : Color.SECONDARY}
            underline={true}
          >
            {truncateMiddle(hash, 22)}
          </TextStyled>
        </a>
      ) : (
        <a
          target="_blank"
          href={`${process.env.REACT_APP_HASH_URL + hash}`}
          rel="noreferrer"
        >
          <TextStyled
            size={TextSize.M}
            color={Color.SECONDARY50}
            underline={true}
          >
            {truncateMiddle(hash, 22)}
          </TextStyled>
        </a>
      )}
      <div
        onClick={() => {
          setShowTooltip(true);
          setTimeout(() => setShowTooltip(false), 2000);
          navigator.clipboard.writeText(
            `${isAddress ? process.env.REACT_APP_ADDRESS_URL + hash : process.env.REACT_APP_HASH_URL + hash}`
          );
        }}
      >
        <IconCopy
          icon={
            isAddress
              ? textColor
                ? IconType.copy2
                : IconType.copy
              : IconType.copy2
          }
          size={IconSize.L}
        />
      </div>
      {showTooltip && (
        <HashTooltip left={tooltipLeft} top={tooltipTop}>
          <TextStyled
            size={TextSize.XS}
            color={Color.SECONDARY}
            breakWords={true}
          >
            {hash}
          </TextStyled>
        </HashTooltip>
      )}
    </HashLinkWrapper>
  );
}
