import React from "react";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { Color } from "../../constants/ColorEnum";
import TextAlign from "../../constants/TextAlign";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";
import { WhiteSpace } from "../../constants/WhiteSpace";
import { IncreaseWithTooltip } from "../../features/cashback/components/IncreaseWithTooltip";
import { formatPriceNumberWithCurrency } from "../../utils/utils";
import { Text } from "./Text";

export type DoubleCurrencyProps = {
  value: number | string;
  valueUsd?: number | string;
  texSizeFwd?: TextSize;
  textSizeUsd?: TextSize;
  withFwdSign?: boolean;
  margin?: number;
  marginRight?: string;
  marginLeft?: string;
  textColorUsd?: Color;
  withIncreaseTooltip?: boolean;
  increaseTooltipMargin?: string;
  textHeightFWD?: string;
  TextWeightFWD?: TextWeight;
  textHeightUSD?: string;
  hideUsd?: boolean;
  cutZeros?: boolean;
  isUSDT?: boolean;
  fwdSignColor?: Color;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TextStyled = styled(Text)<{
  margin?: number;
  marginRight?: string;
  marginLeft?: string;
  textHeightUSD?: string;
}>`
  margin-top: ${({ margin }) => (margin !== undefined ? `${margin}px` : "4px")};
  text-align: right;

  margin-right: ${({ marginRight }) =>
    marginRight !== undefined ? `${marginRight}` : "0"};
  margin-left: ${({ marginLeft }) =>
    marginLeft !== undefined ? `${marginLeft}` : "0"};

  line-height: ${({ textHeightUSD }) =>
    textHeightUSD ? `${textHeightUSD}` : "auto"};
`;

const Wrap = styled.div`
  display: flex;
`;

const TextFWD = styled(Text)<{ textHeightFWD?: string }>`
  line-height: ${({ textHeightFWD }) =>
    textHeightFWD ? `${textHeightFWD}` : "auto"};
`;

const MarginTop = styled.div<{ increaseTooltipMargin: string }>`
  margin-top: ${({ increaseTooltipMargin }) => `${increaseTooltipMargin}`};
`;

export function DoubleCurrency({
  value,
  valueUsd,
  texSizeFwd,
  textSizeUsd,
  withFwdSign,
  margin,
  marginRight,
  marginLeft,
  textColorUsd,
  withIncreaseTooltip,
  increaseTooltipMargin,
  textHeightFWD,
  TextWeightFWD,
  textHeightUSD,
  hideUsd,
  cutZeros,
  isUSDT,
  fwdSignColor
}: DoubleCurrencyProps) {
  const poolRate = useAppSelector(
    (state) => state.userInfo.commonInfo.info?.poolRate
  );

  return (
    <Wrapper>
      <TextFWD
        size={texSizeFwd || TextSize.XXL}
        weight={TextWeightFWD || TextWeight.BOLD}
        color={Color.SECONDARY}
        align={TextAlign.RIGHT}
        textHeightFWD={textHeightFWD}
      >
        { cutZeros ? Math.floor(Number(value)) : formatPriceNumberWithCurrency({
          number: `${value}`
        })}
        {withFwdSign && <Text color={fwdSignColor || Color.SECONDARY} size={fwdSignColor ? TextSize.XS : (texSizeFwd || TextSize.XXL)} weight={TextWeightFWD || TextWeight.BOLD}>&nbsp;FWD</Text>}
      </TextFWD>
      {!hideUsd && (
        <Wrap>
          {withIncreaseTooltip && (
            <MarginTop increaseTooltipMargin={increaseTooltipMargin || "7px"}>
              <IncreaseWithTooltip />
            </MarginTop>
          )}

          <TextStyled
            size={textSizeUsd || TextSize.XS}
            color={textColorUsd || Color.SECONDARY50}
            margin={margin}
            marginRight={marginRight}
            marginLeft={marginLeft}
            textHeightUSD={textHeightUSD}
            whiteSpace={WhiteSpace.NOWRAP}
          >
            {isUSDT ? '' : '$'}
            {formatPriceNumberWithCurrency({
              number: `${valueUsd ? valueUsd : poolRate && +value * +poolRate}`,
            })}{" "}
            {isUSDT ? 'USDT' : ''}
          </TextStyled>
        </Wrap>
      )}
    </Wrapper>
  );
}
