import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { ActionRequered } from "../../../shared/components/ActionRequired";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { Footer } from "../../../shared/components/Footer";
import { HeaderTitle } from "../../../shared/components/HeaderTitle";
import { Icon } from "../../../shared/components/Icon";
import { Text } from "../../../shared/components/Text";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { useQueryParams } from "../../../utils/utils";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0 8px;
`;

const ButtonWithMargin = styled(Button)`
  margin-top: 16px;
`;

export const MobileMetamask = () => {
  const { sid, foreignId, lang } = useQueryParams();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const { t } = useCustomTranslation();

  return (
    <Wrapper>
      <HeaderTitle title={t("switchToMetamaskApp")} />

      <ActionRequered
        actionMessage={t("actionRequired")}
        extraMessage={t(`needSwitchToMetamask`)}
      >
        <ButtonWithMargin
          onClick={() => {
            window.parent.location.href =
              "" +
              process.env.REACT_APP_MOBILE_APP_CONST +
              process.env.REACT_APP_IFRAME_HOST_WITHOUT_HTTP +
              `?sid=${sid}&foreignId=${foreignId}&lang=${lang}`;
          }}
          size={ButtonSize.XL}
          fullWidth={true}
        >
          <Text
            size={TextSize.L}
            color={Color.SECONDARY}
            weight={TextWeight.BOLD}
          >
            {t("goToMetamaskBrowser")}
          </Text>
        </ButtonWithMargin>

        <ButtonWithMargin
          buttonType={ButtonType.WHITE_BORDER}
          size={ButtonSize.XL}
          onClick={() => navigateWithSearchParams({}, RoutesTypes.dashboard)}
          fullWidth={true}
        >
          <Text
            size={TextSize.L}
            color={Color.SECONDARY}
            weight={TextWeight.BOLD}
          >
            {t("backToWidget")}
          </Text>
        </ButtonWithMargin>
      </ActionRequered>
      <Footer />
    </Wrapper>
  );
};
