// import moment from "moment";
import { ethers } from "ethers";
import { useMemo } from "react";

// import { useHistory } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { DEFAULT_CHAINS } from "../constants";
// import { CellValue } from "react-table";

export const truncateMiddle = (word: string, tooLongChars: number) => {
  if (typeof word !== "string") {
    return;
  }

  if (word.length < tooLongChars) {
    return word;
  }

  const ellipsis = "...";
  const charsOnEitherSide = Math.floor(tooLongChars / 2) - ellipsis.length;

  return (
    word?.slice(0, charsOnEitherSide) +
    ellipsis +
    word?.slice(-charsOnEitherSide)
  );
};

export const isUUID = (uuid: string) => {
  return !!("" + uuid).match(
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
  );
};

export const useQueryParams = (): { [key: string]: string } | any => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  return new Proxy(params, {
    get(target, prop) {
      if (typeof prop === "string") {
        return target.get(prop);
      }
    },
  });
};

type QueryParamsObject = Record<string, string>;

interface UseQueryParams {
  setQueryParams: (queryParamsObject: QueryParamsObject) => void;
}

export const useSetQueryParams = () => {
  const setQueryParams = (queryParamsObject: QueryParamsObject) => {
    // Use the history object from withRouter
    const currentSearchParams = new URLSearchParams(window.location.search);

    // Merge the existing query parameters with the new ones
    for (const key in queryParamsObject) {
      if (queryParamsObject.hasOwnProperty(key)) {
        currentSearchParams.set(key, queryParamsObject[key]);
      }
    }

    // Update the URL with the new query parameters
    window.history.replaceState({}, "", `?${currentSearchParams.toString()}`);
  };

  return { setQueryParams };
};

export const LinkForGrades = ({
  param,
  text,
  type,
}: {
  param: string;
  text: string;
  type: string;
}) => {
  return type === "userId" ? (
    <NavLink to={`/grades?operatorId=${param}`}>{text}</NavLink>
  ) : (
    <div>{text}</div>
  );
};

export const getError = (error: {
  response: { data: { message: string | string[] } };
}) => {
  const { message } = error?.response?.data;
  return typeof message === "string" ? message : message?.join(",");
};

export const formatPriceNumberWithCurrency = ({
  number,
  cutZeros = false,
}: {
  number: string;
  cutZeros?: boolean;
}) => {
  const isSmallNum = Number(number) < 0.01 && Number(number) > 0;
  const options = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: isSmallNum ? 6 : 2,
  };
  const roundConst = isSmallNum ? 1000000 : 100;
  number = (Math.floor(+number * roundConst) / roundConst).toString();

  let result = parseFloat(number).toLocaleString("en-US", options);
  const final = result.substr(result.lastIndexOf(",") + 1);

  if (cutZeros && final.includes("00")) {
    result =
      result?.slice(0, -5) + result?.slice(result.length - 2, result.length);
  }
  result = result.slice(1);

  return result || "";
};

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const requestToChangeChain2 = async (client: any, topic: any) => {
  const chainData1 = [
    {
      //@ts-ignore
      chainId: ethers.hexlify(Number(process.env.REACT_APP_CHAIN_ID)),
      rpcUrls: [process.env.REACT_APP_RPC_URL],
      chainName: process.env.REACT_APP_CHAIN_NAME,
      nativeCurrency: {
        name: "MATIC",
        symbol: process.env.REACT_APP_CHAIN_SYMBOL,
        decimals: 18,
      },
      blockExplorerUrls: [process.env.REACT_APP_HASH_URL],
    },
  ];

  for (const chain of DEFAULT_CHAINS) {
    await client.request({
      chainId: chain,
      topic,
      request: {
        method: "wallet_addEthereumChain",
        params: chainData1,
      },
    });
  }
};

export const requestToChangeChain = () => {
  //@ts-ignore
  if (!window.ethereum) {
    return;
  }

  const chainData1 = [
    {
      //@ts-ignore
      chainId: ethers.hexlify(Number(process.env.REACT_APP_CHAIN_ID)),
      rpcUrls: [process.env.REACT_APP_RPC_URL],
      chainName: process.env.REACT_APP_CHAIN_NAME,
      nativeCurrency: {
        name: "MATIC",
        symbol: process.env.REACT_APP_CHAIN_SYMBOL,
        decimals: 18,
      },
      blockExplorerUrls: [process.env.REACT_APP_HASH_URL],
    },
  ];

  //@ts-ignore
  window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: chainData1,
  });
};

export const getParentOrigin = () => {
  const locationAreDisctint = window.location !== window.parent.location;
  const parentOrigin = (
    (locationAreDisctint ? document.referrer : document.location) || ""
  ).toString();

  if (parentOrigin) {
    return new URL(parentOrigin).origin;
  }

  const currentLocation = document.location;

  if (
    currentLocation.ancestorOrigins &&
    currentLocation.ancestorOrigins.length
  ) {
    return currentLocation.ancestorOrigins[0];
  }

  return "";
};

export const calculateDaysDiff = (targetDate: Date) => {
  const currentDate = new Date();
  const timeDiff = targetDate.getTime() - currentDate.getTime();
  const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
  return daysDiff;
};

export function convertStatus(status: string): string {
  const statusMappings: { [key: string]: string } = {
    CONNECT_WALLET_INSTALL_METAMASK: "INSTALL_METAMASK",
    CONNECT_WALLET_ERROR: "ERROR",
    CONNECT_WALLET_NETWORK_ERROR: "NETWORK_ERROR",
    CONNECT_WALLET_LOADING: "LOADING",
    CONNECT_WALLET_SUCCESS: "SUCCESS",
    INITIAL: "INITIAL",
  };

  const reverseMappings: { [key: string]: string } = {
    INSTALL_METAMASK: "CONNECT_WALLET_INSTALL_METAMASK",
    ERROR: "CONNECT_WALLET_ERROR",
    NETWORK_ERROR: "CONNECT_WALLET_NETWORK_ERROR",
    LOADING: "CONNECT_WALLET_LOADING",
    SUCCESS: "CONNECT_WALLET_SUCCESS",
    INITIAL: "INITIAL",
  };

  if (statusMappings.hasOwnProperty(status)) {
    return statusMappings[status];
  } else if (reverseMappings.hasOwnProperty(status)) {
    return reverseMappings[status];
  } else {
    // Handle invalid status
    throw new Error("Invalid status provided.");
  }
}
export const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  console.log("USE_AGENT", ua);
  return ua.includes("safari") && !ua.includes("chrome");
};

export const RedirectParentToIframeUrlIfSafari = () => {
  const redirectToIframeUrl = () => {
    if (isSafari() && inIframe()) {
      console.log("WORK");

      const currentUrl = window.location.href;
      const callback = process.env.REACT_APP_PARENT_URL;
      console.log(callback, "CALLBACK");

      const queryParams = new URLSearchParams(window.location.search);

      queryParams.delete("callback");

      const iframeUrl = currentUrl.split("?")[0];

      const finalUrl =
        queryParams.toString() !== ""
          ? `${iframeUrl}?${queryParams.toString()}&callback=${callback}`
          : `${iframeUrl}?callback=${callback}`;

      window.parent.location.href = finalUrl;
    }
  };

  redirectToIframeUrl();
};

export const getDeadline = () => {
  const plusDay = Math.floor(Date.now() / 1000) + 86400;
  const deadline = ethers.toQuantity(plusDay);

  return { deadline, plusDay };
};
