import React from "react";
import styled from "styled-components";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { Footer } from "../../../shared/components/Footer";
import { HeaderTitle } from "../../../shared/components/HeaderTitle";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;
`;


export function HowItWorks() {
  const { t } = useCustomTranslation();

  return (
    <Wrapper>
      <HeaderTitle title={t("howItWorks")}/>
      <Footer />
    </Wrapper>
  );
}
