import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { TextSize } from "../../../constants/TextSize";
import { WhiteSpace } from "../../../constants/WhiteSpace";
import { Icon } from "../../../shared/components/Icon";
import { Text } from "../../../shared/components/Text";
import { formatPriceNumberWithCurrency } from "../../../utils/utils";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";

export enum ClaimStatusEnum {
  CLAIMED = "CLAIMED",
  UNCLAIMED = "UNCLAIMED",
  EXPIRED = "EXPIRED",
  OFFCHAIN = "OFFCHAIN",
}

interface ClaimStatusProps {
  status: ClaimStatusEnum;
  tokensUnclaimed?: number | null;
}

const FlexWrap = styled.div<{ isSuccess?: boolean }>`
  display: flex;
  align-items: center;
  svg {
    path {
      fill: ${({ isSuccess }) =>
        isSuccess ? Color.GREEN_CLAIM : Color.UNCLAIMED_STATUS};
    }
  }
`;

const IconStyled = styled(Icon)`
  margin-right: 2px;
`;

const IconStyled2 = styled(Icon)`
  margin-right: 2px;
  svg {
    fill: ${Color.SUCCESS_GREEN};

    path {
      fill: rgba(90, 171, 229, 0.05);
    }
  }
`;

export const ClaimStatus = ({ status, tokensUnclaimed }: ClaimStatusProps) => {
  const { t } = useCustomTranslation();

  if (status === ClaimStatusEnum.CLAIMED) {
    return (
      <FlexWrap isSuccess={true}>
        <IconStyled2 icon={IconType.successSmall} />
        <Text color={Color.GREEN_CLAIM} size={TextSize.M}>
          {t("tokensClaimed")}
        </Text>
      </FlexWrap>
    );
  } else if (status === ClaimStatusEnum.OFFCHAIN) {
    return <Text color={Color.BUTTON_BLUE_HOVER}>{t("offchainTokens")}</Text>;
  } else if (status === ClaimStatusEnum.UNCLAIMED) {
    return (
      <FlexWrap>
        <IconStyled icon={IconType.warning} />
        <Text
          color={Color.UNCLAIMED_STATUS}
          size={TextSize.M}
          whiteSpace={WhiteSpace.NOWRAP}
        >
          {formatPriceNumberWithCurrency({
            number: String(tokensUnclaimed || 0 / 10e18),
          })}{" "}
          {t("FWDunclaimed")}
        </Text>
      </FlexWrap>
    );
  } else {
    return <Text color={Color.SECONDARY60}>{t("claimExpired")}</Text>;
  }
};
