import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loading } from "../../../constants/Loading";
import { exchangeTokens, getAmount, getBonuses, getOperatorAmount, getTags } from "./actions";

export enum DefaultTagsEnum {
  ALL = "All",
  REAL_MONEY = "realMoney",
}

export type BonusSchema = {
  burningItemForeignId: string;
  description: string;
  foreignIdHash: string;
  iconUrl: string;
  id: string;
  isActive: boolean;
  operatorId: string;
  title: string;
  usdPrice: number;
  tags: TagsSchema[];
};

export type TagsSchema = {
  name: string;
  operatorId: string;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type bonusesState = {
  result: BonusSchema[];
  count?: number;
  loading: Loading;
  errorMessage?: string;
  errorMessageSubmit?: string;
  editBonusItem?: BonusSchema;
  tags: {
    result: TagsSchema[];
    count?: number;
    loading: Loading;
    errorMessage?: string;
  };
  amount: {
    result?: number;
    loading: Loading;
    errorMessage?: string;
  };
  exchange: {
    result?: any;
    loading: Loading;
    errorMessage?: string;

    amountForExchangeFreeze?: number | string;
    amountResultFreeze?: number | string;
  };
};

export const initialState: bonusesState = {
  result: [],
  loading: Loading.INITIAL,
  tags: {
    result: [
      {
        createdAt: "",
        id: "",
        name: "All",
        operatorId: "",
        updatedAt: "",
      },
    ],
    loading: Loading.INITIAL,
  },
  amount: {
    loading: Loading.INITIAL,
  },
  exchange: {
    loading: Loading.INITIAL,
  },
};

export const bonusesSlice = createSlice({
  name: "bonuses",
  initialState,
  reducers: {
    resetErrorMessageSubmit: (state) => {
      state.errorMessageSubmit = undefined;
    },
    setEditBonusItem: (state, action: PayloadAction<{ item: BonusSchema }>) => {
      state.editBonusItem = action.payload.item;
    },
    resetEditBonusItem: (state) => {
      state.editBonusItem = undefined;
    },
    setAmountForExchangeFreeze: (state, action: PayloadAction<{ amount: string | number }>) => {
      state.exchange.amountForExchangeFreeze = action.payload.amount;
    },
    setAmountResultFreeze: (state, action: PayloadAction<{ amount: string | number }>) => {
      state.exchange.amountResultFreeze = action.payload.amount;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBonuses.pending, (state, action) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(getBonuses.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      let filteredAllTag = [];
      filteredAllTag = action.payload.dtos
      .map((bonus: BonusSchema) => {
        bonus.tags = bonus.tags?.filter((tag: TagsSchema) => tag.name !== DefaultTagsEnum.ALL);
        return bonus;
      });
      state.result = filteredAllTag;
      state.count = action.payload.count;
    });
    builder.addCase(getBonuses.rejected, (state, action) => {
      state.loading = Loading.ERROR;
      state.errorMessage = action.error.message;
    });

    builder.addCase(getTags.pending, (state, action) => {
      state.tags.loading = Loading.PENDING;
    });
    builder.addCase(getTags.fulfilled, (state, action) => {
      state.tags.loading = Loading.SUCCESS;
      state.tags.result = [
        {
          createdAt: "",
          id: "",
          name: DefaultTagsEnum.ALL,
          operatorId: "",
          updatedAt: "",
        },
        // {
        //   createdAt: "",
        //   id: "",
        //   name: DefaultTagsEnum.REAL_MONEY,
        //   operatorId: "",
        //   updatedAt: "",
        // },
        ...action.payload.filter((tag: any) => tag.name !== "All"),
      ];
      state.tags.count = action.payload.length;
    });
    builder.addCase(getTags.rejected, (state, action) => {
      state.tags.loading = Loading.ERROR;
      state.tags.errorMessage = action.error.message;
    });

    builder.addCase(getAmount.pending, (state, action) => {
      state.amount.loading = Loading.PENDING;
    });
    builder.addCase(getAmount.fulfilled, (state, action) => {
      state.amount.loading = Loading.SUCCESS;
      state.amount.result = action.payload;
    });
    builder.addCase(getAmount.rejected, (state, action) => {
      state.amount.loading = Loading.ERROR;
      state.amount.errorMessage = action.error.message;
    });

    builder.addCase(getOperatorAmount.pending, (state, action) => {
      state.amount.loading = Loading.PENDING;
    });
    builder.addCase(getOperatorAmount.fulfilled, (state, action) => {
      state.amount.loading = Loading.SUCCESS;
      state.amount.result = action.payload.usdtAmount;
    });
    builder.addCase(getOperatorAmount.rejected, (state, action) => {
      state.amount.loading = Loading.ERROR;
      state.amount.errorMessage = action.error.message;
    });

    builder.addCase(exchangeTokens.pending, (state, action) => {
      state.exchange.loading = Loading.PENDING;
    });
    builder.addCase(exchangeTokens.fulfilled, (state, action) => {
      state.exchange.loading = Loading.SUCCESS;
      state.exchange.result = action.payload;
    });
    builder.addCase(exchangeTokens.rejected, (state, action) => {
      state.exchange.loading = Loading.ERROR;
      state.exchange.errorMessage = action.error.message;
    });
  },
});

export const { resetErrorMessageSubmit, setEditBonusItem, resetEditBonusItem, setAmountForExchangeFreeze, setAmountResultFreeze } =
  bonusesSlice.actions;

export default bonusesSlice.reducer;
