import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { TextSize } from "../../constants/TextSize";
import { useCustomTranslation } from "../../features/locale/hooks/useCustomTranslation";
import RingLoader from "./Spinner";
import { Text } from "./Text";

const Wrapper = styled.div<{ opacity?: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
`;

const TextWithLeftMargin = styled(Text) <{ marginLeft?: string }>`
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "8px")};
`;

const ProcessingLoader = styled.div`
  display: flex;
  align-items: center;
  margin: 8px auto 0;
`;

export interface SubmitTxMessageProps {
  opacity?: number;
  circleSize?: number;
  textSize?: TextSize;
  circleMargin?: string;
}

export function SubmitTxMessage({
  opacity,
  circleSize,
  textSize,
  circleMargin,
}: SubmitTxMessageProps) {
  const { t } = useCustomTranslation();

  return (
    <Wrapper opacity={opacity}>
      <ProcessingLoader>
        <RingLoader size={circleSize ? circleSize : 20} borderWidth={2} />
        <TextWithLeftMargin
          marginLeft={circleMargin}
          color={Color.SECONDARY}
          size={textSize ? textSize : TextSize.XS}
        >
          {t("waitingTXProcessing")}
        </TextWithLeftMargin>
      </ProcessingLoader>
    </Wrapper>
  );
}
