import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { IPurchase } from "../components/PurchaseFirstLevel";
import { IEventForDate } from "../pages/cashback/CashbackSecondDetailed";
import { ITokenBurnItem } from "../pages/TokenBurn/TokenBurnItem";

export interface GetEventJournalShema {
  sid: string;
  page: number;
  limit: number;
}

export interface GetEventForDay extends GetEventJournalShema {
  date: string;
}

export interface IEventFirstLevel {
  id: string;
  userId: string;
  eventDate: string;
  amount: number;
  statusId: number;
  createdAt: string;
  updatedAt: string;
}

export interface IEventJournal {
  result: IEventFirstLevel[];
  count: number;
}

export interface IPurchaseRes {
  result: IPurchase[];
  total: number;
}

export interface ITokenBurnRes {
  result: ITokenBurnItem[];
  total: number;
}

// export interface ITokenSellOffchainRes 

export const getEventJournal = createAsyncThunk(
  "history/getEventJournal",
  async ({
    sid,
    page,
    limit,
  }: GetEventJournalShema): Promise<IEventJournal> => {
    const {
      data: { dtos, count },
    } = await axios.get(
      `${process.env.REACT_APP_API_URL}/clients/event-journal?sid=${sid}&page=${page}&limit=${limit}`
    );

    return { result: dtos, count };
  }
);

export const getEventForDay = createAsyncThunk(
  "history/getEventForDay",
  async ({ sid, page, date, limit }: GetEventForDay): Promise<IEventForDate[]> => {
    const { data } = await axios.get<Promise<IEventForDate[]>>(
      `${process.env.REACT_APP_API_URL}/clients/event-for-day?page=${page}&limit=${limit}&sid=${sid}&date=${moment(date, 'DD.MM.YYYY').local().format('YYYY-MM-DD')}`,
      // `${process.env.REACT_APP_API_URL}/clients/event-for-day?page=${page}&limit=${limit}&sid=${sid}&date=${moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')}`,
    );

    return data;
  }
);

export const getPurchases = createAsyncThunk(
  "history/getPurchases",
  async ({
    sid,
    page,
    limit,
  }: GetEventJournalShema): Promise<IPurchaseRes> => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/clients/client-purchase-history?sid=${sid}&page=${page}&limit=${limit}`
    );
    return data;
  }
);

export const getTokenBurn = createAsyncThunk(
  "history/getTokenBurn",
  async ({
    sid,
    page,
    limit,
  }: GetEventJournalShema): Promise<ITokenBurnRes> => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/clients/client-exchange-history?sid=${sid}&page=${page}&limit=${limit}`
    );
    return data;
  }
);

export const getTokenSellOffchain = createAsyncThunk(
  "history/getTokenSellOffchain",
  async ({
    sid,
    page,
    limit,
  }: GetEventJournalShema): Promise<ITokenBurnItem[]> => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/clients/client-operator-exchange-history?sid=${sid}&page=${page}&limit=${limit}`
    );
    return data;
  }
);