import React, { useMemo } from "react";
import styled from "styled-components";
import { useCustomTranslation } from "../../features/locale/hooks/useCustomTranslation";
import { useAppSelector } from "../../app/hooks";
import { WalletTypeEnum } from "../../features/cashback/redux/userInfo.slice";
import { ActionRequiredTrust } from "./ActionRequiredTrust";
import { ActionRequiredEmpty } from "./ActionRequiredEmpty";
import { ActionRequiredZerion } from "./ActionRequiredZerion";
import { ActionRequiredMetamask } from "./ActionRequiredMetamask";

export type ActionRequeredWC2Props = {
  fullWidth?: boolean;
  height?: string;
  children?: string | JSX.Element;
};

const Wrapper = styled.div<{ fullWidth?: boolean; height?: string }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "calc(100% - 16px)")};
  height: ${({ height }) => (height ? `${height}` : "auto")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
`;

export function ActionRequeredWC2({
  fullWidth,
  height,
  children
}: ActionRequeredWC2Props) {
  // const { session } = useWalletConnectClient();
  const { t } = useCustomTranslation();
  const { userInfo } = useAppSelector((state) => state.userInfo);

  const walletConnectType = useMemo(() => {
    if (userInfo?.addressMemo) {
      return userInfo?.addressMemo;
    } else {
      // return session?.peer.metadata.name;
    }
  }, [
    // session, 
    userInfo]);

  return (
    <Wrapper fullWidth={fullWidth} height={height}>
      {(walletConnectType === WalletTypeEnum.TRUST && (
        <ActionRequiredTrust />
      )) ||
        (walletConnectType === WalletTypeEnum.METAMASK && (
          <ActionRequiredMetamask />
        )) ||
        (walletConnectType === WalletTypeEnum.ZERION && (
          <ActionRequiredZerion />
        )) || <ActionRequiredEmpty />}{" "}
      {children}
    </Wrapper>
  );
}
