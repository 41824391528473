import React, { ReactNode } from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";
import { Icon } from "./Icon";
import { Text } from "./Text";

export type ErrorProps = {
  errorMessage: string;
  extraMessage: string;
  code?: string;
  children?: ReactNode;
  fullWidth?: boolean;
};

const Wrapper = styled.div<{fullWidth?: boolean}>`
  width: ${({fullWidth}) => fullWidth ? '100%' : 'calc(100% - 16px)'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 16px 15px;
  height: calc(100% - 150px);
  box-sizing: border-box;
`;

const TextStyled = styled(Text)`
  margin-top: 16px;
  text-align: center;
`;

const IconStyled = styled(Icon)`
  svg path {
    fill: none
  }
`;


export function AppError({
  errorMessage,
  extraMessage,
  children,
  fullWidth,
}: ErrorProps) {
  return (
    <Wrapper fullWidth={fullWidth}>
      <IconStyled icon={IconType.error} size={100}/>
      {errorMessage && <TextStyled size={TextSize.XXL} weight={TextWeight.BOLD} color={Color.SECONDARY}>{errorMessage}</TextStyled>}
      {extraMessage && <TextStyled size={TextSize.M} color={Color.SECONDARY50}>{extraMessage}</TextStyled>}
      {children}
    </Wrapper>
  );
}
