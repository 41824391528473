import { useQueryParams } from "../../utils/utils";
import { useMemo } from "react";

export const useDetectCoinbase = () => {
  const { isMobile } = useQueryParams();

  const isCoinbase = useMemo(() => {
    //@ts-ignore
    return (isMobile === "true" || !isMobile || isMobile === 'null') && window && !!window.ethereum;
  }, [isMobile]);

  return { isCoinbase }
};