import React from "react";

export default (
  <svg
    width="100"
    height="101"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M9.71029 35.0661L29.2502 27.4223L29.1568 27.768C28.0167 31.9837 30.0678 36.4095 34.0213 38.2649L37.6806 39.9821C39.8171 40.9847 40.7056 43.5523 39.6459 45.6611C38.8569 47.231 38.7719 49.0621 39.412 50.6983L40.4625 53.3836L26.9749 58.6598L20.0063 61.3859C16.406 62.7943 12.3456 61.0174 10.9372 57.4171L5.74148 44.1352C4.33308 40.5349 6.10997 36.4746 9.71029 35.0661Z"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M75.6631 74.2053L44.9506 62.3173L49.4298 59.6296C51.5873 58.3351 52.5912 55.7414 51.8676 53.3316L50.2411 47.9155C49.9537 46.9583 50.2639 45.9219 51.0301 45.2801L59.7059 38.0122C61.3762 36.613 62.063 34.3597 61.4572 32.2668L59.3538 25.0004L77.046 31.8486L90.1021 36.9023C93.4499 38.1981 95.1134 41.9625 93.8175 45.3103L84.0712 70.4899C82.7754 73.8377 79.0109 75.5011 75.6631 74.2053Z"
      stroke="white"
      strokeWidth="3"
    />
  </svg>
);
