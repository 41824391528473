import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { Icon } from "../../../shared/components/Icon";
import { IconType } from "../../../constants/IconType";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { TextWeight } from "../../../constants/TextWeight";
import { TextSize } from "../../../constants/TextSize";
import { Text } from "../../../shared/components/Text";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { calculateDaysDiff, useQueryParams } from "../../../utils/utils";
import { Footer } from "../../../shared/components/Footer";
import { HeaderTitle } from "../../../shared/components/HeaderTitle";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 8px;
`;

const TextStyled = styled(Text)`
  margin-top: 16px;
  text-align: center;
`;

const TextStyled2 = styled(Text)`
  margin-top: 8px;
  text-align: center;
  line-height: 26px;
  width: 300px;
`;

const ButtonStyled = styled(Button)`
  margin-top: 8px;
`;

export function Exchange() {
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const { t } = useCustomTranslation();

  return (
    <Wrapper>
      <>
        <HeaderTitle title={"Exchange to USDT"} />
        <Icon
          icon={IconType.lockExchange}
          color={Color.SECONDARY}
          size={96}
        ></Icon>
        <TextStyled
          size={TextSize.XXL}
          weight={TextWeight.BOLD}
          color={Color.SECONDARY}
        >
          {t("exchangeLocked")}
        </TextStyled>

        <TextStyled2 size={TextSize.M} color={Color.SECONDARY}>
          {t("exchangeLockedText")}{" "}
          <>
            {" " +
              calculateDaysDiff(new Date("2023-07-07T12:54:53.000Z")) +
              " "}
          </>
          {t("days")}{" "}
        </TextStyled2>

        <ButtonStyled
          buttonType={ButtonType.WHITE_BORDER}
          size={ButtonSize.XL}
          onClick={() => navigateWithSearchParams({}, RoutesTypes.dashboard)}
          fullWidth={true}
        >
          <Text
            size={TextSize.L}
            color={Color.SECONDARY}
            weight={TextWeight.BOLD}
          >
            {t("backToWidget")}
          </Text>
        </ButtonStyled>
      </>
      <Footer />
    </Wrapper>
  );
}
