import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { ActionRequeredCB } from "../../../shared/components/ActionRequiredCB";
import { ActionRequeredWC2 } from "../../../shared/components/ActionRequiredWC2";
import { Button, ButtonSize, ButtonType } from "../../../shared/components/Button";
import { Text } from "../../../shared/components/Text";
import { WalletTypeEnum } from "../../cashback/redux/userInfo.slice";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";

export type CancelTxProps = {
  addressMemo?: WalletTypeEnum;
  selectMethod: () => void;
  setInitProcessStatus: () => void;
};

const ButtonBlockWrapper = styled.div`
  margin-top: 24px;
`;

const ButtonStyled = styled(Button)`
  margin-top: 16px;
`;

export function CancelTx({
  addressMemo,
  selectMethod,
  setInitProcessStatus,
}: CancelTxProps) {
  const { t } = useCustomTranslation();

  return (
    <>
      {addressMemo === WalletTypeEnum.COINBASE ? (
        <ActionRequeredCB fullWidth={true} height={"calc(100vh - 100px)"}>
          <ButtonBlockWrapper>
            <Button
              size={ButtonSize.XL}
              onClick={selectMethod}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("continueExchange")}
              </Text>
            </Button>
            <ButtonStyled
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={setInitProcessStatus}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonStyled>
          </ButtonBlockWrapper>
        </ActionRequeredCB>
      ) : (
        <ActionRequeredWC2 fullWidth={true} height={"calc(100vh - 100px)"}>
          <ButtonBlockWrapper>
            <Button
              size={ButtonSize.XL}
              onClick={selectMethod}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("continueExchange")}
              </Text>
            </Button>
            <ButtonStyled
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={setInitProcessStatus}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonStyled>
          </ButtonBlockWrapper>
        </ActionRequeredWC2>
      )}
    </>
  );
}
