import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { number } from "yup/lib/locale";
import { useAppSelector } from "../../../../app/hooks";
import { Color } from "../../../../constants/ColorEnum";
import { RoutesTypes } from "../../../../constants/RoutesTypes";
import TextAlign from "../../../../constants/TextAlign";
import { TextSize } from "../../../../constants/TextSize";
import { TextWeight } from "../../../../constants/TextWeight";
import { HashCopy } from "../../../../shared/components/HashCopy";
import { Text } from "../../../../shared/components/Text";
import Toggle from "../../../../shared/components/Toggle";
import { useNavigateWithSearchParams } from "../../../../shared/hooks/useNavigateWithSearchParams";
import { formatPriceNumberWithCurrency } from "../../../../utils/utils";
import { IncreaseWithTooltip } from "../../../cashback/components/IncreaseWithTooltip";
import { useCustomTranslation } from "../../../locale/hooks/useCustomTranslation";
import { CashbackGameTypeEnum } from "../../components/CashbackSecondLevelDetailedItem";
import { ClaimStatus, ClaimStatusEnum } from "../../components/ClaimStatus";
import { IEventForDate } from "./CashbackSecondDetailed";

const TextStyled = styled(Text)`
  margin: 16px auto 16px 0;
`;

const TextStyledHeight = styled(Text)`
  line-height: 32px;
`;

const TextStyled2 = styled(Text)`
  margin-right: 4px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ToggleWrap = styled.div`
  display: flex;
`;

const InfoBlock = styled.div`
  position: relative;
  box-sizing: border-box;
  background-color: ${Color.NAVIGATION_WRAPPER};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  width: 100%;
  margin-bottom: 4px;
  background: ${Color.NAVIGATION_WRAPPER};
  border-radius: 8px;
`;

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 4px;
  margin-bottom: 8px;

  ::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
  }
`;

const InfoWrapper2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const InfoWrapper3 = styled.div`
  display: flex;
  width: calc(100% + 16px);
  justify-content: space-between;
  margin-bottom: 4px;
  background: rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  padding: 0 8px;
`;

const Flex = styled.div`
  display: flex;
`;

const BreadcrumbsWrap = styled.div`
  display: flex;
  margin: 16px auto 16px 0;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: ${Color.SECONDARY80};
`;

const Link = styled.div`
  cursor: pointer;

  &:hover span {
    text-decoration: underline;
    color: ${Color.SECONDARY};
  }
`;

const TooltipWrap = styled.div`
  margin-top: 5px;
`;

export enum ClaimedStatusEnum {
  CLAIMED = "CLAIMED",
  UNCLAIMED = "UNCLAIMED",
  EXPIRED = "EXPIRED",
}

export interface IInfoItem {
  date: Date;
  type: CashbackGameTypeEnum.LIVE_CASINO; // bet type
  bet: number; // bet Placed
  amount: number; // cashback Earned
  FWDPrice: number; // 1 FWD = 0.56 USDT
  earnedFWDPrice: number;
  todaysPrice: number;
  claimedStatus: ClaimedStatusEnum;
  expired: boolean;
  claimedAmount: number;
  hash: string;
}

export const CashbackThirdLevel = () => {
  const { t } = useCustomTranslation();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const { navigateWithSearchParams } = useNavigateWithSearchParams();

  const [infoItem, setInfoItem] = useState<IEventForDate>();

  const { id } = useParams();

  const { eventForDay } = useAppSelector((state) => state.history);

  // const eventsFirstLevel = useAppSelector((state) => state.history.eventsFirstLevel);

  // useEffect(() => {
  //   if (id && eventsFirstLevel.list) {
  //     const activeItem = eventsFirstLevel.list.find((i) => i.id === id);
  //     activeItem && setInfoItem(activeItem);
  //   }
  // }, [id, eventsFirstLevel]);

  useEffect(() => {
    if (id && eventForDay.result) {
      const activeItem = eventForDay.result.find((i) => i.id === id);
      activeItem && setInfoItem(activeItem);
    }
  }, [id, eventForDay]);

  return (
    <>
      <Header>
        <BreadcrumbsWrap>
          <Link
            onClick={() => {
              navigateWithSearchParams({}, RoutesTypes.historyCashbackFirst);
            }}
          >
            <Text
              align={TextAlign.LEFT}
              weight={TextWeight.BOLD}
              color={Color.SECONDARY80}
              size={TextSize.XS}
            >
              {t("cashback")}
            </Text>
          </Link>
          &nbsp;/&nbsp;
          <Link
            onClick={() => {
              navigateWithSearchParams(
                {},
                RoutesTypes.historyCashbackSecondDetailed +
                  `/${moment(infoItem?.createdAt).local().format("DD.MM.YYYY")}`
              );
            }}
          >
            <Text
              align={TextAlign.LEFT}
              weight={TextWeight.BOLD}
              color={Color.SECONDARY80}
              size={TextSize.XS}
            >
              {moment(infoItem?.createdAt).local().format("DD.MM.YYYY")}
            </Text>
          </Link>
          &nbsp;/&nbsp;
          <Text
            align={TextAlign.LEFT}
            weight={TextWeight.BOLD}
            color={Color.SECONDARY80}
            size={TextSize.XS}
          >
            {moment(infoItem?.createdAt).local().format("HH:mm:ss")}
          </Text>
        </BreadcrumbsWrap>

        <ToggleWrap>
          {/* <TextStyled2 color={Color.SECONDARY60} size={TextSize.XS}>
            {t("detailedView")}
          </TextStyled2>
          <Toggle checked={true} onToggleChanged={() => {}} unclicable={true} /> */}
        </ToggleWrap>
      </Header>
      <InfoBlock>
        <InfoWrapper>
          <Text
            size={TextSize.M}
            weight={TextWeight.BOLD}
            color={Color.SECONDARY}
          >
            {moment(infoItem?.createdAt)
              .local()
              .format("DD.MM.YYYY, HH:mm:ss") +
              ` (UTC${moment(infoItem?.createdAt)
                .local()
                .format("Z")
                .replace(/(\+|-)0*(\d+):00/, "$1$2")})`}
          </Text>
        </InfoWrapper>
        <InfoWrapper2>
          <TextStyledHeight
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("cashbackEarned")}:
          </TextStyledHeight>
          <TextStyledHeight
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY}
          >
            {infoItem &&
              formatPriceNumberWithCurrency({
                number: String(infoItem?.mintAmount / 10e17),
              }) + " FWD"}
          </TextStyledHeight>
        </InfoWrapper2>

        <InfoWrapper3>
          <TextStyledHeight
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("betPlaced")}:
          </TextStyledHeight>
          <Flex>
            <TextStyledHeight
              size={TextSize.M}
              weight={TextWeight.MEDIUM}
              color={Color.SECONDARY}
            >
              {"$ " +
                (infoItem &&
                  formatPriceNumberWithCurrency({
                    number: String(infoItem?.amount),
                  }))}
            </TextStyledHeight>
          </Flex>
        </InfoWrapper3>

        <InfoWrapper2>
          <TextStyledHeight
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("todaysPrice")}:
          </TextStyledHeight>
          <Flex>
            <TextStyledHeight
              size={TextSize.M}
              weight={TextWeight.MEDIUM}
              color={Color.SECONDARY}
            >
              {formatPriceNumberWithCurrency({
                number: String(userInfo?.poolRate),
              }) + " USDT"}
            </TextStyledHeight>
            &nbsp;&nbsp;
            <TooltipWrap>
              <IncreaseWithTooltip />
            </TooltipWrap>
          </Flex>
        </InfoWrapper2>
      </InfoBlock>
    </>
  );
};
