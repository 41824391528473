import React from "react";

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.60143 5.39857C6.26949 5.06662 5.7313 5.06662 5.39935 5.39857C5.0674 5.73051 5.0674 6.2687 5.39935 6.60065L10.7983 11.9996L5.39935 17.3986C5.06741 17.7305 5.06741 18.2687 5.39935 18.6007C5.7313 18.9326 6.26949 18.9326 6.60143 18.6006L12.0004 13.2017L17.3993 18.6006C17.7313 18.9326 18.2695 18.9326 18.6014 18.6007C18.9334 18.2687 18.9334 17.7305 18.6014 17.3986L13.2025 11.9996L18.6014 6.60065C18.9334 6.2687 18.9334 5.73051 18.6014 5.39857C18.2695 5.06662 17.7313 5.06662 17.3994 5.39857L12.0004 10.7975L6.60143 5.39857Z"
      fill="white"
    />
  </svg>
);
