import React from "react";
import styled from "styled-components";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { Icon } from "../../../shared/components/Icon";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { formatPriceNumberWithCurrency } from "../../../utils/utils";
import moment from "moment";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";

export enum CashbackGameTypeEnum {
  SLOTS = "SLOTS",
  LIVE_CASINO = "LIVE_CASINO",
  SPORTS = "SPORTS",
  OTHER = "OTHER",
}

export interface IPurchase {
  createdat: Date;
  description: string;
  fwdamount: string;
  title: string;
  txhash: string;
  usdtamount: string;
}

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  background-color: ${Color.NAVIGATION_WRAPPER};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 8px;
  gap: 16px;
  width: 100%;
  height: 108px;
  margin-bottom: 4px;

  background: ${Color.NAVIGATION_WRAPPER};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${Color.NAVIGATION_WRAPPER_HOVER};
  }
`;

const ActionBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
`;

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 4px;

  ::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
  }
`;

const InfoWrapper2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
`;

const InfoWrapper3 = styled.div`
  display: flex;
  width: 100%;
`;

const InfoBlock = styled.div`
  width: calc(100% - 30px);
  height: 100%;
`;

const StyledIcon = styled(Icon)`
  svg {
    path {
      fill: none;
    }
  }
`;

export function PurchaseFirstLevel({
  cashbackSecondLevelDetailedItem,
}: {
  cashbackSecondLevelDetailedItem: IPurchase;
}) {
  const { t } = useCustomTranslation();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();

  return (
    <Wrapper
      onClick={() => {
        navigateWithSearchParams(
          {},
          RoutesTypes.historyBonusPurchaseSecond +
            `/${cashbackSecondLevelDetailedItem.txhash}`
        );
      }}
    >
      <InfoBlock>
        <InfoWrapper>
          <div>
            <Text
              size={TextSize.M}
              weight={TextWeight.BOLD}
              color={Color.SECONDARY}
            >
              {moment(cashbackSecondLevelDetailedItem.createdat).local().format(
                "DD.MM.YY"
              )}
            </Text>
            &nbsp;
            <Text
              size={TextSize.M}
              weight={TextWeight.BOLD}
              color={Color.SECONDARY60}
            >
              {moment(cashbackSecondLevelDetailedItem.createdat).local().format(
                "HH:mm"
              )}
            </Text>
          </div>
          <Text
            size={TextSize.M}
            weight={TextWeight.BOLD}
            color={Color.SECONDARY60}
            uppercase={true}
            ellipsis={true}
            lineClamp={1}
            maxWidth={"200px"}
          >
            {cashbackSecondLevelDetailedItem.title}
          </Text>
        </InfoWrapper>
        <InfoWrapper2>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("price")}:
          </Text>
          {/* <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("cashback")}:
          </Text> */}
        </InfoWrapper2>
        <InfoWrapper3>
          <Text
            size={TextSize.M}
            weight={TextWeight.BOLD}
            color={Color.SECONDARY}
          >
            {formatPriceNumberWithCurrency({
              number: String(cashbackSecondLevelDetailedItem?.fwdamount),
            }) + " FWD"}
          </Text>
          &nbsp;
          <Text
            size={TextSize.M}
            weight={TextWeight.REGULAR}
            color={Color.SECONDARY60}
          >
            {"(" +
              formatPriceNumberWithCurrency({
                number: String(cashbackSecondLevelDetailedItem.usdtamount),
              }) +
              " USDT)"}
          </Text>
        </InfoWrapper3>
      </InfoBlock>
      <ActionBlock>
        <StyledIcon
          icon={IconType.shevron_next}
          size={24}
          color={Color.SECONDARY}
        ></StyledIcon>
      </ActionBlock>
    </Wrapper>
  );
}
