import React from "react";

export default (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="40" cy="40" r="38.5" stroke="#5FCB8A" strokeWidth="3" />
    <path
      d="M28.1818 38.7603L37.1074 47.6859L52.0661 32.7273"
      stroke="#5FCB8A"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
