import { useNavigate } from "react-router";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";
import { RoutesTypes } from "../../constants/RoutesTypes";
import { useQueryParams } from "../../utils/utils";
import { useNavigateWithSearchParams } from "../hooks/useNavigateWithSearchParams";
import { Icon } from "./Icon";

interface HeaderTitleProps {
  title: string;
  withoutBack?: boolean;
  toMainPage?: boolean;
  withBackCross?: boolean;
}

const Header = styled.div<{ isMobile: string }>`
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: ${({ isMobile }) => (isMobile === "true" ? "16px 0 8px" : "16px 0 8px")};
  color: ${Color.SECONDARY};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const IconStyledWrarpper = styled.div`
  position: absolute;
  left: 4px;
  top: 4px;
  top: 3px;
  cursor: pointer;
`;

const CloseWrap = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
`;


export const HeaderTitle = ({ title, withoutBack, toMainPage, withBackCross }: HeaderTitleProps) => {
  const { isMobile } = useQueryParams();

  const navigate = useNavigate();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();

  const goBack = () => {
    navigate(-1);
  };

  const goToMainPage = () => {
    navigateWithSearchParams({}, RoutesTypes.dashboard);
  }

  return (
    <Header isMobile={isMobile}>
      {!withoutBack && (
        <IconStyledWrarpper
          onClick={() => (toMainPage ? goToMainPage() : goBack())}
        >
          <Icon icon={IconType.back} size={18} color={Color.SECONDARY} />
        </IconStyledWrarpper>
      )}
      {withBackCross && (
        <CloseWrap onClick={() => goToMainPage()}>
          <Icon icon={IconType.close} size={24} color={Color.SECONDARY}></Icon>
        </CloseWrap>
      )}
      {title}
    </Header>
  );
};
