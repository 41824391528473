import React from "react";

export default (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0267 9.03316C10.7467 9.3065 10.5867 9.69983 10.6267 10.1198C10.6867 10.8398 11.3467 11.3665 12.0667 11.3665H13.3334V12.1598C13.3334 13.5398 12.2067 14.6665 10.8267 14.6665H3.17335C1.79335 14.6665 0.666687 13.5398 0.666687 12.1598V7.67318C0.666687 6.29318 1.79335 5.1665 3.17335 5.1665H10.8267C12.2067 5.1665 13.3334 6.29318 13.3334 7.67318V8.63318H11.9867C11.6134 8.63318 11.2734 8.77983 11.0267 9.03316Z"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.666687 8.27337V5.22674C0.666687 4.4334 1.15335 3.72671 1.89335 3.44671L7.18669 1.44671C8.01335 1.13337 8.90002 1.74673 8.90002 2.63339V5.16672"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0392 9.31327V10.6866C14.0392 11.0533 13.7459 11.3533 13.3726 11.3666H12.0659C11.3459 11.3666 10.6859 10.84 10.6259 10.12C10.5859 9.69995 10.7459 9.30662 11.0259 9.03328C11.2726 8.77995 11.6126 8.6333 11.9859 8.6333H13.3726C13.7459 8.64663 14.0392 8.94661 14.0392 9.31327Z"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.66669 8H8.33335"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
