export function getExpirationDate(dataString: string): number {
    // Получаем текущую дату и время
    const currentDate = new Date();
  
    // Парсим дату из строки в формате "20.05.ХХХХ"
    const parts = dataString.split(".");
    const year = parseInt(parts[2]);
    const month = parseInt(parts[1]) - 1; // Месяцы в JavaScript начинаются с 0 (январь = 0, февраль = 1, и т.д.)
    const day = parseInt(parts[0]);
    const targetDate = new Date(year, month, day);
  
    // Вычисляем разницу между текущей датой и целевой датой
    const timeDiff = currentDate.getTime() - targetDate.getTime();
  
    // Прибавляем 30 дней в миллисекундах к разнице
    const thirtyDaysInMillis = 31 * 24 * 60 * 60 * 1000;
    const result = - timeDiff + thirtyDaysInMillis;
  
    // Создаем и возвращаем объект даты истечения срока
    return result;
  }
  
  