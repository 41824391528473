import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";
import icons from "../../resources/svgs";

export enum IconSize {
  XXL = 72,
  XL = 42,
  L = 28,
  M = 18,
  S = 14,
}

export type IconProps = {
  icon: IconType;
  size?: IconSize | number;
  sizeHeight?: IconSize | number;
  color?: Color;
  className?: string;
  dataTestid?: string;
};

const StyledIcon = styled.span<IconProps>`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ sizeHeight }) => sizeHeight}px;
  svg {
    fill: ${(props) => props.color};

    path {
      fill: ${(props) => props.color};
    }
  }

  &.hoverBgColor:hover {
    svg {
      fill: ${Color.PRIMARY};
    }
  }
`;

export function Icon({
  icon,
  size = IconSize.M,
  sizeHeight,
  color = Color.PRIMARY,
  className,
  dataTestid,
}: IconProps) {
  return (
    <StyledIcon
      data-testid={dataTestid}
      icon={icon}
      size={size}
      sizeHeight={sizeHeight || size}
      color={color}
      className={className}
    >
      {icons[icon]}
    </StyledIcon>
  );
}
