import { useState } from "react";
import styled from "styled-components";
import { Color } from "../../../../constants/ColorEnum";
import { Text } from "../../../../shared/components/Text";
import { useNavigateWithSearchParams } from "../../../../shared/hooks/useNavigateWithSearchParams";
import {
  formatPriceNumberWithCurrency,
  useQueryParams,
} from "../../../../utils/utils";
import { useCustomTranslation } from "../../../locale/hooks/useCustomTranslation";
import { TextSize } from "../../../../constants/TextSize";
import { TextWeight } from "../../../../constants/TextWeight";
import { ClaimStatus, ClaimStatusEnum } from "../../components/ClaimStatus";

export interface IInfoItem {
  totalBet: number;
  totalCashback: number;
  liveCasinoBets: number;
  liveCasinoBetsCashback: number;
  slotsBets: number;
  slotsBetsCashback: number;
  sportsBets: number;
  sportsBetsCashback: number;
  otherBets: number;
  otherBetsCashback: number;
  claimedAmount: number;
  expired: boolean;
}

const Wrapper = styled.div<{ isActive?: boolean }>`
  position: relative;
  box-sizing: border-box;
  background-color: ${Color.NAVIGATION_WRAPPER};
  display: flex;

  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  gap: 16px;
  width: 100%;
  margin-bottom: 4px;

  background: ${Color.NAVIGATION_WRAPPER};
  border-radius: 8px;
`;

const Block = styled.div<{noUnderline?: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 8px;

  ${({noUnderline}) => !noUnderline && `::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);`
  }}
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2px;
`;


const InfoWrapper2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2px;
`;

const InfoWrapperRight = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 6px;
`;

export const CashbackSecondSimplified = () => {
  const { t } = useCustomTranslation();
  const { isMobile } = useQueryParams();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const [infoItem, setInfoItem] = useState<IInfoItem>({
    totalBet: 44,
    totalCashback: 123123123,
    liveCasinoBets: 23,
    liveCasinoBetsCashback: 3123131,
    slotsBets: 23,
    slotsBetsCashback: 12331,
    sportsBets: 2,
    sportsBetsCashback: 121,
    otherBets: 12,
    otherBetsCashback: 12,
    claimedAmount: 22,
    expired: false,
  });

  return (
    <Wrapper>
      <Block>
        <InfoWrapper>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("totalBet")}:
          </Text>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("totalCashback")}:
          </Text>
        </InfoWrapper>
        <InfoWrapper>
          <Text
            size={TextSize.M}
            weight={TextWeight.REGULAR}
            color={Color.SECONDARY}
          >
            {"$" +
              formatPriceNumberWithCurrency({
                number: String(infoItem?.totalBet),
              })}
          </Text>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY}
          >
            {formatPriceNumberWithCurrency({
              number: String(infoItem?.totalCashback),
            }) + " FWD"}
          </Text>
        </InfoWrapper>
        <InfoWrapperRight>
          <ClaimStatus
            status={
              infoItem?.expired
                ? ClaimStatusEnum.EXPIRED
                : infoItem?.claimedAmount
                ? ClaimStatusEnum.UNCLAIMED
                : ClaimStatusEnum.CLAIMED
            }
            tokensUnclaimed={infoItem?.claimedAmount}
          />
        </InfoWrapperRight>
      </Block>

      <Block>
        <InfoWrapper>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("liveCasinoBets")}:
          </Text>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("cashback")}:
          </Text>
        </InfoWrapper>
        <InfoWrapper>
          <Text
            size={TextSize.M}
            weight={TextWeight.REGULAR}
            color={Color.SECONDARY}
          >
            {"$" +
              formatPriceNumberWithCurrency({
                number: String(infoItem?.liveCasinoBets),
              })}
          </Text>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY}
          >
            {formatPriceNumberWithCurrency({
              number: String(infoItem?.liveCasinoBetsCashback),
            }) + " FWD"}
          </Text>
        </InfoWrapper>
      </Block>

      <Block>
        <InfoWrapper>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("slotsBets")}:
          </Text>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("cashback")}:
          </Text>
        </InfoWrapper>
        <InfoWrapper>
          <Text
            size={TextSize.M}
            weight={TextWeight.REGULAR}
            color={Color.SECONDARY}
          >
            {"$" +
              formatPriceNumberWithCurrency({
                number: String(infoItem?.slotsBets),
              })}
          </Text>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY}
          >
            {formatPriceNumberWithCurrency({
              number: String(infoItem?.slotsBetsCashback),
            }) + " FWD"}
          </Text>
        </InfoWrapper>
      </Block>

      <Block>
        <InfoWrapper>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("sportsBets")}:
          </Text>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("cashback")}:
          </Text>
        </InfoWrapper>
        <InfoWrapper>
          <Text
            size={TextSize.M}
            weight={TextWeight.REGULAR}
            color={Color.SECONDARY}
          >
            {"$" +
              formatPriceNumberWithCurrency({
                number: String(infoItem?.sportsBets),
              })}
          </Text>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY}
          >
            {formatPriceNumberWithCurrency({
              number: String(infoItem?.sportsBetsCashback),
            }) + " FWD"}
          </Text>
        </InfoWrapper>
      </Block>

      <Block noUnderline={true}>
        <InfoWrapper>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("otherBets")}:
          </Text>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("cashback")}:
          </Text>
        </InfoWrapper>
        <InfoWrapper>
          <Text
            size={TextSize.M}
            weight={TextWeight.REGULAR}
            color={Color.SECONDARY}
          >
            {"$" +
              formatPriceNumberWithCurrency({
                number: String(infoItem?.otherBets),
              })}
          </Text>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY}
          >
            {formatPriceNumberWithCurrency({
              number: String(infoItem?.otherBetsCashback),
            }) + " FWD"}
          </Text>
        </InfoWrapper>
      </Block>
    </Wrapper>
  );
};
