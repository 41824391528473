import React from "react";

export default (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M15.5 3.5V6.9C15.5 7.46005 15.5 7.74008 15.609 7.95399C15.7049 8.14215 15.8578 8.29513 16.046 8.39101C16.2599 8.5 16.5399 8.5 17.1 8.5H20.5M10.5 8.5H6.5C5.39543 8.5 4.5 9.39543 4.5 10.5V19.5C4.5 20.6046 5.39543 21.5 6.5 21.5H12.5C13.6046 21.5 14.5 20.6046 14.5 19.5V16.5M16.5 3.5H13.7C12.5799 3.5 12.0198 3.5 11.592 3.71799C11.2157 3.90973 10.9097 4.21569 10.718 4.59202C10.5 5.01984 10.5 5.5799 10.5 6.7V13.3C10.5 14.4201 10.5 14.9802 10.718 15.408C10.9097 15.7843 11.2157 16.0903 11.592 16.282C12.0198 16.5 12.5799 16.5 13.7 16.5H17.3C18.4201 16.5 18.9802 16.5 19.408 16.282C19.7843 16.0903 20.0903 15.7843 20.282 15.408C20.5 14.9802 20.5 14.4201 20.5 13.3V7.5L16.5 3.5Z"
        stroke="white"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
