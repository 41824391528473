import { useCallback, useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { Loading } from "../../../constants/Loading";
import { parceValuesInString } from "../utils/parceValuesInString";

export const useCustomTranslation = () => {
  const activeLocale = useAppSelector(state => state.locale.locale);
  const translation = useAppSelector(state => state.locale.translation[String(activeLocale)]);
  const loading = useAppSelector(state => state.locale.loading);

  const t = useCallback((str: string, rest?: {[s: string]: string}) => {
    if (loading === Loading.PENDING) {
      return ''
    }

    const translateStr = translation && translation[String(str)];

    if (!translateStr) return str

    const result = parceValuesInString(translateStr);

    if (result.length > 1) {
      return result.reduce((accum, {type, value}) => type === 'static' ? accum + value : (rest && rest[value]) ? accum + rest[value]: accum, '');
    } else {
      return translateStr
    }
  }, [loading, translation]);

  return {t}

};
