import React from "react";
import styled from "styled-components";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { Exchange } from "../../BuyBonuses/components/Exchange";
import { Footer } from "../../../shared/components/Footer";
import { HeaderTitle } from "../../../shared/components/HeaderTitle";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 8px;
`;

export function QuickExchange() {
  const { t } = useCustomTranslation();

  return (
    <Wrapper>

      <Exchange />
      <Footer />
    </Wrapper>
  );
}
