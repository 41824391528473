import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
  RedirectParentToIframeUrlIfSafari,
  useQueryParams,
  useSetQueryParams,
} from "../../../utils/utils";
import { Color } from "../../../constants/ColorEnum";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { IconType } from "../../../constants/IconType";
import { Icon } from "../../../shared/components/Icon";
import { getUserInfo } from "../redux/actions";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import axios from "axios";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { DECIMALS_CONST } from "../../BuyBonuses/components/Exchange";
import { HowItWorksLink } from "../../../shared/components/HowItWorksLink";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "./connectors";
import WalletConnect from "../../../resources/images/WalletConnect.png";
import CoinbaseWallet from "../../../resources/images/CoinbaseWallet.png";
import { useDetectCoinbase } from "../../../shared/hooks/useDetectCoinbase";
import { HeaderTitle } from "../../../shared/components/HeaderTitle";
import { Footer } from "../../../shared/components/Footer";
import { useConnect, useAccount } from 'wagmi';
import { injected } from 'wagmi/connectors';

export interface ConnectWalletProps {
  setProcessStatus: (status: ProcessStatus) => void;
}

const InfoBlock = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 8px 16px;
  width: 100%;

  background: rgba(90, 171, 229, 0.05);
  border: 1px solid rgba(90, 171, 229, 0.1);
  border-radius: 8px;
  height: 100%;
`;

const InfoBlockTitle = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}px`};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 4px;
  text-align: center;
  color: ${Color.SECONDARY};
`;

const Balance = styled.div`
  display: flex;
  justify-content: center;
  width: 336px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #fff;
  align-items: center;
`;

const InfoBlockSubheader = styled.div`
  font-weight: 400;
  font-size: 14px;
  opacity: 0.5;
  color: ${Color.SECONDARY};
  margin-top: 8px;
  margin-bottom: 4px;
`;

const IconBalance = styled(Icon)`
  margin-right: 8px;

  svg path {
    fill: ${Color.SECONDARY};
  }
`;

export enum ProcessStatus {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  CANCEL = "CANCEL",
  WAITING_SIGN = "WAITING_SIGN",
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
  INSTALL_METAMASK = "INSTALL_METAMASK",
  NETWORK_ERROR = "NETWORK_ERROR",
}

const ButtonWithSvg = styled(Button)`
  svg {
    path {
      fill: none;
    }
  }

  margin-bottom: 8px;
`;

const ButtonBack = styled(Button)`
  border: 0;
`;

const ModalWrap = styled.div`
  position: absolute;

  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 100;
  top: -46px;

  background-color: ${Color.BACKGROUND_COLOR};
`;

const IconStyledWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 18px;
  cursor: pointer;
`;

const IconStyledWrapperRight = styled.div`
  position: absolute;
  right: 10px;
  top: 16px;
  cursor: pointer;
`;

enum WalletTypeEnum {
  WALLET_CONNECT = "WALLET_CONNECT",
  COINBASE = "COINBASE",
}

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

const TextStyled = styled(Text)`
  margin-left: 8px;
`;

const TextWithMarginBottom = styled(Text)`
  margin-bottom: 45px;
`;

const ButtonWithTopMargin = styled(Button)`
  margin-top: 16px;
`;

const ButtonsWrap = styled.div`
  width: calc(100% - 64px);
  margin: 0px auto;
`;

const ChooseWalletWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
`;

const FakeFooter = styled.div`
  height: 48px;
`;

const TopTextWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  margin: 0px 20px 20px;
`;



export function ConnectWallet({ setProcessStatus }: ConnectWalletProps) {
  const { t } = useCustomTranslation();
  const { sid, isMobile } = useQueryParams();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const { isCoinbase } = useDetectCoinbase();


  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const [error, setError] = useState("");
  // const [walletType, setWalletType] = useState<WalletTypeEnum>();

  const setAddress = async (address: string, walletType: WalletTypeEnum | string) => {
    try {
      setProcessStatus(ProcessStatus.LOADING);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/clients/set-address-by-operator`,
        {
          address: address,
          sid,
          addressMemo: walletType,
        }
      );

      if (response) {
        setProcessStatus(ProcessStatus.SUCCESS);

        setTimeout(() => {
          dispatch(getUserInfo({ sid }));
        }, 1000);
      }
    } catch (err) {
      //@ts-ignore
      if (err.response.data.code === 1204 || err.response.data.code === 1208) {
        console.log("walletConnectionType", walletType);
        navigateWithSearchParams(
          {
            isDublicate: true,
            isConnectWallet: true,
            walletConnectionType: walletType,
          },
          RoutesTypes.switchWallet
        );
      }
      setProcessStatus(ProcessStatus.ERROR);
      //@ts-ignore
      setError(err.response.data.message);
    } finally {
    }
  };

  // const { connect, accounts, session } = useWalletConnectClient();

  const [buttonClicked, setButtonClicked] = useState(false);
  const { setQueryParams } = useSetQueryParams();


  const { address } = useAccount();

  const { open } = useWeb3Modal();

  const setAddressWithWalletConnect = async () => {
    if (!address) {
      open();
      // connect({ connector: injected() });
      return;
    };

    // dispatch(setWalletConnection(WalletTypeEnum.WALLET_CONNECT));
    setShowInfoModal(false);
    setQueryParams({ walletConnectionType: WalletTypeEnum.WALLET_CONNECT });


    RedirectParentToIframeUrlIfSafari();
    setButtonClicked(true);

    if (address) {
      // await setAddress(address, WalletTypeEnum.WALLET_CONNECT);
      await setAddress(address, WalletTypeEnum.WALLET_CONNECT);
    } else {
      // connect();
    }
  };

  // useEffectSkipFirst(() => {
  //   if (buttonClicked) {
  //     const address = accounts[0];
  //     if (address && session) {
  //      //  setAddress(address, WalletTypeEnum.WALLET_CONNECT);
  //       setAddress(accounts[0], session.peer.metadata.name);
  //     }
  //   }
  // }, [accounts]);

  const { account, activate } = useWeb3React();

  const [connectWalletTouched, setConnectWalletTouched] = useState(false);
  const connectCoinBase = () => {
    setShowInfoModal(false);

    if (isMobile === "true") {
      navigateWithSearchParams({}, RoutesTypes.mobileCoinbase);
      return;
    }

    setQueryParams({ walletConnectionType: WalletTypeEnum.COINBASE });
    // dispatch(setWalletConnection(WalletTypeEnum.COINBASE));
    if (!account) {
      setConnectWalletTouched(true);
      activate(connectors.coinbaseWallet);
    } else {
      if (sid) {
        setAddress(account, WalletTypeEnum.COINBASE);
      }
    }
  };

  useEffect(() => {
    if (account && connectWalletTouched) {
      setAddress(account, WalletTypeEnum.COINBASE);
    }
  }, [account]);

//   const deleteUnsupportedWallets = () => {
// // Получите доступ к iframe
// const iframe = document.getElementById('yourIframeId') as HTMLIFrameElement; // Замените 'yourIframeId' на фактический идентификатор вашего iframe

// // Проверьте, загружен ли iframe
// if (iframe?.contentDocument) {
//   // Получите доступ к содержимому iframe
//   const iframeDocument = iframe.contentDocument || (iframe.contentWindow as Window).document;

//   // Найдите все элементы с определенным атрибутом walletid и удалите их
//   const elementsToDelete = iframeDocument.querySelectorAll('[walletid="19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927"]');
  
//   elementsToDelete.forEach((element) => {
//     element.parentNode?.removeChild(element);
//   });
// } else {
//   console.error('Iframe not loaded');
// }

//   }


  return (
    <InfoBlock>
      {/* {!!Number(userInfo?.offChainBalance) ? (
        <>
          <Balance>
            <IconBalance
              icon={IconType.rewind}
              size={24}
              color={Color.BUTTON_BLUE}
            ></IconBalance>
            {formatPriceNumberWithCurrency({
              number: `${
                userInfo?.offChainBalance &&
                +userInfo?.offChainBalance / DECIMALS_CONST
              }`,
            })}
          </Balance>
          <InfoBlockTitle width={336}>{t("tokensWaiting")}</InfoBlockTitle>
        </>
      ) : (
        <InfoBlockTitle width={254}>{t("earnTokens")}</InfoBlockTitle>
      )} */}

      {/* <InfoBlockSubheader>{t("connectWalletToGet")}</InfoBlockSubheader> */}
      <TopTextWrap>
        <Text
          size={TextSize.XXL}
          weight={TextWeight.BOLD}
          color={Color.SECONDARY}
        >
          {t("connectWalletToEnable")}
        </Text>

        <InfoBlockSubheader>{t("itIsImportantToConnect")}</InfoBlockSubheader>
      </TopTextWrap>

      {/* <Text
          size={TextSize.L}
          color={Color.SECONDARY}
          weight={TextWeight.BOLD}
        >
          isCoinbase -{isCoinbase}
        </Text> */}
      <ButtonWithSvg
        fullWidth={true}
        icon={IconType.wallet}
        size={ButtonSize.XL}
        onClick={() => {
          setAddressWithWalletConnect();
        }}
      >
        <Text
          color={Color.SECONDARY}
          weight={TextWeight.BOLD}
          size={TextSize.L}
        >
          {t("connectYourWallet")}
        </Text>
      </ButtonWithSvg>

      <ButtonBack
        distance={"4px"}
        fullWidth={true}
        buttonType={ButtonType.EMPTY}
        size={ButtonSize.XL}
        onClick={() => navigateWithSearchParams({}, RoutesTypes.dashboard)}
      >
        {t("back")}
      </ButtonBack>

      {/* <HowItWorksLink /> */}
      {/* <button onClick={deleteUnsupportedWallets}>
        toggle
      </button> */}
      {/* {showInfoModal && (
        <ModalWrap>
          <HeaderTitle withoutBack={true} title={t("connectYourWallet")} />
          <IconStyledWrapper onClick={() => setShowInfoModal(false)}>
            <Icon icon={IconType.back} size={18} color={Color.SECONDARY} />
          </IconStyledWrapper>

          <IconStyledWrapperRight onClick={() => setShowInfoModal(false)}>
            <Icon icon={IconType.close} size={24} color={Color.SECONDARY} />
          </IconStyledWrapperRight>

          <ChooseWalletWrap>
            <TextWithMarginBottom
              weight={TextWeight.BOLD}
              size={TextSize.XXL}
              color={Color.SECONDARY}
            >
              {t("chooseYourWallet")}
            </TextWithMarginBottom>
            <ButtonsWrap>
              <Button
                size={ButtonSize.XXL}
                buttonType={ButtonType.EMPTY}
                onClick={connectCoinBase}
                fullWidth={true}
              >
                <ButtonContent>
                  <img
                    src={CoinbaseWallet}
                    alt={t("coinbaseWallet")}
                    width={40}
                    height={40}
                  />
                  <TextStyled
                    weight={TextWeight.MEDIUM}
                    size={TextSize.L}
                    color={Color.SECONDARY}
                  >
                    {t("coinbaseWallet")}
                  </TextStyled>
                </ButtonContent>
              </Button>
              <ButtonWithTopMargin
                size={ButtonSize.XXL}
                buttonType={ButtonType.EMPTY}
                onClick={setAddressWithWalletConnect}
                fullWidth={true}
              >
                <ButtonContent>
                  <img src={WalletConnect} alt="WalletConnect" width={40} />
                  <TextStyled
                    weight={TextWeight.MEDIUM}
                    size={TextSize.L}
                    color={Color.SECONDARY}
                  >
                    WalletConnect
                  </TextStyled>
                </ButtonContent>
              </ButtonWithTopMargin>
            </ButtonsWrap>
          </ChooseWalletWrap>
          <Footer />
          <FakeFooter />
        </ModalWrap>
      )} */}
    </InfoBlock>
  );
}
