import React from "react";

export default (
  <svg
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8121 4.85456L18.6667 9H26.6667V1L22.8121 4.85456ZM22.8121 4.85456C20.62 2.48414 17.4833 1 14 1C7.82327 1 2.73651 5.66673 2.07325 11.6667M5.1879 21.1454L1.33334 25V17H9.33334L5.1879 21.1454ZM5.1879 21.1454C7.38006 23.5159 10.5167 25 14 25C20.1767 25 25.2635 20.3333 25.9268 14.3333"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
