import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Color } from "../../../../constants/ColorEnum";
import { Loading } from "../../../../constants/Loading";
import TextAlign from "../../../../constants/TextAlign";
import { TextSize } from "../../../../constants/TextSize";
import { TextWeight } from "../../../../constants/TextWeight";
import {
  ScrollArea,
  ScrollAreaSize,
} from "../../../../shared/components/ScrollArea";
import { SkeletonItem } from "../../../../shared/components/SkeletonItem";
import { Text } from "../../../../shared/components/Text";
import { useQueryParams } from "../../../../utils/utils";
import { useCustomTranslation } from "../../../locale/hooks/useCustomTranslation";
import { ShadowScrollWrapper } from "../../components/ShadowScrllWrapper";
import { getTokenBurn } from "../../redux/actions";
import { TokenBurnItem } from "./TokenBurnItem";

const TextStyled = styled(Text)`
  margin: 16px auto 16px 0;
`;

const NotFoundWrapper = styled.div`
  height: 256px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TokenBurn = () => {
  const dispatch = useAppDispatch();
  const { sid } = useQueryParams();
  const { t } = useCustomTranslation();
  const scrollRef = useRef<Scrollbars>(null);

  useEffect(() => {
    dispatch(getTokenBurn({ sid, page: 1, limit: 9999 }));
  }, []);

  const items = useAppSelector((state) => state.history.tokenBurn.result);
  const loading = useAppSelector((state) => state.history.tokenBurn.loading);
  const [showTopShadow, setShowTopShadow] = useState(false);

  return (
    <>
      <TextStyled
        align={TextAlign.LEFT}
        weight={TextWeight.BOLD}
        color={Color.SECONDARY80}
        size={TextSize.XS}
      >
        {t("swapToUSDT")}
      </TextStyled>
      <ShadowScrollWrapper showTopShadow={showTopShadow}>
        {(loading === Loading.SUCCESS && (
          <ScrollArea
            size={ScrollAreaSize.S}
            ref={scrollRef}
            hideHorizontalTrack={true}
            onUpdate={(data) => setShowTopShadow(data.scrollTop > 1)}
          >
            {items && items?.length > 0 ? (
              items.map((item) => <TokenBurnItem tokenBurnItem={item} />)
            ) : (
              <NotFoundWrapper>
                <Text
                  size={TextSize.M}
                  weight={TextWeight.REGULAR}
                  color={Color.SECONDARY60}
                >
                  {t("noRecordsFound")}
                </Text>
              </NotFoundWrapper>
            )}
          </ScrollArea>
        )) ||
          (loading === Loading.PENDING && (
            <>
              <SkeletonItem />
              <SkeletonItem />
              <SkeletonItem />
            </>
          )) || <></>}
      </ShadowScrollWrapper>
    </>
  );
};
