import React, { ReactNode } from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";
import { RewindLoader } from "./RewindLoader";
import { Text } from "./Text";

export type LoaderProps = {
  message: string;
  extraMessage?: string;
  fullHeight?: boolean;
  children?: ReactNode;
  fullWidth?: boolean;
};

const Wrapper = styled.div<{fullHeight?: boolean, fullWidth?: boolean}>`
  width: ${({fullWidth}) => fullWidth ? '100%' : 'calc(100% - 16px)'};
  height: ${(fullHeight) => fullHeight ? 'calc(100% - 150px)' : 'auto'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 20px 16px;
  box-sizing: border-box;
`

const TextStyled = styled(Text)`
  margin-top: 16px;
`

export function AppLoading({
  message,
  extraMessage,
  fullHeight,
  children,
  fullWidth,
}: LoaderProps) {
  return (
    <Wrapper fullWidth={fullWidth} fullHeight={fullHeight}>
      <RewindLoader color={Color.SECONDARY} size={90} sizeUnit={"px"} />
      {!!message && <TextStyled size={TextSize.XXL} weight={TextWeight.BOLD} color={Color.SECONDARY}>{message}</TextStyled>}
      {!!extraMessage && <Text size={TextSize.M} color={Color.SECONDARY50}>{extraMessage}</Text>}
      {children}
    </Wrapper>
  );
}
