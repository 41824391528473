// import {
//   EthereumClient,
//   w3mConnectors,
//   w3mProvider,
// } from "@web3modal/ethereum";

// import { configureChains, createConfig } from "wagmi";
// import { polygon } from "wagmi/chains";
// import { ethers, providers } from "ethers";
import { abi } from "../../BuyBonuses/utils/getNonces";
// import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { ProcessStatus } from "../../cashback/components/Cashback";

export const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || "0";
export const APP_CHAIN_ID = 137;
export const defaultChainId =
  Number(process.env.REACT_APP_CHAIN_ID) || APP_CHAIN_ID;
// export const chains = [polygon];

// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
// export const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, chains }),
//   publicClient,
// });

// export const getEthereumProvider = async() => await EthereumProvider.init({
//   projectId: projectId,
//   chains: [defaultChainId],
  
//   showQrModal: true,
//   methods: ['eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign'],
//   optionalMethods: ['eth_signTypedData_v4', 'eth_sign']
// });

// export const ethereumClient = new EthereumClient(wagmiConfig, chains);
// export const getAddress = () => ethereumClient.getAccount().address;
// export const getChainId = () => ethereumClient.getNetwork().chain?.id;

// export const getNonceCount = async (
//   userAddress: string,
//   tokenContractAddress: string
// ) => {
//   const walletConnectProvider = await EthereumProvider.init({
//     projectId: projectId,
//     chains: [defaultChainId],
    
//     showQrModal: true,
//     methods: ['eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign'],
//   });
//   await walletConnectProvider.enable();

//   const provider = new providers.Web3Provider(walletConnectProvider);
//   const signer = provider.getSigner(userAddress);
//   const contract = new ethers.Contract(tokenContractAddress, abi, signer);
//   const nonces = await contract
//     .nonces(userAddress)
//     .then((res: any) => Number(res));

//   return nonces;
// };


export const getNonceCount = async (
  userAddress: string,
  tokenContractAddress: string,
  navigateWithSearchParams: (values: any, pathname: string) => void,
  // setProcessStatus: (status: ProcessStatus) => void
) => {
  // const walletConnectProvider = await EthereumProvider.init({
  //   projectId: projectId, // REQUIRED your projectId
  //   chains: [137], // REQUIRED chain ids
  //   showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal
  //   methods: ["eth_signTypedData_v4"], // REQUIRED ethereum methods
  // });

  // await walletConnectProvider.connect();

  // const provider = new providers.Web3Provider(walletConnectProvider);
  
  // // console.log(provider, projectId, 'INSIDENONCE')
  // const signer = provider.getSigner();
  
  // const address = await signer.getAddress();
  // console.log(address, 'Address')

  // if (address.toLowerCase() !== userAddress.toLowerCase()) {
  //   //TO DO - possible to pass param with current route
  //   navigateWithSearchParams({}, RoutesTypes.switchWallet);
  //   // setProcessStatus(ProcessStatus.)
  //   return;
  // }
  
  // const contract = new ethers.Contract(tokenContractAddress, abi, signer);
  // // console.log(contract, 'contract')
  // // console.log(userAddress, 'address')
  // const nonces = await contract
  //   .nonces(userAddress)
  //   .then((res: any) => Number(res));

  // return nonces;
};