import React from "react";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";
import { TextSize } from "../../constants/TextSize";
import { TxHashEnum } from "../../features/cashback/redux/userInfo.slice";
import { useCustomTranslation } from "../../features/locale/hooks/useCustomTranslation";
import { HashCopy } from "./HashCopy";
import { Icon } from "./Icon";
import RingLoader from "./Spinner";
import { Text } from "./Text";

const Wrapper = styled.div<{ opacity?: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
`;

const TextWithLeftMargin = styled(Text)<{ marginLeft?: string }>`
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "8px")};
`;

const ProcessingLoader = styled.div`
  display: flex;
  align-items: center;
  margin: 8px auto 0;
`;

const HashCopyPosition = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

const IconStyled = styled(Icon)`
  svg {
    fill: ${Color.SUCCESS_GREEN};

    path {
      fill: rgba(90, 171, 229, 0.05);
    }
  }
`;

export interface BalanceLoaderProps {
  opacity?: number;
  withoutBottom?: boolean;
  circleSize?: number;
  textSize?: TextSize;
  circleMargin?: string;
}

export function BalanceLoader({
  opacity,
  withoutBottom,
  circleSize,
  textSize,
  circleMargin,
}: BalanceLoaderProps) {
  const { t } = useCustomTranslation();
  const hash = useAppSelector((state) => state.userInfo.activeTxHash) || "";
  const txString = useAppSelector((state) => state.userInfo.txString) || "";
  const activeTxHashUsage = useAppSelector((state) => state.userInfo.activeTxHashUsage) || "";
  const balanceIsPending = useAppSelector(
    (state) => state.userInfo.balanceIsPending
  );

  return (
    <Wrapper opacity={opacity}>
      <ProcessingLoader>
        {balanceIsPending ? (
          <RingLoader size={circleSize ? circleSize : 20} borderWidth={2} />
        ) : (
          <>
            <IconStyled
              size={circleSize ? circleSize : 24}
              icon={IconType.successSmall}
            />
          </>
        )}

        <TextWithLeftMargin
          marginLeft={circleMargin}
          color={balanceIsPending ? Color.SECONDARY : Color.SUCCESS_GREEN}
          size={textSize ? textSize : TextSize.XS}
        >
          {t("processing")}{" "}
          {txString ||
            (activeTxHashUsage === TxHashEnum.CLAIM && t("tokensClaimed")) ||
            (activeTxHashUsage === TxHashEnum.EXCHANGE &&
              t("exchangedTokens")) ||
            (activeTxHashUsage === TxHashEnum.BUY_BONUS && t("yourPurchase"))}
        </TextWithLeftMargin>
      </ProcessingLoader>
      {/* <RewindLoader color={Color.SECONDARY} size={68} sizeUnit={"px"} /> */}
      {!withoutBottom && (
        <HashCopyPosition>
          <HashCopy hash={hash} isAddress={false}></HashCopy>
        </HashCopyPosition>
      )}
    </Wrapper>
  );
}
