import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";
import { useCustomTranslation } from "../../features/locale/hooks/useCustomTranslation";
import { useQueryParams } from "../../utils/utils";
import { Icon } from "./Icon";
import { Text } from "./Text";

const InfoBlockFaq = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  display: flex;
  color: ${Color.SECONDARY};
`;

const IconQuestion = styled(Icon)`
  margin-right: 4px;

  svg path {
    fill: ${Color.BUTTON_BLUE};
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
    color: white;
  }
`;

export function HowItWorksLink({ textSize }: { textSize?: TextSize }) {
  const { helpLink } = useQueryParams();
  const { t } = useCustomTranslation();

  return (
    <>
      <StyledLink href={`${helpLink}`} target="_blank" rel="noreferrer">
        <InfoBlockFaq>
          <IconQuestion
            icon={IconType.question}
            size={18}
            color={Color.BUTTON_BLUE}
          ></IconQuestion>
          <Text
            color={Color.SECONDARY}
            size={textSize || TextSize.XS}
            weight={TextWeight.MEDIUM}
          >
            {t("howItWorks")}
          </Text>
        </InfoBlockFaq>
      </StyledLink>
    </>
  );
}
