import React, { useMemo } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { ActionRequered } from "../../../shared/components/ActionRequired";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { AppError } from "../../../shared/components/AppError";
import { AppLoading } from "../../../shared/components/AppLoader";
import { AppSuccess } from "../../../shared/components/AppSuccess";
import InstallMetamask from "../../../resources/images/installMetamask.png";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { inIframe } from "../../../utils/utils";

const ButtonWithMargin = styled(Button)`
  margin-top: 16px;
`;

export enum ProcessStatus {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  CANCEL = "CANCEL",
  WAITING_SIGN = "WAITING_SIGN",
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
  INSTALL_METAMASK = "INSTALL_METAMASK",
  NETWORK_ERROR = "NETWORK_ERROR",
}

const ButtonStyled = styled(Button)`
  margin-top: 16px;
`;

const TextStyled = styled(Text)`
  line-height: 28px;
`;

export interface ProcessOptionsProps {
  processStatus: ProcessStatus;
  error?: string;
  setProcessStatus: (status: ProcessStatus) => void;
}

export function ProcessOptions({
  processStatus,
  error,
  setProcessStatus,
}: ProcessOptionsProps) {
  const { t } = useCustomTranslation();
  const isInIframe = useMemo(() => inIframe(), []);

  return (
    <>
      {(processStatus === ProcessStatus.INSTALL_METAMASK && (
        <ActionRequered
          actionMessage={t("metamaskOffline")}
          extraMessage={t("metamaskOfflineText")}
          image={InstallMetamask}
          imageSize={128}
        >
          <a
            style={{ textDecoration: "none", width: "100%" }}
            href="https://metamask.io/download/"
            target="_blank"
            rel="noreferrer"
          >
            <Button size={ButtonSize.XL} fullWidth={true}>
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("installMetamask")}
              </Text>
            </Button>
          </a>
          <ButtonWithMargin
            buttonType={ButtonType.WHITE_BORDER}
            size={ButtonSize.XL}
            onClick={() => {
              setProcessStatus(ProcessStatus.INITIAL);
            }}
            fullWidth={true}
          >
            <Text
              size={TextSize.L}
              color={Color.SECONDARY}
              weight={TextWeight.BOLD}
            >
              {t("backToWidget")}
            </Text>
          </ButtonWithMargin>
        </ActionRequered>
      )) ||
        (processStatus === ProcessStatus.ERROR && (
          <AppError errorMessage="Connect Error" extraMessage={error || ""}>
            {isInIframe && (
              <a
                style={{ textDecoration: "none", width: "100%" }}
                href={window.location.href}
                rel="noreferrer"
                target="_blank"
              >
                <ButtonWithMargin size={ButtonSize.XL} fullWidth={true}>
                  <Text
                    size={TextSize.L}
                    color={Color.SECONDARY}
                    weight={TextWeight.BOLD}
                  >
                    {t("goToRewind")}
                  </Text>
                </ButtonWithMargin>
              </a>
            )}
            <ButtonWithMargin
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                setProcessStatus(ProcessStatus.INITIAL);
              }}
              fullWidth={true}
            >
              <TextStyled
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </TextStyled>
            </ButtonWithMargin>
          </AppError>
        )) ||
        (processStatus === ProcessStatus.NETWORK_ERROR && (
          <ActionRequered
            actionMessage="Network Error"
            extraMessage={"Switch to Rewind Network, please"}
          >
            <ButtonWithMargin
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                setProcessStatus(ProcessStatus.INITIAL);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonWithMargin>
          </ActionRequered>
        )) ||
        (processStatus === ProcessStatus.LOADING && (
          <AppLoading message={t("loading")}></AppLoading>
        )) ||
        (processStatus === ProcessStatus.SUCCESS && (
          <AppSuccess message={t("youAddAddress")}>
            <ButtonStyled
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                setProcessStatus(ProcessStatus.INITIAL);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonStyled>
          </AppSuccess>
        ))}
    </>
  );
}
