import React, { useState } from "react";
import styled from "styled-components";
import { Footer } from "../../../shared/components/Footer";
import { HeaderTitle } from "../../../shared/components/HeaderTitle";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { Color } from "../../../constants/ColorEnum";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { HashCopy } from "../../../shared/components/HashCopy";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Icon } from "../../../shared/components/Icon";
import { IconType } from "../../../constants/IconType";
import MM from "../../../resources/images/MetamaskFox.png";
import CoinbaseWallet from "../../../resources/images/CoinbaseWallet.png";
import { WalletTypeEnum } from "../../cashback/redux/userInfo.slice";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { getUserInfo, removeWallet } from "../../cashback/redux/actions";
import { useQueryParams } from "../../../utils/utils";
import { AppSuccess } from "../../../shared/components/AppSuccess";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { AppSuccessDisconnect } from "../../../shared/components/AppSuccessDisconnect";
import { AppLoading } from "../../../shared/components/AppLoader";
import { AppError } from "../../../shared/components/AppError";
import { useWeb3React } from "@web3-react/core";
import { DECIMALS_CONST } from "../../BuyBonuses/components/Exchange";
import TextAlign from "../../../constants/TextAlign";
import { WidgetStats } from "../../cashback/components/WidgetStats";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0 8px;
`;

const ContentWrapper = styled.div`
  background-color: ${Color.NAVIGATION_WRAPPER};
  width: 100%;
  border-radius: 8px;
  padding: 16px 8px 8px;
  box-sizing: border-box;
`;

const WalletInfoLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`;

const WalletLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const TextStyled = styled(Text)`
  line-height: 32px;
`;

const IconZerion = styled(Icon)`
  svg {
    width: 100 !important;
    height: 20px !important;

    path {
      fill: ${Color.SECONDARY};
    }
  }

  svg path:nth-child(3) {
    fill: #2461ed;
  }
`;

const WalletType = styled.div`
  position: relative;

  ::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
  }
`;

const TextWithMargin = styled(Text)`
  margin-left: 8px;
`;

const WrapMarginTop = styled.div`
  margin-top: 16px;
`;

const IconCoinbase = styled(Icon)`
  svg {
    width: 100px !important;
    height: 20px !important;
    path {
      fill: ${Color.SECONDARY};
    }
  }

  margin-left: 8px;
`;

const ButtonReset = styled(Button)`
  background-color: none;
  border: 1px transparent;
  border-radius: 4px;
  margin-top: 48px;

  &:hover {
    background-color: none !important;
  }
`;

const ButtonResetDisabled = styled(Button)`
  background-color: none;
  border: 1px solid #065b98;
  border-radius: 4px;
  margin-top: 12px;
  pointer-events: none;
  opacity: 0.2;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: #161925;
  border-radius: 4px;
  box-sizing: border-box;
  width: 270px;
  text-align: center;
`;

const TextReset = styled(Text)`
  maprgin-bottom: 2px;
  line-height: 28px;
`;

const TextWrapper = styled.div`
  padding: 16px;
  height: calc(100% - 47px);
  box-sizing: border-box;
`;

const TextResetSmall = styled(Text)`
  line-height: 20px;
`;

const ButtonArea = styled.div`
  display: flex;
  height: 46px;
`;
const ButtonCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  cursor: pointer;
  border-top: 1px solid rgba(84, 84, 88, 0.65);

  :hover {
    background-color: rgba(84, 84, 88, 0.65);
  }
`;

const Reset = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  border-top: 1px solid rgba(84, 84, 88, 0.65);
  border-left: 1px solid rgba(84, 84, 88, 0.65);
  cursor: pointer;

  :hover {
    background-color: rgba(84, 84, 88, 0.65);
  }
`;

const ButtonStyled = styled(Button)`
  margin-top: 32px;
`;

const MoreThenDollarWrap = styled.div`
  position: relative;
  margin: 12px 0 4px;

  ::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
  }
`;

const ContactLink = styled.a`
  margin-top: 16px;
  text-weight: 400;
  text-size: 14px;
  color: ${Color.SECONDARY60};
`;

const TextResetSmallWrap = styled.div`
  height: 100%;
`;

enum ProcessStatus {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export function WalletSettings() {
  const { t } = useCustomTranslation();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [processStatus, setProcessStatus] = useState(ProcessStatus.INITIAL);
  const dispatch = useAppDispatch();
  const { sid } = useQueryParams();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const { account, deactivate } = useWeb3React();
  const { helpLink } = useQueryParams();
  const [hideResetButton, setHideResetButton] = useState(false);

  const resetWallet = async () => {
    setProcessStatus(ProcessStatus.LOADING);
    setShowResetModal(false);

    const result = await dispatch(removeWallet({ sid }));
    console.log(result, "RESULT");
    //@ts-ignore
    if (result.error) {
      // setProcessStatus(ProcessStatus.ERROR);
      setShowResetModal(false);
      setShowErrorModal(true);
      setProcessStatus(ProcessStatus.INITIAL);
      setHideResetButton(true);
      dispatch(getUserInfo({ sid }));

      return;
    }
    deactivate();
    localStorage.clear();
    dispatch(getUserInfo({ sid }));
    setProcessStatus(ProcessStatus.SUCCESS);
  };

  return (
    <Wrapper>
      <HeaderTitle title={t("walletSettings")} />
      {(processStatus === ProcessStatus.INITIAL && (
        <ContentWrapper>
          <WalletType>
            {(userInfo?.addressMemo === WalletTypeEnum.COINBASE && (
              <WalletLine>
                <img
                  src={CoinbaseWallet}
                  alt={t("coinbaseWallet")}
                  width={20}
                  height={20}
                />
                <IconCoinbase
                  icon={IconType.coinbase}
                  size={100}
                  sizeHeight={20}
                />
              </WalletLine>
            )) ||
              (userInfo?.addressMemo === WalletTypeEnum.ZERION && (
                <WalletLine>
                  <IconZerion
                    icon={IconType.zerion}
                    size={80}
                    sizeHeight={20}
                  />
                </WalletLine>
              )) ||
              (userInfo?.addressMemo === WalletTypeEnum.METAMASK && (
                <WalletLine>
                  <img src={MM} alt={t("Metamask")} width={20} height={20} />
                  <TextWithMargin
                    size={TextSize.M}
                    weight={TextWeight.BOLD}
                    color={Color.SECONDARY}
                  >
                    MetaMask wallet
                  </TextWithMargin>
                </WalletLine>
              )) ||
              (userInfo?.addressMemo === WalletTypeEnum.TRUST && (
                <WalletLine>
                  <IconZerion icon={IconType.trust} size={80} sizeHeight={20} />
                </WalletLine>
              ))}
          </WalletType>
          <WalletInfoLine>
            <TextStyled
              size={TextSize.M}
              color={Color.SECONDARY60}
              weight={TextWeight.MEDIUM}
            >
              {t("walletAddress")}
            </TextStyled>

            {userInfo?.address && (
              <HashCopy
                isAddress={true}
                hash={userInfo?.address}
                contentFit={true}
                textColor={Color.SECONDARY60}
                tooltipLeft={"-115px"}
                tooltipTop={"50px"}
              />
            )}
          </WalletInfoLine>

          {Number(
            userInfo?.offChainBalance &&
              (userInfo?.offChainBalance / DECIMALS_CONST) *
                //@ts-ignore
                userInfo?.poolRate
          ) > 1 || hideResetButton ? (
            <MoreThenDollarWrap>
              <Text
                color={Color.SECONDARY}
                size={TextSize.M}
                align={TextAlign.LEFT}
              >
                {t("resetText")}
              </Text>
              <WrapMarginTop>
                <ContactLink
                  href={`${helpLink}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {helpLink}
                </ContactLink>
              </WrapMarginTop>

              <ButtonResetDisabled
                distance={"4px"}
                fullWidth={true}
                buttonType={ButtonType.EMPTY}
                size={ButtonSize.XL}
              >
                {t("resetWallet")}
              </ButtonResetDisabled>
            </MoreThenDollarWrap>
          ) : (
            <ButtonReset
              onClick={() => {
                setShowResetModal(true);
              }}
              distance={"4px"}
              fullWidth={true}
              buttonType={ButtonType.EMPTY}
              size={ButtonSize.XL}
            >
              {t("resetWallet")}
            </ButtonReset>
          )}
        </ContentWrapper>
      )) ||
        (processStatus === ProcessStatus.LOADING && (
          <AppLoading message={t("loading")}></AppLoading>
        )) ||
        (processStatus === ProcessStatus.SUCCESS && (
          <AppSuccessDisconnect
            fullWidth={true}
            message={t("success")}
            extraMessage={t("successDisconnect")}
          >
            <ButtonStyled
              buttonType={ButtonType.PRIMARY}
              size={ButtonSize.XL}
              onClick={() => {
                navigateWithSearchParams({}, RoutesTypes.dashboard);
                window.location.reload();
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("continue")}
              </Text>
            </ButtonStyled>
          </AppSuccessDisconnect>
        )) ||
        (processStatus === ProcessStatus.ERROR && (
          <AppError errorMessage="Request failed" extraMessage={""} />
        ))}
      <Footer />

      {showResetModal && (
        <ModalBackdrop>
          <ModalContent>
            <TextWrapper>
              <TextReset
                size={TextSize.L}
                weight={TextWeight.SEMIBOLD}
                color={Color.SECONDARY}
              >
                {t("walletReset")}
              </TextReset>
              <TextResetSmall
                size={TextSize.XS}
                weight={TextWeight.REGULAR}
                color={Color.SECONDARY}
              >
                {t("walletResetText")}
              </TextResetSmall>
            </TextWrapper>
            <ButtonArea>
              <ButtonCancel onClick={() => setShowResetModal(false)}>
                <Text
                  size={TextSize.M}
                  weight={TextWeight.REGULAR}
                  color={Color.SECONDARY40}
                >
                  {t("cancel")}
                </Text>
              </ButtonCancel>
              <Reset onClick={resetWallet}>
                <Text
                  size={TextSize.M}
                  weight={TextWeight.SEMIBOLD}
                  color={Color.BUTTON_BLUE_HOVER}
                >
                  {t("reset")}
                </Text>
              </Reset>
            </ButtonArea>
          </ModalContent>
        </ModalBackdrop>
      )}
      {showErrorModal && (
        <ModalBackdrop>
          <ModalContent>
            <TextWrapper>
              <TextReset
                size={TextSize.L}
                weight={TextWeight.SEMIBOLD}
                color={Color.SECONDARY}
              >
                {t("resetImpossible")}
              </TextReset>
              <TextResetSmallWrap>
                <TextResetSmall
                  size={TextSize.XS}
                  weight={TextWeight.REGULAR}
                  color={Color.SECONDARY}
                >
                  {t("resetText")}
                </TextResetSmall>
              </TextResetSmallWrap>
            </TextWrapper>
            <ButtonArea>
              <ButtonCancel onClick={() => setShowErrorModal(false)}>
                <Text
                  size={TextSize.M}
                  weight={TextWeight.REGULAR}
                  color={Color.SECONDARY40}
                >
                  {t("cancel")}
                </Text>
              </ButtonCancel>
              <Reset
                onClick={() => {
                  window.open(helpLink, "_blank");
                  setShowErrorModal(false);
                }}
              >
                <Text
                  size={TextSize.M}
                  weight={TextWeight.SEMIBOLD}
                  color={Color.BUTTON_BLUE_HOVER}
                >
                  {t("contactSupport")}
                </Text>
              </Reset>
            </ButtonArea>
          </ModalContent>
        </ModalBackdrop>
      )}

      <WidgetStats />
    </Wrapper>
  );
}
