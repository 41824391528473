import React from "react";

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 7.00511V7C15.5 5.067 13.933 3.5 12 3.5C10.067 3.5 8.5 5.067 8.5 7C8.5 7.0017 8.5 7.0034 8.5 7.00511C8.95093 7 9.44839 7 10 7H14C14.5516 7 15.0491 7 15.5 7.00511ZM17 7.05907V7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 7.01971 7.00011 7.03939 7.00034 7.05904C6.02167 7.13295 5.33857 7.29937 4.77772 7.67412C4.34096 7.96596 3.96596 8.34096 3.67412 8.77772C3 9.78661 3 11.1911 3 14C3 16.8089 3 18.2134 3.67412 19.2223C3.96596 19.659 4.34096 20.034 4.77772 20.3259C5.78661 21 7.19108 21 10 21H14C16.8089 21 18.2134 21 19.2223 20.3259C19.659 20.034 20.034 19.659 20.3259 19.2223C21 18.2134 21 16.8089 21 14C21 11.1911 21 9.78661 20.3259 8.77772C20.034 8.34096 19.659 7.96596 19.2223 7.67412C18.6615 7.29941 17.9785 7.13299 17 7.05907ZM6.86566 8.57656C7.60047 8.50181 8.56432 8.5 10 8.5H14C15.4357 8.5 16.3995 8.50181 17.1343 8.57656C17.8441 8.64876 18.172 8.7764 18.3889 8.92133C18.6619 9.10372 18.8963 9.3381 19.0787 9.61107C19.2236 9.82798 19.3512 10.1559 19.4234 10.8657C19.4982 11.6005 19.5 12.5643 19.5 14C19.5 15.4357 19.4982 16.3995 19.4234 17.1343C19.3512 17.8441 19.2236 18.172 19.0787 18.3889C18.8963 18.6619 18.6619 18.8963 18.3889 19.0787C18.172 19.2236 17.8441 19.3512 17.1343 19.4234C16.3995 19.4982 15.4357 19.5 14 19.5H10C8.56432 19.5 7.60047 19.4982 6.86566 19.4234C6.15592 19.3512 5.82798 19.2236 5.61107 19.0787C5.3381 18.8963 5.10372 18.6619 4.92133 18.3889C4.7764 18.172 4.64876 17.8441 4.57656 17.1343C4.50181 16.3995 4.5 15.4357 4.5 14C4.5 12.5643 4.50181 11.6005 4.57656 10.8657C4.64876 10.1559 4.7764 9.82798 4.92133 9.61107C5.10372 9.3381 5.3381 9.10372 5.61107 8.92133C5.82798 8.7764 6.15592 8.64876 6.86566 8.57656Z"
        fill="white"
      />
      <mask id="path-2-inside-1_4004_7477" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.75 13.8546C13.483 13.5579 14 12.8393 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 12.8393 10.517 13.5579 11.25 13.8546V16C11.25 16.4142 11.5858 16.75 12 16.75C12.4142 16.75 12.75 16.4142 12.75 16V13.8546Z"
        />
      </mask>
      <path
        d="M12.75 13.8546L12.1872 12.4642L11.25 12.8436V13.8546H12.75ZM11.25 13.8546H12.75V12.8436L11.8128 12.4642L11.25 13.8546ZM12.5 12C12.5 12.2075 12.3732 12.3889 12.1872 12.4642L13.3128 15.245C14.5927 14.7269 15.5 13.4712 15.5 12H12.5ZM12 11.5C12.2761 11.5 12.5 11.7239 12.5 12H15.5C15.5 10.067 13.933 8.5 12 8.5V11.5ZM11.5 12C11.5 11.7239 11.7239 11.5 12 11.5V8.5C10.067 8.5 8.5 10.067 8.5 12H11.5ZM11.8128 12.4642C11.6268 12.3889 11.5 12.2075 11.5 12H8.5C8.5 13.4712 9.40727 14.7269 10.6872 15.245L11.8128 12.4642ZM12.75 16V13.8546H9.75V16H12.75ZM12 15.25C12.4142 15.25 12.75 15.5858 12.75 16H9.75C9.75 17.2426 10.7574 18.25 12 18.25V15.25ZM11.25 16C11.25 15.5858 11.5858 15.25 12 15.25V18.25C13.2426 18.25 14.25 17.2426 14.25 16H11.25ZM11.25 13.8546V16H14.25V13.8546H11.25Z"
        fill="white"
        mask="url(#path-2-inside-1_4004_7477)"
      />
    </g>
  </svg>
);
