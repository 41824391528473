import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import CoinbaseWallet from "../../../resources/images/CoinbaseWallet.png";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { Footer } from "../../../shared/components/Footer";
import { HeaderTitle } from "../../../shared/components/HeaderTitle";
import { Text } from "../../../shared/components/Text";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { useQueryParams } from "../../../utils/utils";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { IconType } from "../../../constants/IconType";
import { Icon } from "../../../shared/components/Icon";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0 12px;
`;

const ButtonWithMargin = styled(Button)`
  margin-top: 16px;
`;

const CoinbaseLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const IconStyled = styled(Icon)`
  svg path {
    fill: ${Color.SECONDARY};
  }
  margin-left: 13px;
`;

const CenterBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 144px;
`;

const Title = styled(Text)`
  margin-bottom: 8px;
`;

const TextStyled = styled(Text)`
  margin: 0 14px;
`;

export const MobileCoinbase = () => {
  const { sid, lang } = useQueryParams();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const { t } = useCustomTranslation();
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    if (navigator.userAgent.indexOf("Android") !== -1) {
      console.log("This is an Android device.");
      setIsAndroid(true);
    } else {
      
    }
  }, [])

  return (
    <Wrapper>
      <HeaderTitle title={t("cryptoCashback")} />
      <CenterBlock>
        <CoinbaseLogo>
          <img
            src={CoinbaseWallet}
            alt={t("coinbaseWallet")}
            width={32}
            height={32}
          />
          <IconStyled icon={IconType.coinbase} size={152} sizeHeight={26} />
        </CoinbaseLogo>

        <Title
          weight={TextWeight.BOLD}
          size={TextSize.XXL}
          color={Color.SECONDARY}
        >
          {t("actionRequired")}
        </Title>
        <TextStyled size={TextSize.M} weight={TextWeight.REGULAR} color={Color.SECONDARY}>
          {/* {isAndroid ? t('openCoinbaseOnAndroid') : t("confirmConnectionCoinbase")} */}
          {isAndroid ? t('coinbaseOnAndroid') : t("confirmConnectionCoinbase")}
        </TextStyled>

        {!isAndroid  && <ButtonWithMargin
          onClick={async () => {
            const link = `sid-${sid}_lang-${lang}.redirect.${process.env.REACT_APP_IFRAME_HOST_WITHOUT_HTTP}`;

            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

            if (isIOS) {
              // window.parent.location.href = "cbwallet://dapp?url=" + link;
              window.parent.location.href =
                process.env.REACT_APP_MOBILE_COINBASE_APP_CONST +
                "https://" +
                link;
            } else {
              // window.parent.location.href =
              //   process.env.REACT_APP_MOBILE_COINBASE_APP_CONST +
              //   "https://" +
              //   link;

              // window.parent.location.href =
              //   "" +
              //   process.env.REACT_APP_MOBILE_COINBASE_APP_CONST + "https://" +
              //   process.env.REACT_APP_IFRAME_HOST_WITHOUT_HTTP + 
              //   `?sid=${sid}&lang=${lang}`;

              // console.log('DEEP_LINK', process.env.REACT_APP_MOBILE_COINBASE_APP_CONST + link)
              // window.parent.location.href =
              //   process.env.REACT_APP_MOBILE_COINBASE_APP_CONST + 'https%3A%2F%2F' + link;

              // const parentURL = window.parent.location.href;
              //   console.log("Parent URL: " + parentURL);
              
              var referrer = document.referrer;
                console.log("Parent referrer: " + referrer);

              window.parent.location.href =
                process.env.REACT_APP_MOBILE_COINBASE_APP_CONST + referrer;
            }
          }}
          size={ButtonSize.XL}
          fullWidth={true}
        >
          <Text
            size={TextSize.L}
            color={Color.SECONDARY}
            weight={TextWeight.BOLD}
          >
            {t("openCoinbaseBrowser")}
          </Text>
        </ButtonWithMargin>}
        <ButtonWithMargin
          buttonType={ButtonType.WHITE_BORDER}
          size={ButtonSize.XL}
          onClick={() => navigateWithSearchParams({}, RoutesTypes.dashboard)}
          fullWidth={true}
        >
          <Text
            size={TextSize.L}
            color={Color.SECONDARY}
            weight={TextWeight.BOLD}
          >
            {t("backToWidget")}
          </Text>
        </ButtonWithMargin>
      </CenterBlock>
      <Footer />
    </Wrapper>
  );
};
