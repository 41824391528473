import { ethers } from "ethers";
import { getAddress } from 'viem'

export const getNonceUrlProvider = async (
  userAddress: string,
  tokenContractAddress: string,
  abi: any
) => {
  userAddress = getAddress(userAddress);

  const provider = new ethers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
  const contract = new ethers.Contract(tokenContractAddress, abi, provider);

  try {
    const nonces = await contract.nonces(userAddress);
    return Number(nonces);
  } catch (error) {
    console.error("Error getting nonce:", error);
    throw error;
  }

};
