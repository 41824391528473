import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";

import { Text } from "./Text";
import CoinbaseWallet from "../../resources/images/CoinbaseWallet.png";
import { useCustomTranslation } from "../../features/locale/hooks/useCustomTranslation";
import { Icon } from "./Icon";
import { IconType } from "../../constants/IconType";

export type ActionRequeredCBProps = {
  fullWidth?: boolean;

  height?: string;
};

const Wrapper = styled.div<{ fullWidth?: boolean; height?: string }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "calc(100% - 16px)")};
  height: ${({ height }) => (height ? `${height}` : "auto")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
`;

const CoinbaseLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const IconStyled = styled(Icon)`
  svg path {
    fill: ${Color.SECONDARY};
  }

  svg path:nth-child(3) {
    fill: #3375bb;
  }
`;

const Title = styled(Text)`
  margin-bottom: 8px;
`;

const TextStyled = styled(Text)`
  margin: 0 45px;
`;

export function ActionRequiredTrust({ fullWidth, height }: ActionRequeredCBProps) {
  const { t } = useCustomTranslation();

  return (
    <Wrapper fullWidth={fullWidth} height={height}>
      <CoinbaseLogo>
        <IconStyled icon={IconType.trust} size={152} sizeHeight={26} />
      </CoinbaseLogo>

      <Title
        weight={TextWeight.BOLD}
        size={TextSize.XXL}
        color={Color.SECONDARY}
      >
        {t("actionRequired")}
      </Title>
      <TextStyled
        size={TextSize.M}
        weight={TextWeight.REGULAR}
        color={Color.SECONDARY}
      >
        {t("confirmTrust")}
      </TextStyled>
    </Wrapper>
  );
}
