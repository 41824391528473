import React from "react";

export default (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2508_10247)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16ZM7.70201 7.652V9.56H8.95002V8.456C9.22202 8.408 9.48602 8.324 9.74201 8.204C9.99801 8.076 10.226 7.912 10.426 7.712C10.634 7.512 10.798 7.272 10.918 6.992C11.046 6.704 11.11 6.38 11.11 6.02C11.11 5.668 11.05 5.348 10.93 5.06C10.81 4.764 10.638 4.516 10.414 4.316C10.19 4.108 9.91801 3.948 9.59801 3.836C9.27802 3.724 8.92202 3.668 8.53001 3.668C8.14601 3.668 7.80602 3.724 7.51001 3.836C7.21401 3.94 6.95801 4.084 6.74201 4.268C6.52601 4.444 6.34601 4.648 6.20201 4.88C6.06601 5.112 5.96201 5.352 5.89001 5.6L7.03001 6.056C7.11801 5.696 7.28201 5.4 7.52201 5.168C7.76201 4.936 8.08202 4.82 8.48201 4.82C8.85802 4.82 9.15801 4.924 9.38202 5.132C9.60602 5.332 9.71801 5.616 9.71801 5.984V6.152C9.71801 6.632 9.54202 6.996 9.19001 7.244C8.84601 7.484 8.35001 7.62 7.70201 7.652ZM7.66601 12.116C7.81001 12.26 8.03401 12.332 8.33801 12.332C8.64201 12.332 8.86201 12.26 8.99801 12.116C9.14201 11.964 9.21402 11.772 9.21402 11.54V11.336C9.21402 11.104 9.14201 10.916 8.99801 10.772C8.86201 10.62 8.64201 10.544 8.33801 10.544C8.03401 10.544 7.81001 10.62 7.66601 10.772C7.53001 10.916 7.46201 11.104 7.46201 11.336V11.54C7.46201 11.772 7.53001 11.964 7.66601 12.116Z"
        fill="#238FDC"
      />
    </g>
    <defs>
      <clipPath id="clip0_2508_10247">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
