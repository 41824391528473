import React from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { useAppSelector } from "../../../app/hooks";
import { Text } from "../../../shared/components/Text";
import { TextWeight } from "../../../constants/TextWeight";
import { TextSize } from "../../../constants/TextSize";

const HistoryWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
`;

const ButtonHistory = styled(Button)`
  background-color: ${Color.SECONDARY05};
  border: none;
`;

const ButtonSettings = styled(Button)`
  background-color: ${Color.SECONDARY05};
  border: none;

  width: auto;
  min-width: 123px;

  span {
    margin: 0;
  }

  svg path {
    fill: none;
  }
`;

export const HistoryLine = () => {
  const { t } = useCustomTranslation();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const { userInfo } = useAppSelector((state) => state.userInfo);

  return (
    <HistoryWrap>
      {userInfo?.address ? (
        <ButtonSettings
          onClick={() => {
            navigateWithSearchParams({}, RoutesTypes.walletSettings);
          }}
          distance={"4px"}
          buttonType={ButtonType.EMPTY}
          size={ButtonSize.M}
          icon={IconType.Walletsettings}
        >
          {t("walletSettings")}
        </ButtonSettings>
      ) : (
        <Text
          size={TextSize.XS}
          weight={TextWeight.BOLD}
          color={Color.SECONDARY60}
        >
          {t("rewindWallet")}
        </Text>
      )}

      <ButtonHistory
        onClick={() => {
          navigateWithSearchParams({}, RoutesTypes.historyCashbackFirst);
        }}
        distance={"4px"}
        customMaxWidth={76}
        buttonType={ButtonType.EMPTY}
        size={ButtonSize.M}
        icon={IconType.history}
      >
        {t("history")}
      </ButtonHistory>
    </HistoryWrap>
  );
};
