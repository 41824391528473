import React from "react";

/* eslint-disable max-len */
export default (
  <svg
    width="100"
    height="11"
    viewBox="0 0 525 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M242.084 27.8109C243.668 25.2293 245.722 23.2051 248.244 21.7383C250.826 20.2714 253.76 19.538 257.045 19.538V32.4754H253.789C249.917 32.4754 246.983 33.3848 244.988 35.2037C243.052 37.0225 242.084 40.1908 242.084 44.7086V68.9992H229.763V20.2421H242.084V27.8109Z"
      fill="white"
    />
    <path
      d="M310.669 43.5645C310.669 45.3247 310.552 46.9089 310.317 48.317H274.673C274.967 51.8374 276.199 54.595 278.37 56.5899C280.541 58.5847 283.21 59.5822 286.379 59.5822C290.955 59.5822 294.211 57.6166 296.148 53.6856H309.437C308.029 58.3794 305.33 62.2518 301.34 65.3028C297.35 68.2951 292.451 69.7912 286.643 69.7912C281.949 69.7912 277.724 68.7645 273.969 66.7109C270.273 64.5987 267.369 61.6357 265.256 57.822C263.203 54.0083 262.176 49.6078 262.176 44.6206C262.176 39.5748 263.203 35.145 265.256 31.3312C267.31 27.5175 270.185 24.5839 273.881 22.5303C277.578 20.4768 281.831 19.45 286.643 19.45C291.278 19.45 295.414 20.4475 299.052 22.4423C302.748 24.4372 305.594 27.2828 307.589 30.9792C309.642 34.6169 310.669 38.812 310.669 43.5645ZM297.908 40.0442C297.849 36.8758 296.705 34.3529 294.475 32.4754C292.246 30.5392 289.518 29.5711 286.291 29.5711C283.24 29.5711 280.658 30.5098 278.546 32.3874C276.492 34.2062 275.231 36.7585 274.761 40.0442H297.908Z"
      fill="white"
    />
    <path
      d="M387.254 20.2421L372.996 68.9992H359.707L350.818 34.9396L341.929 68.9992H328.551L314.206 20.2421H326.703L335.328 57.382L344.657 20.2421H357.683L366.835 57.2939L375.46 20.2421H387.254Z"
      fill="white"
    />
    <path
      d="M407.503 14.4335C405.332 14.4335 403.513 13.7588 402.046 12.4093C400.638 11.0011 399.934 9.27029 399.934 7.21675C399.934 5.1632 400.638 3.46169 402.046 2.11222C403.513 0.704073 405.332 0 407.503 0C409.674 0 411.463 0.704073 412.872 2.11222C414.338 3.46169 415.072 5.1632 415.072 7.21675C415.072 9.27029 414.338 11.0011 412.872 12.4093C411.463 13.7588 409.674 14.4335 407.503 14.4335ZM406.242 20.2421V68.9992H393.92V20.2421H406.242Z"
      fill="white"
    />
    <path
      d="M445.434 19.538C451.242 19.538 455.936 21.3862 459.515 25.0826C463.094 28.7203 464.884 33.8248 464.884 40.3962V68.9992H452.563V42.0684C452.563 38.196 451.594 35.233 449.658 33.1794C447.722 31.0672 445.082 30.0111 441.737 30.0111C438.334 30.0111 435.635 31.0672 433.641 33.1794C431.704 35.233 430.736 38.196 430.736 42.0684V68.9992H418.415V20.2421H430.736V26.3147C432.379 24.2025 434.462 22.5597 436.985 21.3862C439.567 20.1541 442.383 19.538 445.434 19.538Z"
      fill="white"
    />
    <path
      d="M473.432 44.4446C473.432 39.5161 474.401 35.145 476.337 31.3312C478.332 27.5175 481.031 24.5839 484.434 22.5303C487.837 20.4768 491.621 19.45 495.787 19.45C498.955 19.45 501.977 20.1541 504.852 21.5622C507.727 22.9117 510.015 24.7306 511.716 27.0188V3.8724H524.214V68.9992H511.716V61.7824C510.191 64.188 508.049 66.1242 505.292 67.591C502.534 69.0578 499.336 69.7912 495.699 69.7912C491.592 69.7912 487.837 68.7351 484.434 66.6229C481.031 64.5107 478.332 61.5477 476.337 57.734C474.401 53.8616 473.432 49.4318 473.432 44.4446ZM511.804 44.6206C511.804 41.6283 511.218 39.0761 510.044 36.9638C508.871 34.7929 507.287 33.1501 505.292 32.0353C503.297 30.8619 501.155 30.2751 498.867 30.2751C496.579 30.2751 494.467 30.8325 492.53 31.9473C490.594 33.0621 489.01 34.7049 487.778 36.8758C486.604 38.988 486.018 41.511 486.018 44.4446C486.018 47.3782 486.604 49.9598 487.778 52.1894C489.01 54.3603 490.594 56.0325 492.53 57.2059C494.525 58.3794 496.638 58.9661 498.867 58.9661C501.155 58.9661 503.297 58.4087 505.292 57.2939C507.287 56.1205 508.871 54.4776 510.044 52.3654C511.218 50.1945 511.804 47.6129 511.804 44.6206Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M34.1705 77.4099C16.4658 76.1297 2.5 61.3605 2.5 43.3294C2.5 25.2984 16.4658 10.5292 34.1705 9.24902V77.4099Z"
      fill="white"
      stroke="white"
      strokeWidth="5"
    />
    <path
      opacity="0.4"
      d="M85.5092 77.4099C67.8045 76.1297 53.8387 61.3605 53.8387 43.3294C53.8387 25.2984 67.8045 10.5292 85.5092 9.24902V77.4099Z"
      fill="white"
      stroke="white"
      strokeWidth="5"
    />
    <path
      opacity="0.6"
      d="M136.848 77.4099C119.143 76.1297 105.177 61.3605 105.177 43.3294C105.177 25.2984 119.143 10.5292 136.848 9.24902V77.4099Z"
      fill="white"
      stroke="white"
      strokeWidth="5"
    />
    <path
      d="M188.187 77.4099C170.482 76.1297 156.516 61.3605 156.516 43.3294C156.516 25.2984 170.482 10.5292 188.187 9.24902V77.4099Z"
      fill="white"
      stroke="white"
      strokeWidth="5"
    />
  </svg>
);
