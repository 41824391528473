import { useAppSelector } from "../../app/hooks";
import { useMemo } from "react";
import { useQueryParams } from "../../utils/utils";

export const useWalletConnectionType = () => {
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const { walletConnectionType } = useQueryParams();

  const walletConnectionTypeMemo =  useMemo(() => {
    if (userInfo?.addressMemo) {
      return userInfo?.addressMemo;
    } else if (walletConnectionType) {
      return walletConnectionType;
    } else {
      return null;
    }
  }, [walletConnectionType, userInfo?.addressMemo]);
  return  {walletConnectionTypeMemo }
};