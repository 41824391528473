import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";

const container = document.getElementById("root")!;
const root = createRoot(container);
const getLibrary = (provider: any) => {
  const library = new ethers.BrowserProvider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
