import { createSlice } from "@reduxjs/toolkit";
import { Loading } from "../../../constants/Loading";
import { TxStatus } from "../../BuyBonuses/redux/actions";
import { getCommonInfo, getTxStatusBySid, getUserInfo } from "./actions";

export const SUCCESS_MESSAGE_PROCESSING = 2400;

export enum WalletTypeEnum {
  WALLET_CONNECT='WALLET_CONNECT',
  COINBASE = 'COINBASE',
  ZERION = 'Zerion',
  TRUST = "Trust Wallet",
  METAMASK = "MetaMask Wallet",
}

//peerMetadata.name
// session.peer.metadata.name

export type CommonInfoState = {
  poolRate: string;
  usdtCirculation: string;
  fwdCirculation: string;
};

export interface IUserInfo {
  offChainBalance: number | null;
  onChainBalance: number | null;
  userId: string | null;
  address: string | null;
  poolRate: string | null;
  history: any[];
  historyFrom: string;
  usdtOnChainBalance: number | null;
  addressMemo?: WalletTypeEnum;
}

export enum TxHashEnum {
  CLAIM = "CLAIM",
  EXCHANGE = "EXCHANGE",
  EXCHANGE_OFFCHAIN = "EXCHANGE_OFFCHAIN",
  BUY_BONUS = "BUY_BONUS",
}

export const hashTypes = {
  [TxHashEnum.CLAIM]: 8,
  [TxHashEnum.EXCHANGE]: 10,
  [TxHashEnum.BUY_BONUS]: 9,
}

export type UserState = {
  loading: Loading;
  userInfo?: IUserInfo;
  balanceIsPending?: boolean;
  activeTxHash?: string;
  activeTxHashUsage?: TxHashEnum;
  activeTxHashStatus?: TxStatus;
  activeTxHashMessage?: string;
  errorMessage?: string;
  commonInfo: {
    info?: CommonInfoState;
    loading: Loading;
    errorMessage?: string;
  };

  txStatus: {
    txId?: string;
    type?: number;
    loading: Loading;
    errorMessage?: string;
  }
  txString?: string;
  showSubmiTxMessage?: boolean;
  // walletConnectionType?: WalletTypeEnum;
};

export const initialState: UserState = {
  loading: Loading.INITIAL,
  balanceIsPending: false,
  commonInfo: {
    loading: Loading.INITIAL,
  },
  txStatus: {
    loading: Loading.INITIAL,
  },
};

export const authSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setBalanceIsPending: (
      state,
      action: {
        payload: {
          status: boolean;
          txHash?: string;
          txHashUsage?: TxHashEnum;
          txHashStatus?: TxStatus;
          txString?: string;
        };
      }
    ) => {
      state.balanceIsPending = action.payload.status;
      if (action.payload.status) {
        state.activeTxHash = action.payload.txHash;
        state.activeTxHashUsage = action.payload.txHashUsage;
        state.txString = action.payload.txString;
        //activeTxHashStatus
      }

      if (action.payload.txHashStatus) {
        state.activeTxHashStatus = action.payload.txHashStatus;
      }
    },
    resetActiveTx: (state) => {
      state.activeTxHash = undefined;
      state.txString = undefined;

      state.activeTxHashUsage = undefined;
      state.activeTxHashStatus = undefined;
      state.activeTxHashMessage = undefined;
    },
    // setWalletConnection: (state, action) => {
    //   state.walletConnectionType = action.payload;
    // }
    setTxMessage: (state, action) => {
      state.txString = action.payload;
    }, 
    setShowSubmiTxMessage: (state, action) => {
      state.showSubmiTxMessage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.pending, (state) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      state.userInfo = action.payload;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.errorMessage = action.error.message;
      state.loading = Loading.ERROR;
    });
    builder.addCase(getCommonInfo.pending, (state) => {
      state.commonInfo.loading = Loading.PENDING;
    });
    builder.addCase(getCommonInfo.fulfilled, (state, action) => {
      state.commonInfo.loading = Loading.SUCCESS;
      state.commonInfo.info = action.payload;
    });
    builder.addCase(getCommonInfo.rejected, (state, action) => {
      state.commonInfo.errorMessage = action.error.message;
      state.commonInfo.loading = Loading.ERROR;
    });

    // builder.addCase(getClaimStatusBySid.pending, (state) => {
    //   state.claimStatus.loading = Loading.PENDING;
    // });
    // builder.addCase(getClaimStatusBySid.fulfilled, (state, action) => {
    //   state.claimStatus.loading = Loading.SUCCESS;
    //   console.log('CLAIM_INFO_PAYLOAD', action.payload)
    //   state.claimStatus.txId = action.payload;
    //   state.activeTxHash = action.payload;
    //   state.activeTxHashUsage = TxHashEnum.CLAIM;
    //   if (state.claimStatus.txId) {
    //     state.balanceIsPending = true;
    //     state.txString = "Tokens Claim."
    //   }
    // });
    // builder.addCase(getClaimStatusBySid.rejected, (state, action) => {
    //   state.claimStatus.errorMessage = action.error.message;
    //   state.claimStatus.loading = Loading.ERROR;
    // });


    builder.addCase(getTxStatusBySid.pending, (state) => {
      state.txStatus.loading = Loading.PENDING;
    });
    builder.addCase(getTxStatusBySid.fulfilled, (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.txStatus.loading = Loading.SUCCESS;
        const activeTx = action.payload[0];
        state.txStatus.txId = activeTx.hash;
        state.activeTxHash = activeTx.hash;
        if (activeTx.type === hashTypes[TxHashEnum.CLAIM]) {
          state.activeTxHashUsage = TxHashEnum.CLAIM;
        } else if (activeTx.type === hashTypes[TxHashEnum.EXCHANGE]) {
          state.activeTxHashUsage = TxHashEnum.EXCHANGE;
        } else if (activeTx.type === hashTypes[TxHashEnum.BUY_BONUS]) {
          state.activeTxHashUsage = TxHashEnum.BUY_BONUS;
        }

        if (state.txStatus.txId) {
          state.balanceIsPending = true;
        }
      }

      state.showSubmiTxMessage = false;
    });
    builder.addCase(getTxStatusBySid.rejected, (state, action) => {
      state.txStatus.errorMessage = action.error.message;
      state.txStatus.loading = Loading.ERROR;
      
      state.showSubmiTxMessage = false;
    });  
  },
});

export const { setBalanceIsPending, resetActiveTx, setTxMessage, setShowSubmiTxMessage } = authSlice.actions;

export default authSlice.reducer;
