export default (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66732 14.3327C6.88477 15.5263 8.32899 16.123 10 16.123C11.671 16.123 13.1033 15.5263 14.2969 14.3327C15.5143 13.1152 16.123 11.671 16.123 10C16.123 8.32899 15.5143 6.8967 14.2969 5.70312C13.1033 4.48568 11.671 3.87695 10 3.87695C8.32899 3.87695 6.88477 4.48568 5.66732 5.70312C4.47374 6.8967 3.87695 8.32899 3.87695 10C3.87695 11.671 4.47374 13.1152 5.66732 14.3327ZM4.5931 4.62891C6.09701 3.125 7.89931 2.37305 10 2.37305C12.1007 2.37305 13.8911 3.125 15.3711 4.62891C16.875 6.10894 17.627 7.89931 17.627 10C17.627 12.1007 16.875 13.903 15.3711 15.4069C13.8911 16.8869 12.1007 17.627 10 17.627C7.89931 17.627 6.09701 16.8869 4.5931 15.4069C3.11306 13.903 2.37305 12.1007 2.37305 10C2.37305 7.89931 3.11306 6.10894 4.5931 4.62891ZM9.24805 6.16862H10.752V10.752H9.24805V6.16862ZM9.24805 12.2917H10.752V13.8314H9.24805V12.2917Z"
      fill="#FFE176"
    />
  </svg>
);
