import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
// import { FlexAlignItems, FlexJustifyContent, HorizontalLayout } from '../Layout';
import { transparentize } from "polished";
import { TextSize } from "../../constants/TextSize";
import { FlexAlignItems, FlexJustifyContent } from "./FlexBox";
import { Color } from "../../constants/ColorEnum";
import { TextWeight } from "../../constants/TextWeight";
import { IconType } from "../../constants/IconType";
import { Icon, IconSize } from "./Icon";
import { Text } from "./Text";
import { WhiteSpace } from "../../constants/WhiteSpace";
import { HorizontalLayout } from "./HorizontalLayout";
import { useQueryParams } from "../../utils/utils";

export enum HTMLButtonType {
  BUTTON = "button",
  SUBMIT = "submit",
}

export enum ButtonType {
  DARK = "dark",
  PRIMARY = "primary",
  CLAIM = "claim",
  CLAIM_DISABLED = "claim_disabled",
  DISABLED = "disabled",
  WHITE_BORDER = "white_border",
  EMPTY = 'empty',

  SECONDARY = "secondary",
  GHOST = "ghost",
  DANGER = "danger",
  DANGER_LIGHT = "dangerLight",
  GREEN = "green",
  BURNING_ORANGE = "burningOrange",
  COLD_FUSION = "coldFusion",
  COLD_FUSION01 = "coldFusion01",
  DAGOBAH_FOREST = "dagobahForest",
  /* use invertColor for black */
  WHITE = "white",
  BORDERED_WHITE = "bordered_white",
  // TODO: remove or rename
  FLOATING = "floating",
  PRIMARY_LIGHT = "primary_light",
  CHROME_ORANGE = "chromeOrange",
  CHROME_ORANGE01 = "chromeOrange01",
}

export enum ButtonSize {
  XXL = 56,
  XL = 48,
  L = 42,
  R = 38,
  M = 32,
  S = 24,
}

const SizeButtonConfig = {
  [ButtonSize.XXL]: {
    borderRadius: "8px",
    fontSize: TextSize.L,
    marginContent: "25px",
    marginIcon: "15px",
  },
  [ButtonSize.XL]: {
    borderRadius: "8px",
    fontSize: TextSize.L,
    marginContent: "25px",
    marginIcon: "15px",
  },
  [ButtonSize.L]: {
    borderRadius: "8px",
    fontSize: TextSize.M,
    marginContent: "20px",
    marginIcon: "12px",
  },
  [ButtonSize.R]: {
    borderRadius: "6px",
    fontSize: TextSize.S,
    marginContent: "16px",
    marginIcon: "9px",
  },
  [ButtonSize.M]: {
    borderRadius: "6px",
    fontSize: TextSize.XS,
    marginContent: "16px",
    marginIcon: "9px",
  },
  [ButtonSize.S]: {
    borderRadius: "4px",
    fontSize: TextSize.XS,
    marginContent: "16px",
    marginIcon: "5px",
  },
};

interface StyledButtonProps {
  size: ButtonSize;
  onlyIcon?: boolean;
  fullWidth?: boolean;
  buttonConfig: ButtonConfig;
  active?: boolean;
  maxBorderRadius?: boolean;
  customHeight?: number;
  customMaxWidth?: number;
  buttonType?: ButtonType;
}
interface StyledButtonContentProps {
  size: ButtonSize;
  onlyIcon?: boolean;
  fullWidth?: boolean;
  justifyContent?: FlexJustifyContent;
}
type ButtonConfig = {
  textColor: Color;
  backgroundHover: Color | string;
  background: Color | string;
  borderColor?: Color | string;
  borderHoverColor?: Color | string;
};

function getButtonConfig(
  buttonType: ButtonType,
  invertColors: boolean,
  lockColors: boolean
) {
  const color = Color; //change this when we have theme
  switch (buttonType) {
    case ButtonType.PRIMARY:
    default:
      return {
        textColor: color.SECONDARY,
        backgroundHover: color.BUTTON_BLUE_HOVER,
        background: color.BUTTON_BLUE,
      };

    case ButtonType.CLAIM:
      return {
        textColor: color.SECONDARY,
        backgroundHover: color.GREEN_CLAIM_HOVER,
        background: color.GREEN_CLAIM,
      };

    case ButtonType.CLAIM_DISABLED:
      return {
        textColor: color.SECONDARY40,
        backgroundHover: color.GREEN_CLAIM_HOVER,
        background: color.GREEN_CLAIM_DISABLED,
      };

      case ButtonType.EMPTY:
        return {
          textColor: color.SECONDARY,
          backgroundHover: color.SECONDARY10,
          background: 'rgba(0,0,0,.0)',
          borderColor: color.SECONDARY10,
        };

    case ButtonType.DISABLED:
      return {
        textColor: color.BUTTON_DISABLED_TEXT,
        backgroundHover: color.BUTTON_DISABLED,
        background: color.BUTTON_DISABLED,
      };

    case ButtonType.WHITE_BORDER:
      return {
        textColor: color.SECONDARY,
        backgroundHover: color.SECONDARY10,
        background: "transparent",
      };

    case ButtonType.SECONDARY:
      return {
        textColor: invertColors ? color.SECONDARY : color.PRIMARY,
        backgroundHover: invertColors ? color.SECONDARY05 : color.PRIMARY05,
        background: invertColors ? color.SECONDARY10 : color.PRIMARY10,
      };
    case ButtonType.GHOST:
      return {
        textColor: invertColors ? color.SECONDARY : color.PRIMARY,
        backgroundHover: invertColors ? color.SECONDARY05 : color.PRIMARY05,
        background: "transparent",
      };
    case ButtonType.DANGER:
      return {
        textColor: color.SECONDARY,
        backgroundHover: transparentize(0.1, Color.RED_HOT_HOOD),
        background: color.RED_HOT_HOOD,
      };
    case ButtonType.DANGER_LIGHT:
      return {
        textColor: color.RED_HOT_HOOD,
        backgroundHover: transparentize(0.8, Color.RED_HOT_HOOD),
        background: transparentize(0.9, Color.RED_HOT_HOOD),
      };
    case ButtonType.GREEN:
      return {
        textColor: color.PRIMARY,
        backgroundHover: transparentize(0.1, color.GREEN),
        background: color.GREEN,
      };
    case ButtonType.DAGOBAH_FOREST:
      return {
        textColor: color.SECONDARY,
        backgroundHover: transparentize(0.1, color.DAGOBAH_FOREST),
        background: color.DAGOBAH_FOREST,
      };
    case ButtonType.BURNING_ORANGE:
      return {
        textColor: color.SECONDARY,
        backgroundHover: transparentize(0.1, color.BURNING_ORANGE),
        background: color.BURNING_ORANGE,
      };
    case ButtonType.CHROME_ORANGE:
      return {
        textColor: invertColors ? color.SECONDARY : color.PRIMARY,
        backgroundHover: transparentize(0.1, color.CHROME_ORANGE),
        background: color.CHROME_ORANGE,
      };
    case ButtonType.CHROME_ORANGE01:
      return {
        textColor: color.PRIMARY,
        backgroundHover: transparentize(0.95, color.CHROME_ORANGE),
        background: transparentize(0.9, color.CHROME_ORANGE),
      };
    case ButtonType.COLD_FUSION:
      return {
        textColor: color.SECONDARY,
        backgroundHover: transparentize(0.1, color.COLD_FUSION),
        background: color.COLD_FUSION,
      };
    case ButtonType.COLD_FUSION01:
      return {
        textColor: color.PRIMARY,
        backgroundHover: transparentize(0.95, color.COLD_FUSION),
        background: transparentize(0.9, color.COLD_FUSION),
      };
    case ButtonType.WHITE:
      return {
        textColor: invertColors ? color.SECONDARY : color.PRIMARY,
        backgroundHover: invertColors ? color.PRIMARY90 : color.SECONDARY90,
        background: invertColors ? color.PRIMARY : color.SECONDARY,
      };
    case ButtonType.BORDERED_WHITE:
      return {
        textColor: color.PRIMARY,
        backgroundHover: color.SECONDARY90,
        background: color.SECONDARY,
        borderColor: color.PRIMARY10,
        borderHoverColor: color.PRIMARY20,
      };
    case ButtonType.FLOATING:
      return {
        textColor: invertColors ? color.PRIMARY : color.SECONDARY,
        backgroundHover: invertColors ? color.SECONDARY10 : color.PRIMARY10,
        background: invertColors ? color.SECONDARY20 : color.PRIMARY20,
      };
    case ButtonType.PRIMARY_LIGHT:
      return {
        textColor: color.ACCENT,
        backgroundHover: color.ACCENT20,
        background: color.ACCENT10,
      };
    case ButtonType.DARK:
      return {
        textColor: color.SECONDARY,
        backgroundHover: color.PRIMARY90,
        background: color.PRIMARY,
      };
  }
}
const StyledButton = styled.button<StyledButtonProps>`
  border: ${({ buttonConfig }) =>
    buttonConfig.borderColor
      ? `1px solid ${buttonConfig.borderColor}`
      : "none"};
  padding: 0;
  border-radius: 4px;
  // border-radius: ${({ size, maxBorderRadius }) =>
    maxBorderRadius ? "50%" : SizeButtonConfig[size].borderRadius};
  height: ${({ size, customHeight }) =>
    customHeight ? `${customHeight}px` : `${size}px`};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  min-width: ${({ size }) => `${size}px`};
  max-width: ${({ customMaxWidth }) => `${customMaxWidth}px`};
  font-family: IBM Plex Sans, sans-serif;
  background: ${({ buttonConfig }) => buttonConfig.background};
  :hover:not([disabled]) {
    background: ${({ buttonConfig }) => buttonConfig.backgroundHover};
    border: ${({ buttonConfig }) =>
      buttonConfig.borderHoverColor
        ? `1px solid ${buttonConfig.borderHoverColor}`
        : "none"};
  }
  :focus {
    outline: none;
  }
  ${({ active, buttonConfig }) =>
    active
      ? css`
          background: ${buttonConfig.backgroundHover};
        `
      : null}
  ${({ disabled, buttonType }) => {
    const style =
      disabled && buttonType !== ButtonType.CLAIM_DISABLED
        ? "opacity: 0.2; pointer-events: none;"
        : buttonType === ButtonType.CLAIM_DISABLED
        ? "pointer-events: none;"
        : "cursor: pointer;";

    return css`
      ${style}
    `;
  }}
`;
const StyledButtonContent = styled.span<StyledButtonContentProps>`
  margin: ${({ onlyIcon, size }) =>
    onlyIcon
      ? SizeButtonConfig[size].marginIcon
      : `0 ${SizeButtonConfig[size].marginContent}`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export type ButtonProps = {
  type?: HTMLButtonType;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseDown?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  size?: ButtonSize;
  weight?: TextWeight;
  buttonType?: ButtonType;
  icon?: IconType | null;
  disabled?: boolean;
  children?: string | JSX.Element;
  iconReverse?: boolean;
  fullWidth?: boolean;
  invertColors?: boolean;
  lockColors?: boolean;
  active?: boolean;
  dataTestid?: string;
  maxBorderRadius?: boolean;
  iconSize?: IconSize | number;
  justifyContent?: FlexJustifyContent;
  customHeight?: number;
  customMaxWidth?: number;
  distance?: string;
};

/**
 * This is the button component
 */
export function Button({
  type = HTMLButtonType.BUTTON,
  onClick,
  onMouseDown,
  children,
  className,
  icon,
  buttonType = ButtonType.PRIMARY,
  size = ButtonSize.M,
  weight = TextWeight.SEMIBOLD,
  disabled = false,
  iconReverse = false,
  fullWidth = false,
  invertColors = false,
  lockColors = false,
  active = false,
  dataTestid,
  maxBorderRadius = false,
  iconSize = IconSize.M,
  justifyContent = FlexJustifyContent.CENTER,
  customHeight,
  customMaxWidth,
  distance,
  ...props
}: PropsWithChildren<ButtonProps>) {
  let iconContent = null;
  const buttonConfig = getButtonConfig(buttonType, invertColors, lockColors);
  if (icon) {
    iconContent = (
      <Icon icon={icon} size={iconSize} color={buttonConfig.textColor} />
    );
  }
  const { isMobile } = useQueryParams();

  return (
    <StyledButton
      type={type}
      className={className}
      onClick={
        disabled
          ? undefined
          : (param) => {
              if (isMobile === "true") {
                setTimeout(() => {
                  //@ts-ignore
                  onClick(param);
                }, Number(process.env.REACT_APP_MOBILE_HOVER_DELAY));
              } else {
                //@ts-ignore
                onClick(param);
              }
            }
      }
      onMouseDown={disabled ? undefined : onMouseDown}
      disabled={disabled}
      size={size}
      fullWidth={fullWidth}
      buttonConfig={buttonConfig}
      active={active}
      data-testid={dataTestid}
      maxBorderRadius={maxBorderRadius}
      customHeight={customHeight}
      customMaxWidth={customMaxWidth}
      buttonType={buttonType}
      {...props}
    >
      <StyledButtonContent
        size={size}
        onlyIcon={!children}
        justifyContent={justifyContent}
      >
        <HorizontalLayout distance={distance || '8px'} alignItems={FlexAlignItems.CENTER}>
          {!iconReverse && iconContent}
          {children && (
            <Text
              whiteSpace={WhiteSpace.NOWRAP}
              size={SizeButtonConfig[size].fontSize}
              weight={weight}
              color={buttonConfig.textColor}
              {...props}
            >
              {children}
            </Text>
          )}
          {iconReverse && iconContent}
        </HorizontalLayout>
      </StyledButtonContent>
    </StyledButton>
  );
}
