import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { WidgetStats } from "./WidgetStats";
import { useAppSelector } from "../../../app/hooks";
import { AppError } from "../../../shared/components/AppError";
import { AppLoading } from "../../../shared/components/AppLoader";
import { Loading } from "../../../constants/Loading";
import { UserInfo } from "./UserInfo";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { Footer } from "../../../shared/components/Footer";
import { useQueryParams } from "../../../utils/utils";

const Header = styled.div<{isMobile: string}>`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: ${({isMobile}) => isMobile === 'true' ? '16px 0 8px' : '16px 0 8px' };
  color: ${Color.SECONDARY};
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0 8px;
`;

const LoaderWrapp = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  margin-right: -30px;
`;

export enum ProcessStatus {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  CANCEL = "CANCEL",
  WAITING_SIGN = "WAITING_SIGN",
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
}

export function Cashback() {
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const errorMessage = useAppSelector((state) => state.userInfo.errorMessage);
  const loading = useAppSelector((state) => state.userInfo.loading);
  const loadingLocale = useAppSelector((state) => state.locale.loading);

  const { t } = useCustomTranslation();
  const { isMobile } = useQueryParams();

  return (
    <Wrapper>
      <Header isMobile={isMobile}>{t("cryptoCashback")}</Header>
      {(userInfo && (
        <>
          {loadingLocale === Loading.PENDING ? (
            <LoaderWrapp>
              <AppLoading message={"Getting Data"} fullHeight={true} />
            </LoaderWrapp>
          ) : (
            <>
              <UserInfo />
              {/* <WidgetStats></WidgetStats> */}
            </>
          )}
        </>
      )) ||
        (errorMessage && (
          <AppError errorMessage={errorMessage} extraMessage={""} />
        )) ||
        (loading === Loading.PENDING && (
          <AppLoading message={t("gettingClientData")} fullHeight={true} />
        ))}
      <Footer />
    </Wrapper>
  );
}
