import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAccount, useConnect } from "wagmi";
import { disconnect } from '@wagmi/core'
import { injected } from 'wagmi/connectors';
import { Color } from "../../../constants/ColorEnum";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { ActionRequered } from "../../../shared/components/ActionRequired";
import { Text } from "../../../shared/components/Text";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { useQueryParams, useSetQueryParams } from "../../../utils/utils";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getUserInfo } from "../../cashback/redux/actions";
import { HashCopy } from "../../../shared/components/HashCopy";
import WalletConnect from "../../../resources/images/WalletConnect.png";
import CoinBase from "../../../resources/images/Coinbase.png";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import axios from "axios";
import { ProcessOptions } from "../../cashback/components/ProcessOptions";
import { ProcessStatus } from "../../cashback/components/UserInfo";
import { WalletTypeEnum } from "../../cashback/redux/userInfo.slice";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../cashback/components/connectors";
import { useWalletConnectionType } from "../../../shared/hooks/useWalletConnectionType";
import { CoinbaseIncorrectAddress } from "./CoinbaseIncorrectAddress";
import { config } from "../../../layouts/config";




export enum WCEventsEnum {
  ACCOUNT_CONNECTED = "ACCOUNT_CONNECTED",
  ACCOUNT_DISCONNECTED = "ACCOUNT_DISCONNECTED",
}

export enum USAGE_CASE {
  BUY_BONUSES = 'buy-bonuse',
  EXCHANGE = 'exchange'
}

const ButtonWithMargin = styled(Button)`
  margin-top: 16px;
`;

interface IncorrectAddressActionProps {
  callback?: any
}

export function IncorrectAddressAction({ callback }: IncorrectAddressActionProps) {
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const { t } = useCustomTranslation();

  const { userInfo } = useAppSelector((state) => state.userInfo);

  const { sid, isDublicate } = useQueryParams();

  // const walletConnectionType = useAppSelector(state => state.userInfo.walletConnectionType);

  // const walletConnectionTypeMemo =  useMemo(() => {
  //   if (userInfo?.addressMemo) {
  //     return userInfo?.addressMemo;
  //   } else if (walletConnectionType) {
  //     return walletConnectionType;
  //   } else {
  //     return null;
  //   }
  // }, [walletConnectionType, userInfo?.addressMemo]);
  const { walletConnectionTypeMemo } = useWalletConnectionType();

  const dispatch = useAppDispatch();

  const [processStatus, setProcessStatus] = useState(ProcessStatus.ERROR);
  const setAddressCalledRef = useRef(false);
  const setAddress = async (address: string, walletType: WalletTypeEnum | string) => {
    setAddressCalledRef.current = true;
    try {
      setProcessStatus(ProcessStatus.LOADING);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/clients/set-address-by-operator`,
        {
          address: address,
          sid,
          addressMemo: walletType,
        }
      );

      if (response) {
        setProcessStatus(ProcessStatus.SUCCESS);

        setTimeout(() => {
          dispatch(getUserInfo({ sid }));
        }, 1000);
      }
    } catch (err) {
      setProcessStatus(ProcessStatus.ERROR);
    }
  };
  // const walletConnectProvider = useContext(EthereumProviderContext);

  useEffect(() => {
    if (processStatus === ProcessStatus.INITIAL) {
      navigateWithSearchParams({}, RoutesTypes.dashboard)
    }
  }, [processStatus]);

  // useEffect(() => {
  //   if (walletConnectionTypeMemo === WalletTypeEnum.COINBASE) return;
  //   if (!session?.topic && client) {
  //     connect()
  //     ;
  //   }
  // }, [session?.topic, client, connect, userInfo?.address, accounts, isDublicate, navigateWithSearchParams])

  // useEffect(() => {
  //   if (walletConnectionTypeMemo === WalletTypeEnum.COINBASE) return;
  //   if (session?.topic) {
  //     if (userInfo?.address && ((userInfo?.address?.toLowerCase() === accounts[0].toLowerCase()))) {
  //       navigateWithSearchParams('', RoutesTypes.dashboard)
  //     }
  //   }
  // }, [accounts, session?.topic, userInfo?.address])
  const { address } = useAccount();
  const { connect } = useConnect();

  useEffect(() => {
    console.log('CONNECT_NEW_ADDRESS', address)
    if (walletConnectionTypeMemo === WalletTypeEnum.COINBASE) return;
    if (address) {
      if (isDublicate && !userInfo?.address && address && processStatus === ProcessStatus.ERROR) {
        // setAddress(accounts[0], WalletTypeEnum.WALLET_CONNECT);
        setAddress(address, "");
      }

      if (address?.toLowerCase() === userInfo?.address?.toLowerCase()) { 

        console.log('RIGHT_ADDRESS')
        navigateWithSearchParams({}, RoutesTypes.dashboard)
      }
    }
  }, [address]);

  // useEffect(() => {
  //   if (isDublicate === 'true' && userInfo?.address && accounts[0]) {
  //     navigateWithSearchParams('', RoutesTypes.dashboard)
  //   }
  // }, [session?.topic, client, isDublicate, userInfo?.address, accounts, navigateWithSearchParams])

  const { account, activate, deactivate } = useWeb3React();

  const [activateNewWallet , setActivateNewWallet] = useState(false);

  // useEffect(() => {
  //   if (account && activateNewWallet) {
  //     setAddress(account, WalletTypeEnum.COINBASE);
  //   }
  // }, [account]);
  const { setQueryParams } = useSetQueryParams();

  // useEffect(() => {
  //   if (
  //     (walletConnectionTypeMemo === WalletTypeEnum.COINBASE)
  //   ) {
  //     // dispatch(setWalletConnection(WalletTypeEnum.COINBASE));
  //     setQueryParams({walletConnectionType: WalletTypeEnum.COINBASE});
  //     if (account) {
  //       deactivate();
  //       localStorage.clear();
  //       window.location.reload();
  //     } else {
  //       // setActivateNewWallet(true);
  //       // activate(connectors.coinbaseWallet);
  //     }
  //   }
  // }, []);

  // const toggleCoinbaseReconnect = () => {
  //   // dispatch(setWalletConnection(WalletTypeEnum.COINBASE));
  //   setQueryParams({walletConnectionType: WalletTypeEnum.COINBASE});
  //   if (account) {
  //     deactivate();
  //     localStorage.clear();
  //     window.location.reload();
  //   } else {
  //     setActivateNewWallet(true);
  //     activate(connectors.coinbaseWallet);
  //   }
  // }

  return (
    <>
      {(processStatus === ProcessStatus.ERROR && (
        <>
          {/* {walletConnectionTypeMemo === WalletTypeEnum.COINBASE ? (
            <CoinbaseIncorrectAddress
              toggleCoinbaseReconnect={toggleCoinbaseReconnect}
            />
          ) : ( */}
            <ActionRequered
              actionMessage={
                isDublicate === "true"
                  ? t("addressAlreadyInUse")
                  : t("incorrectAddress")
              }
              extraMessage={
                isDublicate === "true"
                  ? t("addressAlreadyInUseText")
                  : t("incorrectAddressText")
              }
              image={
                walletConnectionTypeMemo === WalletTypeEnum.COINBASE
                  ? CoinBase
                  : WalletConnect
              }
              imageSize={152}
              height={"440px"}
            >
              {userInfo?.address && (
                <HashCopy
                  hash={userInfo?.address || ""}
                  isAddress={true}
                ></HashCopy>
              )}
              <Text color={Color.SECONDARY}>
                {t("connectedAddress")} -{" "}
                <HashCopy
                  hash={address || ""}
                  isAddress={true}
                ></HashCopy>
              </Text>

              <ButtonWithMargin
                size={ButtonSize.XL}
                fullWidth={true}
                onClick={async () => {
                  if (walletConnectionTypeMemo === WalletTypeEnum.COINBASE) {
                    // dispatch(setWalletConnection(WalletTypeEnum.COINBASE));
                    setQueryParams({
                      walletConnectionType: WalletTypeEnum.COINBASE,
                    });
                    if (account) {
                      deactivate();
                      localStorage.clear();
                      window.location.reload();
                    } else {
                      setActivateNewWallet(true);
                      activate(connectors.coinbaseWallet);
                    }
                  } else {
                    if (callback) {
                      callback();
                    } else {
                      await disconnect(config);
                      localStorage.clear();
                      connect({ connector: injected() });
                      // disconnect(config).then(() => {
                      //   localStorage.clear();
                      //   window.location.reload();
                      // });
                    }
                  }
                }}
              >
                <Text
                  size={TextSize.L}
                  color={Color.SECONDARY}
                  weight={TextWeight.BOLD}
                >
                  {t("reconnect")}
                </Text>
              </ButtonWithMargin>
              <ButtonWithMargin
                buttonType={ButtonType.WHITE_BORDER}
                size={ButtonSize.XL}
                onClick={() => {
                  navigateWithSearchParams({}, RoutesTypes.dashboard);
                }}
                fullWidth={true}
              >
                <Text
                  size={TextSize.L}
                  color={Color.SECONDARY}
                  weight={TextWeight.BOLD}
                >
                  {t("backToWidget")}
                </Text>
              </ButtonWithMargin>
            </ActionRequered>
          {/* )} */}
        </>
      )) || (
        <ProcessOptions
          processStatus={processStatus}
          setProcessStatus={setProcessStatus}
        />
      )}
    </>
  );
}
