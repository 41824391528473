import React from "react";
import styled from "styled-components";
import { Footer } from "../../../shared/components/Footer";
import { HeaderTitle } from "../../../shared/components/HeaderTitle";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { IncorrectAddressAction } from "../components/IncorrectAddressActtion";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0 8px;
`;

export function SwitchWallet() {
  const { t } = useCustomTranslation();

  

  return (
    <Wrapper>
      <HeaderTitle title={t("switchYourWallet")} />
      <IncorrectAddressAction />
      <Footer />
    </Wrapper>
  );
}
