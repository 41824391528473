import moment from "moment";
import styled from "styled-components";
import { Color } from "../../../../constants/ColorEnum";
import { TextSize } from "../../../../constants/TextSize";
import { TextWeight } from "../../../../constants/TextWeight";
import { HashCopy } from "../../../../shared/components/HashCopy";
import { Text } from "../../../../shared/components/Text";
import { formatPriceNumberWithCurrency } from "../../../../utils/utils";
import { useCustomTranslation } from "../../../locale/hooks/useCustomTranslation";

const TextStyledHeight = styled(Text)`
  line-height: 32px;
`;

// const Text = styled(Text)`
//   line-height: 22px;
// `;

const InfoBlock = styled.div`
  position: relative;
  box-sizing: border-box;
  background-color: ${Color.NAVIGATION_WRAPPER};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  width: 100%;
  margin-bottom: 4px;
  background: ${Color.NAVIGATION_WRAPPER};
  border-radius: 8px;
`;

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 4px;
  margin-bottom: 4px;

  ::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
  }
`;

const InfoWrapper3 = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
  margin-bottom: 4px;

  ::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
  }
`;

const InfoWrapper2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: -4px;
`;

const InfoWrapper4 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
`;

const InfoWrapper5 = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 4px;

  ::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
  }
`;

const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
`;

export enum TokenBurnEnum {
  SMART_EXCHANGE = "SMART_EXCHANGE",
  QUICK_EXCHANGE = "QUICK_EXCHANGE",
}

export interface ITokenBurnItem {
  createdat: Date;
  fwdamount: string;
  poolrate: string;
  txhash: string;
  typeid: number;
  usdtamount: string;
}

interface tokenBurnItemProps {
  tokenBurnItem: ITokenBurnItem;
}

export const TokenBurnItem = ({ tokenBurnItem }: tokenBurnItemProps) => {
  const { t } = useCustomTranslation();

  return (
    <InfoBlock>
      <InfoWrapper>
        <Flex>
          <Text
            size={TextSize.M}
            weight={TextWeight.BOLD}
            color={Color.COLOR_BLUE_TAG}
          >
            {moment(tokenBurnItem?.createdat).local().format("DD.MM.YYYY")}
          </Text>
          &nbsp;
          <Text
            size={TextSize.M}
            weight={TextWeight.BOLD}
            color={Color.SECONDARY60}
          >
            {moment(tokenBurnItem?.createdat).local().format("HH:mm")}
          </Text>
        </Flex>
        {/* <Text
          size={TextSize.M}
          weight={TextWeight.BOLD}
          color={Color.SECONDARY60}
          uppercase={true}
        >
          {tokenBurnItem.typeid === 10
            ? t("quickExchange")
            : t("smartExchange")}
        </Text> */}
      </InfoWrapper>
      <InfoWrapper3>
        <TextStyledHeight
          size={TextSize.M}
          weight={TextWeight.BOLD}
          color={Color.SECONDARY60}
        >
          {tokenBurnItem.typeid === 10
            ? t("swapToUSDTRate")
            : t("tokenSellRate")}
          :
        </TextStyledHeight>
        <TextStyledHeight
          size={TextSize.M}
          weight={TextWeight.MEDIUM}
          color={Color.SECONDARY60}
        >
          {/* {"1 FWD = " + (0.5 *+tokenBurnItem.poolrate / 10e17).toFixed(5)} */}

          {"1 FWD = " +
            Math.floor((Number(tokenBurnItem.poolrate) / 10e17) * (tokenBurnItem.typeid === 10 ? 5000000 : 10000000)) /
              10000000}
        </TextStyledHeight>
      </InfoWrapper3>
      <InfoWrapper4>
        <Text
          size={TextSize.M}
          weight={TextWeight.REGULAR}
          color={Color.SECONDARY60}
        >
          {tokenBurnItem.typeid === 10 ? t("tokensSwapped") : t("tokensSold")}:
        </Text>
        <Text
          size={TextSize.M}
          weight={TextWeight.REGULAR}
          color={Color.SECONDARY60}
        >
          {t("recieved")}:
        </Text>
      </InfoWrapper4>
      <InfoWrapper5>
        <Text
          size={TextSize.M}
          weight={TextWeight.BOLD}
          color={Color.SECONDARY}
        >
          {formatPriceNumberWithCurrency({
            number: String(tokenBurnItem?.fwdamount),
          }) + " FWD"}
        </Text>
        <Text
          size={TextSize.M}
          weight={TextWeight.BOLD}
          color={Color.SECONDARY}
        >
          {formatPriceNumberWithCurrency({
            number: String(tokenBurnItem?.usdtamount),
          }) + " USDT"}
        </Text>
      </InfoWrapper5>

      <HashCopy hash={tokenBurnItem.txhash} />
    </InfoBlock>
  );
};
