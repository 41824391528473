import React from "react";

export default (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 3V6.4C15.5 6.96005 15.5 7.24008 15.609 7.45399C15.7049 7.64215 15.8578 7.79513 16.046 7.89101C16.2599 8 16.5399 8 17.1 8H20.5M10.5 8H6.5C5.39543 8 4.5 8.89543 4.5 10V19C4.5 20.1046 5.39543 21 6.5 21H12.5C13.6046 21 14.5 20.1046 14.5 19V16M16.5 3H13.7C12.5799 3 12.0198 3 11.592 3.21799C11.2157 3.40973 10.9097 3.71569 10.718 4.09202C10.5 4.51984 10.5 5.0799 10.5 6.2V12.8C10.5 13.9201 10.5 14.4802 10.718 14.908C10.9097 15.2843 11.2157 15.5903 11.592 15.782C12.0198 16 12.5799 16 13.7 16H17.3C18.4201 16 18.9802 16 19.408 15.782C19.7843 15.5903 20.0903 15.2843 20.282 14.908C20.5 14.4802 20.5 13.9201 20.5 12.8V7L16.5 3Z"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);
