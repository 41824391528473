import React from "react";

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        d="M10 7L15 12L10 17"
        stroke="white"
        stroke-width="1.6"
        stroke-linecap="round"
      />
    </g>
  </svg>
);
