import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { calculateDaysDiff, useQueryParams } from "../../../utils/utils";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { NavigationLink } from "./NavigationLink";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { ClaimTokens, whiteListForHistory } from "./ClaimTokens";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";

import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { ProcessOptions } from "./ProcessOptions";
import { getBonuses } from "../../BuyBonuses/redux/actions";
import HiddenTooltip from "../../../shared/components/HiddenTooltip";
import { ExchangeType } from "../../BuyBonuses/components/Exchange";

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;
  width: 100%;
`;

const NavigationLinkWrap = styled.div`
  width: 100%;
`;

const TooltipContent = styled(Text)`
  text-align: center;
  width: 227px;
  padding: 12px;
  border-radius: 4px;
  gap: 12px;
  background: rgba(10, 12, 20, 0.8);
`;

export enum ProcessStatus {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  CANCEL = "CANCEL",
  WAITING_SIGN = "WAITING_SIGN",
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
  INSTALL_METAMASK = "INSTALL_METAMASK",
  NETWORK_ERROR = "NETWORK_ERROR",
}

const TextLeftMargin = styled(Text)`
  margin-left: 8px;
  margin-bottom: 16px
`;

export function UserInfo() {
  const { t } = useCustomTranslation();
  const [processStatus, setProcessStatus] = useState<ProcessStatus>(
    ProcessStatus.INITIAL
  );
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);

  const [showNavLinks, setShowNavLinks] = useState(true);
  const { sid, isMobile, lang } = useQueryParams();

  const dispatch = useAppDispatch();

  const { result: bonusList } = useAppSelector((state) => state.bonuses);
  const noActiveBonuses = useMemo(
    () => !bonusList.filter((bonus) => bonus.isActive).length,
    [bonusList]
  );

  useEffect(() => {
    dispatch(getBonuses({ page: "1", limit: "9999", sid, language: lang }));
  }, []);

  const navigationLinkWrapRef = useRef<HTMLDivElement>(null);

  const [navigationLinkWrapWidth, setNavigationLinkWrapWidth] =
    useState<number>(0);

  useEffect(() => {
    const getElementWidth = () => {
      if (navigationLinkWrapRef.current) {
        const width = navigationLinkWrapRef.current.offsetWidth;
        setNavigationLinkWrapWidth(width);
      }
    };

    getElementWidth();
    window.addEventListener("resize", getElementWidth);

    return () => {
      window.removeEventListener("resize", getElementWidth);
    };
  }, []);

  return (
    <>
      {(processStatus === ProcessStatus.INITIAL && (
        <>
            <ClaimTokens
              setShowNavLinks={setShowNavLinks}
              exchangeType={ExchangeType.OFFCHAIN}
              showClaimButton={false}
            />
          {/* {!userInfo?.address ? (
            <ClaimTokens
              setShowNavLinks={setShowNavLinks}
              exchangeType={ExchangeType.OFFCHAIN}
              showClaimButton={!!userInfo?.address}
            />
          ) : (
            <ClaimTokens
              setShowNavLinks={setShowNavLinks}
              exchangeType={ExchangeType.ONCHAIN}
              showClaimButton={!!userInfo?.address}
            />
          )} */}

          {showNavLinks && (
            <LinkWrapper>
              <TextLeftMargin
                color={Color.SECONDARY80}
                weight={TextWeight.MEDIUM}
                size={TextSize.M}
              >
                {t("tokenBurnOptions")}
              </TextLeftMargin>
              {/* <HiddenTooltip
                content={
                  <TooltipContent
                    color={Color.SECONDARY}
                    size={TextSize.XS}
                    weight={TextWeight.MEDIUM}
                  >
                    {t("SmartExchangeTooltip")}
                  </TooltipContent>
                }
                timerDuration={3000}
              >
                <NavigationLinkWrap>
                  {" "}
                  <NavigationLink
                    isActive={false}
                    icon={IconType.increase}
                    title={t("smartExchange")}
                    subTitle={
                      t("availableForExchange", {daysToUnlock:String(calculateDaysDiff(
                        new Date(
                          String(process.env.REACT_APP_SMART_EXCHANGE_DATE)
                        )
                      ))}).replace(/"/g, '')
                    }
                    link={RoutesTypes.exchange}
                  ></NavigationLink>
                </NavigationLinkWrap>
              </HiddenTooltip> */}
              {userInfo?.address && <NavigationLink
                isActive={true}
                icon={IconType.arrows}
                title={t("quickExchange")}
                subTitle={userInfo?.address ? t("exchangeTokensText") : ""}
                link={RoutesTypes.quickExchange}
              ></NavigationLink>}
              <NavigationLink
                isActive={true}
                icon={IconType.sellTokens}
                title={t("cashout")}
                subTitle={userInfo?.address ? t("cashoutText") : ""}
                link={RoutesTypes.quickExchange}
                activeTag={ExchangeType.OFFCHAIN}
              ></NavigationLink>
              {noActiveBonuses ||
              (false &&
                !whiteListForHistory.find(
                  (el: string) => el === userInfo?.userId
                )) ? (
                <HiddenTooltip
                  content={
                    <TooltipContent
                      color={Color.SECONDARY}
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                    >
                      {t("noBonusesText")}
                    </TooltipContent>
                  }
                  timerDuration={3000}
                >
                  <NavigationLink
                    isActive={false}
                    icon={IconType.domino}
                    title={t("buyBonuses")}
                    subTitle={userInfo?.address ? t("buyBonusesText") : ""}
                    link={RoutesTypes.buy_bonuses}
                  ></NavigationLink>
                </HiddenTooltip>
              ) : (
                <NavigationLink
                  isActive={true}
                  icon={IconType.domino}
                  title={t("buyBonuses")}
                  subTitle={userInfo?.address ?  t("buyBonusesText") : ""}
                  link={RoutesTypes.buy_bonuses}
                ></NavigationLink>
              )}

            </LinkWrapper>
          )}
        </>
      )) || (
        <ProcessOptions
          processStatus={processStatus}
          setProcessStatus={setProcessStatus}
          // error={error}
        />
      )}
    </>
  );
}
