import { IDayInfo } from "../history.slice";

export function getOldestRecordWithStatusTwo(records: IDayInfo[]): IDayInfo | null {
  const filteredRecords = records.filter((record) => record[2]);

  if (filteredRecords.length === 0) {
    return null;
  }

  const sortedRecords = filteredRecords.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  return sortedRecords[0];
}
