import React, { useState } from "react";
import styled from "styled-components";
import { useQueryParams } from "../../../utils/utils";
import { Color } from "../../../constants/ColorEnum";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Icon } from "../../../shared/components/Icon";
import { IconType } from "../../../constants/IconType";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { BonusList } from "./BonusList";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import { getBonuses, getTags } from "../redux/actions";
import { PageCountEnum } from "../../../constants/PageCountEnum";
// import { BonusSchema } from "../../Bonuses/redux/bonus.slice";
import { BonusListItem } from "./BonusListItem";
import { getUserInfo } from "../../cashback/redux/actions";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { BonusSchema } from "../redux/bonus.slice";
import { Footer } from "../../../shared/components/Footer";

const Header = styled.div<{isMobile: string}>`
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: ${({isMobile}) => isMobile === 'true' ? '16px 0 8px' : '16px 0 8px' };
  color: ${Color.SECONDARY};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0 8px;
`;

const IconStyledWrarpper = styled.div`
  position: absolute;
  left: 4px;
  top: 4px;
  top: 0;
  cursor: pointer;
`;

const InfoBlockFaq = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  display: flex;
  color: ${Color.SECONDARY};
`;

export enum ProcessStatus {
  CARDS_LIST = "CARDS_LIST",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  CANCEL = "CANCEL",
  WAITING_SIGN = "WAITING_SIGN",
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
  WAITING_ACCOUNT_SWITCH = "WAITING_ACCOUNT_SWITCH",
  WAITING_NETWORK_SWITCH = "WAITING_NETWORK_SWITCH",
  INSTALL_METAMASK = "INSTALL_METAMASK",
  INCORRECT_ADDRESS = 'INCORRECT_ADDRESS',
}

export function BuyBonuses() {
  const dispatch = useAppDispatch();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const [openItem, setOpenItem] = useState<BonusSchema | null>(null);

  const [page, setPage] = useState("1");
  const [limit, setLimit] = useState(PageCountEnum.TWO_HUNDRED);
  const { sid, isMobile, lang } = useQueryParams();
  const [buyNow, setBuyNow] = useState(false);
  const {
    result: bonusList,
    loading,
    tags,
  } = useAppSelector((state) => state.bonuses);

  useEffectSkipFirst(() => {
    dispatch(getBonuses({ page, limit, sid, language: lang }));
    dispatch(getTags({ sid }));
    dispatch(getUserInfo({ sid }));

    //remove after implementation
    setPage("1");
    setLimit(PageCountEnum.TWO_HUNDRED);
  }, [sid]);

  const { t } = useCustomTranslation();
  const [processStatus, setProcessStatus] = useState<ProcessStatus>(
    ProcessStatus.CARDS_LIST
  );

  return (
    <Wrapper>
      <Header isMobile={isMobile}>
        {processStatus !== ProcessStatus.LOADING && (<IconStyledWrarpper
          onClick={() =>
            openItem
              ? setOpenItem(null)
              : navigateWithSearchParams({}, RoutesTypes.dashboard)
          }
        >
          <Icon icon={IconType.back} size={18} color={Color.SECONDARY} />
        </IconStyledWrarpper>)}
        {t("buyBonuses")}
      </Header>

      {openItem ? (
        <BonusListItem
          bonusItem={openItem}
          setOpenItem={(bonus) => {
            setOpenItem(bonus);
            setBuyNow(false);
          }}
          buyNow={buyNow}
          emitProcessStatus={(status) => setProcessStatus(status)}
        ></BonusListItem>
      ) : (
        <BonusList
          bonusList={bonusList}
          tagsList={tags.result?.map((i) => i.name)}
          setOpenItem={setOpenItem}
          emitBuyNow={(bonus) => {
            setBuyNow(true);
            setOpenItem(bonus);
          }}
        />
      )}
      <Footer />
    </Wrapper>
  );
}
