import React from "react";
import styled from "styled-components";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { Icon } from "../../../shared/components/Icon";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import {
  formatPriceNumberWithCurrency,
} from "../../../utils/utils";
import { ClaimStatus, ClaimStatusEnum } from "./ClaimStatus";

interface CashbackFirstLevelProps {
  date: string,
  status: ClaimStatusEnum,
  tokensUnclaimed: number,
  amount: number,
}

const Wrapper = styled.div<{ isActive?: boolean }>`
  position: relative;
  box-sizing: border-box;
  background-color: ${Color.NAVIGATION_WRAPPER};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 8px;
  gap: 16px;
  width: 100%;
  height: 80px;
  margin-bottom: 4px;

  background: ${Color.NAVIGATION_WRAPPER};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${Color.NAVIGATION_WRAPPER_HOVER};
  }
`;

const ActionBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(50% - 23px);
  height: 100%;
  justify-content: space-between;

  &:nth-child(2) {
    align-items: flex-end;
  }
`;

const StyledIcon = styled(Icon)`
  svg {
    path {
      fill: none;
    }
  }
`;

export function CashbackFirstLevelItemM({date, status, tokensUnclaimed, amount}: CashbackFirstLevelProps) {
  const { navigateWithSearchParams } = useNavigateWithSearchParams();

  return (
    <Wrapper
      onClick={() => {
        navigateWithSearchParams(
          {},
          RoutesTypes.historyCashbackSecondDetailed + `/${date}`
        );
      }}
    >
      <InfoWrapper>
        <Text
          size={TextSize.M}
          weight={TextWeight.BOLD}
          color={Color.SECONDARY}
        >
          {date}
        </Text>
        <Text
          size={TextSize.M}
          weight={TextWeight.REGULAR}
          color={Color.SECONDARY60}
        >
          {/* {t("bet") +
            " $" +
            formatPriceNumberWithCurrency({
              number: String(cashbackFirstLevelItem?.bet),
            })} */}
        </Text>
      </InfoWrapper>
      <InfoWrapper>
        <ClaimStatus
          status={status}
          tokensUnclaimed={
            tokensUnclaimed
          }
        />
        <Text
          size={TextSize.M}
          weight={TextWeight.BOLD}
          color={Color.SECONDARY}
        >
          {formatPriceNumberWithCurrency({
            number: String(amount)
          }) + " FWD"}
        </Text>
      </InfoWrapper>

      <ActionBlock>
        <StyledIcon
          icon={IconType.shevron_next}
          size={24}
          color={Color.SECONDARY}
        ></StyledIcon>
      </ActionBlock>
    </Wrapper>
  );
}
