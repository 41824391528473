export const parceValuesInString = (s) => {
  s = s.replace(/“/g, '').replace(/”/g, '')
  let result = [
    { a: 0, b: 0 },
    ...Array.from(s.matchAll(/{{.*?}}/g)).map((e) => ({
      a: e.index,
      b: e.index + e[0].length,
    })),
  ]
    .map((e, i, arr) => [
      { type: "dynamic", a: e.a, b: e.b },
      {
        type: "static",
        a: e.b,
        b: i === arr.length - 1 ? s.length : arr[i + 1].a,
      },
    ])
    .flat()
    .map((o) => ({ type: o.type, value: s.substring(o.a, o.b) }))
    .filter((e) => e.value.length > 0)
    .map((o) =>
      o.type === "static"
        ? o
        : { type: "dynamic", value: o.value.substring(2, o.value.length - 2) }
    );

  return result
};
