import styled, { keyframes } from "styled-components";
import { Color } from "../../constants/ColorEnum";

const moveAnimation = keyframes`
  0% {
    transform: translateX(-160px);
  }
  50% {
    transform: translateX(50vw);
  }
  100% {
    transform: translateX(calc(100vw + 160px));
  }
`;


const BlinkingItem = styled.div`
margin-bottom: 8px;
  display: flex;
  width: 100%;
  height: 80px;
  border-radius: 8px;
  background-color: ${Color.NAVIGATION_WRAPPER};
`;

const BlinkingElement = styled.div`
width: 160px;
height: 80px;
background: linear-gradient(90deg, rgba(32, 47, 64, 0) 0%, rgba(32, 47, 64, 0.4) 33.33%, rgba(32, 47, 64, 0.4) 66.67%, rgba(32, 47, 64, 0) 100%);
  animation: ${moveAnimation} 1s linear infinite;
`;

export const SkeletonItem = () => {
  return (
    <BlinkingItem>
      <BlinkingElement />
    </BlinkingItem>
  );
};
