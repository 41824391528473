import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";
import { formatPriceNumberWithCurrency } from "../../utils/utils";
import { Icon } from "./Icon";
import { Text } from "./Text";

const IncreaseDecreaseComponent = styled.div`
  display: inline-block;
  font-size: 16px;

  display: flex;
  align-items: center;
`;

const Triangle = styled.span`
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  &.up {
    border-bottom: 4px solid ${Color.SUCCESS_GREEN};
  }
  &.down {
    border-top: 4px solid ${Color.RED_HOT_HOOD};
  }

  margin: 0 2px 2px 0;
`;

const Value = styled(Text)<{ color: Color }>`
  color: ${({ color }) => color};
  line-height: 16px;
`;

const IconStyled = styled(Icon)`
  svg path {
    fill: ${Color.SECONDARY60};
  }
`;

export const Increase = ({ value }: { value: number }) => {
  const triangleClassName = value > 0 ? "up" : value < 0 ? "down" : "";
  const valueColor =
    value > 0
      ? Color.SUCCESS_GREEN
      : value < 0
      ? Color.RED_HOT_HOOD
      : Color.PRIMARY60;

  return (
    <IncreaseDecreaseComponent>
      <Triangle className={triangleClassName} />
      <Value size={TextSize.M} weight={TextWeight.REGULAR} color={valueColor}>
        {formatPriceNumberWithCurrency({
          number: String(Math.abs(value)),
        })}
        %
      </Value>
      <IconStyled icon={IconType.exclamation} />
    </IncreaseDecreaseComponent>
  );
};
