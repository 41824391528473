import { useMemo } from "react";
import { CashbackFirstLevelItemM } from "../../components/CashbackFirstLevelItemM";
import { ClaimStatusEnum } from "../../components/ClaimStatus";
import { IDayInfo } from "../../redux/history.slice";

export function CashbackFirstLevelDay({ dayInfo }: { dayInfo: IDayInfo }) {
  const onlyOneStatus = useMemo(
    () =>
      (dayInfo[1] && !dayInfo[2] && !dayInfo[3] && !dayInfo[20]) ||
      (!dayInfo[1] && dayInfo[2] && !dayInfo[3] && !dayInfo[20]) ||
      (!dayInfo[1] && !dayInfo[2] && dayInfo[3] && !dayInfo[20]) ||
      (!dayInfo[1] && !dayInfo[2] && !dayInfo[3] && dayInfo[20]),
    []
  );

  if (onlyOneStatus) {
    if (dayInfo[2]) {
      return (
        <CashbackFirstLevelItemM
          date={dayInfo.date}
          status={ClaimStatusEnum.UNCLAIMED}
          tokensUnclaimed={dayInfo[2]}
          amount={dayInfo[2]}
        />
      );
    } else {
      if (dayInfo[1]) {
        return (
          <CashbackFirstLevelItemM
            date={dayInfo.date}
            status={ClaimStatusEnum.CLAIMED}
            tokensUnclaimed={0}
            amount={dayInfo[1]}
          />
        );
      } else if (dayInfo[3]) {
        return (
          <CashbackFirstLevelItemM
            date={dayInfo.date}
            status={ClaimStatusEnum.EXPIRED}
            tokensUnclaimed={0}
            amount={dayInfo[3]}
          />
        );
      } else if (dayInfo[20]) {
        return (
          <CashbackFirstLevelItemM
            date={dayInfo.date}
            status={ClaimStatusEnum.OFFCHAIN}
            tokensUnclaimed={0}
            amount={dayInfo[20]}
          />
        );
      } else {
        return <></>;
      }
    }
  } else {
    if (dayInfo[2]) {
      if (dayInfo[1] && dayInfo[20]) {
        return (
          <>
            {" "}
            <CashbackFirstLevelItemM
              date={dayInfo.date}
              status={ClaimStatusEnum.UNCLAIMED}
              tokensUnclaimed={dayInfo[2]}
              amount={dayInfo[1] + dayInfo[2]}
            />
            <CashbackFirstLevelItemM
              date={dayInfo.date}
              status={ClaimStatusEnum.OFFCHAIN}
              tokensUnclaimed={0}
              amount={dayInfo[20]}
            />
          </>
        );
      } else {
        return (
          <>
            {
              <CashbackFirstLevelItemM
                date={dayInfo.date}
                status={ClaimStatusEnum.UNCLAIMED}
                tokensUnclaimed={dayInfo[2]}
                amount={
                  dayInfo[1]
                    ? dayInfo[2] + dayInfo[1]
                    : dayInfo[2] + Number(dayInfo[20])
                }
              />
            }
          </>
        );
      }
    } else {
      return (
        <>
          {dayInfo[1] && (
            <CashbackFirstLevelItemM
              date={dayInfo.date}
              status={ClaimStatusEnum.CLAIMED}
              tokensUnclaimed={0}
              amount={dayInfo[1]}
            />
          )}
          {dayInfo[3] && (
            <CashbackFirstLevelItemM
              date={dayInfo.date}
              status={ClaimStatusEnum.EXPIRED}
              tokensUnclaimed={0}
              amount={dayInfo[3]}
            />
          )}
          {dayInfo[20] && (
            <CashbackFirstLevelItemM
              date={dayInfo.date}
              status={ClaimStatusEnum.OFFCHAIN}
              tokensUnclaimed={0}
              amount={dayInfo[20]}
            />
          )}
        </>
      );
    }
  }
}
