import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Color } from '../../constants/ColorEnum';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const CircleLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  // border: 2px solid rgba(229, 233, 242, 0.4);
  // border-radius: 10px;
`;

const Circle = styled.div<{ size: number; borderWidth: number }>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  border: ${({ borderWidth }) => borderWidth}px solid rgba(229, 233, 242, 0.4);
  border-top-color: ${Color.SECONDARY};
  animation: ${spin} 1s linear infinite;
`;

const RingLoader = ({ size = 40, borderWidth = 4 }: { size?: number; borderWidth?: number }) => {
  return (
    <CircleLoaderContainer>
      <Circle size={size} borderWidth={borderWidth} />
    </CircleLoaderContainer>
  );
};

export default RingLoader;
