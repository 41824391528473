import React from "react";

export default (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.34375 3.34375H8.34375V6.15625L10.6875 7.5625L10.1875 8.375L7.34375 6.65625V3.34375ZM3.75 1.75C4.9375 0.583333 6.35417 0 8 0C9.64583 0 11.0521 0.583333 12.2188 1.75C13.4062 2.91667 14 4.33333 14 6C14 7.66667 13.4062 9.08333 12.2188 10.25C11.0521 11.4167 9.64583 12 8 12C7.3125 12 6.55208 11.8333 5.71875 11.5C4.90625 11.1458 4.26042 10.7292 3.78125 10.25L4.71875 9.28125C5.63542 10.1979 6.72917 10.6562 8 10.6562C9.29167 10.6562 10.3958 10.2083 11.3125 9.3125C12.2292 8.39583 12.6875 7.29167 12.6875 6C12.6875 4.70833 12.2292 3.61458 11.3125 2.71875C10.3958 1.80208 9.29167 1.34375 8 1.34375C6.70833 1.34375 5.60417 1.80208 4.6875 2.71875C3.79167 3.61458 3.34375 4.70833 3.34375 6H5.34375L2.65625 8.6875L2.59375 8.59375L0 6H2C2 4.33333 2.58333 2.91667 3.75 1.75Z"
      fill="white"
    />
  </svg>
);
