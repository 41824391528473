import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useParams } from "react-router";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Loading } from "../../../../constants/Loading";
import {
  ScrollArea,
  ScrollAreaSize,
} from "../../../../shared/components/ScrollArea";
import { SkeletonItem } from "../../../../shared/components/SkeletonItem";
import { useQueryParams } from "../../../../utils/utils";
import {
  CashbackSecondLevelDetailedItem,
} from "../../components/CashbackSecondLevelDetailedItem";
import { ShadowScrollWrapper } from "../../components/ShadowScrllWrapper";
import { getEventForDay } from "../../redux/actions";

const ListWrapper = styled.div`
  height: calc(100% - 220px);
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export interface IEventForDate {
  id: string;
  userId: string;
  operatorGradeId: string;
  amount: number;
  mintAmount: number;
  poolAmount: number;
  extra: string;
  multiplier: number;
  createdAt: string;
  foreignResourceId: string;
}

export const CashbackSecondDetailed = () => {
  const scrollRef = useRef<Scrollbars>(null);
  const dispatch = useAppDispatch();
  const { date } = useParams();
  const { result, loading } = useAppSelector(
    (state) => state.history.eventForDay
  );

  const { sid } = useQueryParams();

  useEffect(() => {
    if (date) {
      dispatch(getEventForDay({ sid, page: 1, limit: 9999, date }));
    }
  }, []);

  const [showTopShadow, setShowTopShadow] = useState(false);

  return (
    <>
      {(loading === Loading.SUCCESS && (
        <ShadowScrollWrapper showTopShadow={showTopShadow}>
          <ScrollArea
            size={ScrollAreaSize.S}
            ref={scrollRef}
            hideHorizontalTrack={true}
            onUpdate={(data) => setShowTopShadow(data.scrollTop > 1)}
          >
            {result &&
              result.map((item) => (
                <CashbackSecondLevelDetailedItem
                  cashbackSecondLevelDetailedItem={item}
                />
              ))}
          </ScrollArea>
        </ShadowScrollWrapper>
      )) ||
        (loading === Loading.PENDING && (
          <>
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
          </>
        )) || <></>}
    </>
  );
};
