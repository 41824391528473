import { useEffect, useState } from "react";
import styled from "styled-components";
import { Footer } from "../../../shared/components/Footer";
import { HeaderTitle } from "../../../shared/components/HeaderTitle";
import { WidgetStats } from "../../cashback/components/WidgetStats";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { Outlet } from "react-router";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { TagsListOld } from "../../BuyBonuses/components/TagListOld";
import { useLocation } from "react-router";
import { TagsList } from "../../BuyBonuses/components/TagsList";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;

  justify-content: flex-start;
  padding: 0 8px;
`;

export enum HistoryTagEnum {
  CASHBACK = "cashback",
  TOKEN_SELL_OFFCHAIN = "tokenSell",
  TOKEN_BURN = "swapToUSDT",
  BONUS_PURCHASE = "bonusPurchase",
}

export const History = () => {
  const { t } = useCustomTranslation();
  const [activeTag, setActiveTag] = useState<string>(HistoryTagEnum.CASHBACK);

  const tagsList = [
    HistoryTagEnum.CASHBACK,
    HistoryTagEnum.TOKEN_SELL_OFFCHAIN,
    HistoryTagEnum.BONUS_PURCHASE,
    HistoryTagEnum.TOKEN_BURN,
  ];
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const pathToTagMapping = {
      [RoutesTypes.historyBonusPurchase]: HistoryTagEnum.BONUS_PURCHASE,
      [RoutesTypes.historyTokenBurn]: HistoryTagEnum.TOKEN_BURN,
      [RoutesTypes.historyCashbackFirst]: HistoryTagEnum.CASHBACK,
      [RoutesTypes.historyCashbackSellTokens]: HistoryTagEnum.TOKEN_SELL_OFFCHAIN,
    };

    //@ts-ignore
    const mappedTag = pathToTagMapping[currentPath];
    if (mappedTag) {
      setActiveTag(mappedTag);
    }
  }, [location.pathname, setActiveTag]);

  return (
    <Wrapper>
      <HeaderTitle title={t("history")} toMainPage={true} withBackCross={false} />
      {/* <HeaderTitle toMainPage={true} title={t("history")} withBackCross={true} /> */}
      <TagsList
        tagsList={tagsList}
        activeTag={activeTag}
        setActiveTag={(tag) => {
          setActiveTag(tag);
          switch (tag) {
            case HistoryTagEnum.BONUS_PURCHASE:
              navigateWithSearchParams({}, RoutesTypes.historyBonusPurchase);
              break;
            case HistoryTagEnum.TOKEN_BURN:
              navigateWithSearchParams({}, RoutesTypes.historyTokenBurn);
              break;

            case HistoryTagEnum.CASHBACK:
              navigateWithSearchParams({}, RoutesTypes.historyCashbackFirst);
              break;

            case HistoryTagEnum.TOKEN_SELL_OFFCHAIN:
              navigateWithSearchParams({}, RoutesTypes.historyCashbackSellTokens);
              break;
          }
        }}
        disabledTags={[]}
        needTranslate={true}
      />
        <Outlet />
        <WidgetStats />
      <Footer />
    </Wrapper>
  );
};
