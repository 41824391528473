import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CommonInfoState, IUserInfo } from "./userInfo.slice";

export interface GetUserInfoShema {
  sid: string;
}

export const getUserInfo = createAsyncThunk(
  "userInfo/getUserInfo",
  async ({ sid }: GetUserInfoShema): Promise<IUserInfo> => {
    const {
      data
    } = await axios.get<Promise<IUserInfo>>(`${process.env.REACT_APP_API_URL}/clients/get-user-info?sid=${sid}`);

    return data;
  }
);


export const getCommonInfo = createAsyncThunk(
  "userInfo/getCommonInfo",
  async (): Promise<CommonInfoState> => {
    const {
      data
    } = await axios.get<Promise<CommonInfoState>>(`${process.env.REACT_APP_API_URL}/protocol/common-info`);

    return data;
  }
);

export const getClaimStatusBySid = createAsyncThunk(
  "userInfo/getClaimStatusBySid",
  async ({sid}: {sid: string}) => {
    const {
      data
    } = await axios.get(`${process.env.REACT_APP_API_URL}/clients/claim-status-by-sid?sid=${sid}`);

    return data;
  }
);

export const getTxStatusBySid = createAsyncThunk(
  "userInfo/getClaimStatusBySid",
  async ({sid}: {sid: string}) => {
    const {
      data
    } = await axios.get(`${process.env.REACT_APP_API_URL}/clients/tx-status-by-sid?sid=${sid}&status=5`);

    return data;
  }
);

export const removeWallet = createAsyncThunk(
  "userInfo/removeWallet",
  async ({ sid }: GetUserInfoShema) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/clients/remove-empty-wallet`, { sid });
      return data;
    } catch (error) {
      throw error;
    }
  }
);