import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { Loading } from "../../../constants/Loading";
import { setAuth } from "./actions";

export interface IUserInfo {
  email: string;
  expiresIn: number;
  token: string;
  avatar?: string;
}

export type AuthState = {
  loading: Loading;
  userInfo?: IUserInfo;
  errorMessage?: string;
};

export const initialState: AuthState = {
  loading: Loading.INITIAL,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(setAuth.pending, (state) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(setAuth.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      state.userInfo = action.payload;
      Cookies.set('token', action.payload.token);
      Cookies.set('email', action.payload.email);
      Cookies.set('expires_in', action.payload.expiresIn);
    });
    builder.addCase(setAuth.rejected, (state, action) => {
      state.errorMessage = action.error.message;
      state.loading = Loading.ERROR;
    });
  },
});

export default authSlice.reducer;
