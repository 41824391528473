import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum ShowModalEnum {
  SELECT_CONNECT_WALLET_METHOD = 'SELECT_CONNECT_WALLET_METHOD',
  NONE = "NONE",
}

export type ModalHubState = {
  shownModal: ShowModalEnum;
};

export type ModalHubActionPayload = {
  shownModal: ShowModalEnum;
};

export const initialState: ModalHubState = {
  shownModal: ShowModalEnum.NONE,
};

export const modalHubSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setShownModal: (state, action: PayloadAction<ModalHubActionPayload>) => {
      state.shownModal = action.payload.shownModal;
    },
  },
});

export const { setShownModal } = modalHubSlice.actions;

export default modalHubSlice.reducer;
