import { Color } from "../../../../constants/ColorEnum";
import { Text } from "../../../../shared/components/Text";
import { CashbackFirstLevelItem } from "../../components/CashbackFirstLevelItem";
import styled from "styled-components";
import TextAlign from "../../../../constants/TextAlign";
import { TextWeight } from "../../../../constants/TextWeight";
import { TextSize } from "../../../../constants/TextSize";
import {
  ScrollArea,
  ScrollAreaSize,
} from "../../../../shared/components/ScrollArea";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getEventJournal } from "../../redux/actions";
import { useQueryParams } from "../../../../utils/utils";
import { ShadowScrollWrapper } from "../../components/ShadowScrllWrapper";
import { SkeletonItem } from "../../../../shared/components/SkeletonItem";
import { Loading } from "../../../../constants/Loading";
import { useCustomTranslation } from "../../../locale/hooks/useCustomTranslation";
import { CashbackFirstLevelDay } from "./CashbackFirstLevelDay";

const TextStyled = styled(Text)`
  margin: 16px auto 16px 0;
`;

const NotFoundWrapper = styled.div`
  height: 256px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CashbackFirstLevel = () => {
  const { t } = useCustomTranslation();
  const scrollRef = useRef<Scrollbars>(null);
  const dispatch = useAppDispatch();

  const { result: cashbackFirstLevelItems, list, loading } = useAppSelector(
    (state) => state.history.eventsFirstLevel
  );
  const { sid } = useQueryParams();

  useEffect(() => {
    dispatch(getEventJournal({ sid, page: 1, limit: 9999 }));
  }, []);

  const [showTopShadow, setShowTopShadow] = useState(false);

  return (
    <>
      <TextStyled
        align={TextAlign.LEFT}
        weight={TextWeight.BOLD}
        color={Color.SECONDARY80}
        size={TextSize.XS}
      >
        {t("cashback")}
      </TextStyled>
      <ShadowScrollWrapper showTopShadow={showTopShadow}>
        {(loading === Loading.SUCCESS && (
          <ScrollArea
            size={ScrollAreaSize.S}
            ref={scrollRef}
            hideHorizontalTrack={true}
            onUpdate={(data) => setShowTopShadow(data.scrollTop > 1)}
          >
            {
              //   list && list.length > 0 ? (list.map((item) =>                 <CashbackSecondLevelDetailedItemTemp
              //   //@ts-ignore
              //   cashbackSecondLevelDetailedItem={item}
              // />)
              cashbackFirstLevelItems && cashbackFirstLevelItems.length > 0 ? (
                cashbackFirstLevelItems.map((item) => (
                  <CashbackFirstLevelDay dayInfo={item} />
                ))
              ) : (
                <NotFoundWrapper>
                  <Text
                    size={TextSize.M}
                    weight={TextWeight.REGULAR}
                    color={Color.SECONDARY60}
                  >
                    {t("noRecordsFound")}
                  </Text>
                </NotFoundWrapper>
              )
            }
          </ScrollArea>
        )) ||
          (loading === Loading.PENDING && (
            <>
              <SkeletonItem />
              <SkeletonItem />
              <SkeletonItem />
            </>
          )) || <></>}
      </ShadowScrollWrapper>
    </>
  );
};
