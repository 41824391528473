import React from "react";

export default (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5V13.8C1 14.9201 1 15.4802 1.21799 15.908C1.40973 16.2843 1.71569 16.5903 2.09202 16.782C2.51984 17 3.07989 17 4.2 17H15.8C16.9201 17 17.4802 17 17.908 16.782C18.2843 16.5903 18.5903 16.2843 18.782 15.908C19 15.4802 19 14.9201 19 13.8V7.7C19 7.04891 19 6.72337 18.9249 6.45712C18.736 5.78739 18.2126 5.26397 17.5429 5.07509C17.2766 5 16.9511 5 16.3 5M1 5V4.2C1 3.0799 1 2.51984 1.21799 2.09202C1.40973 1.71569 1.71569 1.40973 2.09202 1.21799C2.51984 1 3.07989 1 4.2 1H13.1C14.2201 1 14.7802 1 15.208 1.21799C15.5843 1.40973 15.8903 1.71569 16.082 2.09202C16.3 2.51984 16.3 3.0799 16.3 4.2V5M1 5H16.3M19 9H17C15.8954 9 15 9.89543 15 11C15 12.1046 15.8954 13 17 13H19V9Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
