import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loading } from "../../../constants/Loading";
import { getTranslations } from "./actions";

// export enum LangEnum {
//   EN = "en",
//   ET = "et",
//   LV = "lv",
//   ES = "es",
//   PT = "pt",
//   FR = "fr",
//   GR = "gr",
//   JP = "jp",
//   DE = "de",
// }

export enum LangEnum {
  en = 'en',
  et = 'et',
  Iv = 'Iv',
  es = 'es',
  pt = 'pt',
  fr = 'fr',
  el = 'el',
  ja = 'ja',
  de = 'de',
  vi = 'vi',
  th = 'th',
  it = 'it',
}

export type Langs = {
  [key in LangEnum]?: string;
};

export type bonusesState = {
  locale?: LangEnum;
  loading: Loading;
  translation: any;
  errorMessage?: string;
};

export const initialState: bonusesState = {
  locale: LangEnum.en,
  loading: Loading.INITIAL,
  translation: {},
};

export const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<{ locale: LangEnum }>) => {
      state.locale = action.payload.locale;
    },
    resetLocale: (state) => {
      state.locale = LangEnum.en;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTranslations.pending, (state, action) => {
      state.loading = Loading.PENDING;
      state.locale = action.meta.arg.locale;
    });
    builder.addCase(getTranslations.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      const parseObj =  action.payload.reduce((accum, {key, value}: {key: string, value: string}) => {
        //@ts-ignore
        accum[key] = value;
        return accum;
      }, {})
      state.translation[action.meta.arg.locale || LangEnum.en] = parseObj;
    });
    builder.addCase(getTranslations.rejected, (state, action) => {
      state.errorMessage = action.error.message;
      state.loading = Loading.ERROR;
    });
  },
});

export const { 
  setLocale,
  resetLocale } = localeSlice.actions;

export default localeSlice.reducer;
