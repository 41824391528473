import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { useAppSelector } from "../../../app/hooks";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { formatPriceNumberWithCurrency, useQueryParams } from "../../../utils/utils";

const WidgetStatsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: calc(100% - 16px);
  margin-top: 12px;
  bottom: 35px;
`;

const StatsLine = styled.div<{ isMobile: string }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ isMobile }) => (isMobile === "true" ? "4px" : "0")};
`;

const TextStyled = styled(Text)`
  opacity: 0.5;
`;

export function WidgetStats() {
  const { isMobile } = useQueryParams();
  const commonInfo = useAppSelector((state) => state.userInfo.commonInfo);
  const { t } = useCustomTranslation();

  return (
    <WidgetStatsWrapper>
      <StatsLine isMobile={isMobile}>
        <TextStyled size={TextSize.XXS} color={Color.SECONDARY}>
          {t("FWDInCirculation")}
        </TextStyled>
        <TextStyled size={TextSize.XXS} color={Color.SECONDARY}>
          {formatPriceNumberWithCurrency({
            number: String(
              commonInfo.info?.fwdCirculation &&
                Math.round(+commonInfo.info?.fwdCirculation * 100) / 100
            ),
          })}
        </TextStyled>
      </StatsLine>
      <StatsLine isMobile={isMobile}>
        <TextStyled size={TextSize.XXS} color={Color.SECONDARY}>
          {t("USDTinBacking")}
        </TextStyled>
        <TextStyled size={TextSize.XXS} color={Color.SECONDARY}>
          {formatPriceNumberWithCurrency({
            number: String(
              commonInfo.info?.usdtCirculation &&
                Math.round(+commonInfo.info?.usdtCirculation * 100) / 100
            ),
          })}
        </TextStyled>
      </StatsLine>
    </WidgetStatsWrapper>
  );
}
