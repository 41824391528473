import React from "react";

export default (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 13V16C11.5 16.2833 11.596 16.521 11.788 16.713C11.98 16.905 12.2173 17.0007 12.5 17C12.7833 17 13.021 16.904 13.213 16.712C13.405 16.52 13.5007 16.2827 13.5 16V13H16.5C16.7833 13 17.021 12.904 17.213 12.712C17.405 12.52 17.5007 12.2827 17.5 12C17.5 11.7167 17.404 11.479 17.212 11.287C17.02 11.095 16.7827 10.9993 16.5 11H13.5V8C13.5 7.71667 13.404 7.479 13.212 7.287C13.02 7.095 12.7827 6.99933 12.5 7C12.2167 7 11.979 7.096 11.787 7.288C11.595 7.48 11.4993 7.71733 11.5 8V11H8.5C8.21667 11 7.979 11.096 7.787 11.288C7.595 11.48 7.49933 11.7173 7.5 12C7.5 12.2833 7.596 12.521 7.788 12.713C7.98 12.905 8.21733 13.0007 8.5 13H11.5ZM12.5 22C11.1167 22 9.81667 21.7373 8.6 21.212C7.38333 20.6867 6.325 19.9743 5.425 19.075C4.525 18.175 3.81267 17.1167 3.288 15.9C2.76333 14.6833 2.50067 13.3833 2.5 12C2.5 10.6167 2.76267 9.31667 3.288 8.1C3.81333 6.88333 4.52567 5.825 5.425 4.925C6.325 4.025 7.38333 3.31267 8.6 2.788C9.81667 2.26333 11.1167 2.00067 12.5 2C13.8833 2 15.1833 2.26267 16.4 2.788C17.6167 3.31333 18.675 4.02567 19.575 4.925C20.475 5.825 21.1877 6.88333 21.713 8.1C22.2383 9.31667 22.5007 10.6167 22.5 12C22.5 13.3833 22.2373 14.6833 21.712 15.9C21.1867 17.1167 20.4743 18.175 19.575 19.075C18.675 19.975 17.6167 20.6877 16.4 21.213C15.1833 21.7383 13.8833 22.0007 12.5 22ZM12.5 20C14.7333 20 16.625 19.225 18.175 17.675C19.725 16.125 20.5 14.2333 20.5 12C20.5 9.76667 19.725 7.875 18.175 6.325C16.625 4.775 14.7333 4 12.5 4C10.2667 4 8.375 4.775 6.825 6.325C5.275 7.875 4.5 9.76667 4.5 12C4.5 14.2333 5.275 16.125 6.825 17.675C8.375 19.225 10.2667 20 12.5 20Z"
      fill="white"
    />
  </svg>
);
