import moment from "moment";
import { IEventFirstLevel } from "../actions";
import { IDayInfo } from "../history.slice";

interface ReducedResult {
  [date: string]: {
    [statusId: number]: number;
  };
}

export function formatEventsFirstLevel(result: IEventFirstLevel[]): IDayInfo[] {
  const res = result.reduce((acc: ReducedResult, item: IEventFirstLevel) => {
    const date = moment(item.eventDate).local().format("DD.MM.YYYY");
    const statusId = item.statusId;
    const amount = item.amount;

    if (!acc[date]) {
      acc[date] = {};
    }
    const prevValue = Number(acc[date][statusId]);

    acc[date][statusId] = prevValue ? prevValue + Number(amount) : Number(amount);

    return acc;
  }, {});

  const resArr = Object.entries(res).map(([key, value]) => ({
    ...value,
    date: key,
  }));

  return resArr.reverse();
}
