import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { useCustomTranslation } from "../../features/locale/hooks/useCustomTranslation";
import { AppError } from "../../shared/components/AppError";
import { Footer } from "./Footer";
import { HeaderTitle } from "./HeaderTitle";
import { useNavigateWithSearchParams } from "../../shared/hooks/useNavigateWithSearchParams";
import { RoutesTypes } from "../../constants/RoutesTypes";
import { TextWeight } from "../../constants/TextWeight";
import { TextSize } from "../../constants/TextSize";
import { Text } from "../../shared/components/Text";
import { Button, ButtonSize, ButtonType } from "../../shared/components/Button";

const Wrapper = styled.div`
  margin: auto;
  border-radius: 8px;
  width: 400px;
  height: 100vh;
  min-height: 640px;
  padding-top: 1px;
  background-color: ${Color.BACKGROUND_COLOR};
`;

const ButtonStyled = styled(Button)`
  margin-top: 8px;
`;

export const AuthError = () => {
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const { t } = useCustomTranslation();

  return (
    <Wrapper>
      <HeaderTitle withoutBack={true} title={t("error")} />
      <AppError
        fullWidth={true}
        errorMessage={t("sessionExpiredHeader")}
        extraMessage={t("sessionExpiredMessage")}
      >
        <ButtonStyled
          buttonType={ButtonType.WHITE_BORDER}
          size={ButtonSize.XL}
          onClick={() => navigateWithSearchParams({}, RoutesTypes.dashboard)}
          fullWidth={true}
        >
          <Text
            size={TextSize.L}
            color={Color.SECONDARY}
            weight={TextWeight.BOLD}
          >
            {t("backToWidget")}
          </Text>
        </ButtonStyled>
      </AppError>

      <Footer />
    </Wrapper>
  );
};
