import { Color } from "../../../../constants/ColorEnum";
import { Text } from "../../../../shared/components/Text";
import styled from "styled-components";
import TextAlign from "../../../../constants/TextAlign";
import { TextWeight } from "../../../../constants/TextWeight";
import { TextSize } from "../../../../constants/TextSize";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getPurchases } from "../../redux/actions";
import { useQueryParams } from "../../../../utils/utils";
import { useCustomTranslation } from "../../../locale/hooks/useCustomTranslation";
import { IPurchase } from "../../components/PurchaseFirstLevel";
import { useNavigateWithSearchParams } from "../../../../shared/hooks/useNavigateWithSearchParams";
import { RoutesTypes } from "../../../../constants/RoutesTypes";
import moment from "moment";
import { useParams } from "react-router";
import { PurchaseSecondLevel } from "../cashback/PurchaseSecondLevel";

const BreadcrumbsWrap = styled.div`
  display: flex;
  margin: 16px auto 16px 0;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: ${Color.SECONDARY80};
`;

const Link = styled.div`
  cursor: pointer;

  &:hover span {
    text-decoration: underline;
    color: ${Color.SECONDARY};
  }
`;

export const BonusPurchaseDetaailed = () => {
  const { t } = useCustomTranslation();
  const dispatch = useAppDispatch();
  const { sid } = useQueryParams();

  useEffect(() => {
    dispatch(getPurchases({ sid, page: 1, limit: 9999 }));
  }, []);
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const [infoItem, setInfoItem] = useState<IPurchase>();
  const { txhash } = useParams();
  const { purchasesFirstLevel } = useAppSelector((state) => state.history);

  useEffect(() => {
    if (txhash && purchasesFirstLevel.result) {
      const activeItem = purchasesFirstLevel.result.find(
        (i) => i.txhash === txhash
      );
      activeItem && setInfoItem(activeItem);
    }
  }, [txhash, purchasesFirstLevel]);

  return (
    <>
      <BreadcrumbsWrap>
        <Link
          onClick={() => {
            navigateWithSearchParams({}, RoutesTypes.historyBonusPurchase);
          }}
        >
          <Text
            align={TextAlign.LEFT}
            weight={TextWeight.BOLD}
            color={Color.SECONDARY80}
            size={TextSize.XS}
          >
            {t("bonusPurchase")}
          </Text>
        </Link>
        &nbsp;/&nbsp;
        <div>
          <Text
            align={TextAlign.LEFT}
            weight={TextWeight.BOLD}
            color={Color.SECONDARY80}
            size={TextSize.XS}
          >
            {moment(infoItem?.createdat).local().format("DD.MM.YYYY")}
          </Text>
          &nbsp;
          <Text
            align={TextAlign.LEFT}
            weight={TextWeight.BOLD}
            color={Color.SECONDARY80}
            size={TextSize.XS}
          >
            {moment(infoItem?.createdat).local().format("HH:mm")}
          </Text>
        </div>
      </BreadcrumbsWrap>
      {infoItem && <PurchaseSecondLevel infoItem={infoItem} />}
    </>
  );
};
