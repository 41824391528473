import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { Icon } from "../../../shared/components/Icon";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import {
  formatPriceNumberWithCurrency,
  useQueryParams,
} from "../../../utils/utils";
import axios from "axios";
import { AppLoading } from "../../../shared/components/AppLoader";
import { AppSuccess } from "../../../shared/components/AppSuccess";
import { AppError } from "../../../shared/components/AppError";
import { TxStatus } from "../../BuyBonuses/redux/actions";
import { HashCopy } from "../../../shared/components/HashCopy";
import {
  setBalanceIsPending,
  SUCCESS_MESSAGE_PROCESSING,
  TxHashEnum,
} from "../redux/userInfo.slice";
import { DoubleCurrency } from "../../../shared/components/DoubleCurrency";
import { BalanceLoader } from "../../../shared/components/BalanceLoader";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { DECIMALS_CONST, ExchangeType } from "../../BuyBonuses/components/Exchange";
import TextAlign from "../../../constants/TextAlign";
import { getEventJournal } from "../../History/redux/actions";
import { resetLastRecordWithStatusTwo } from "../../History/redux/history.slice";
import { HistoryLine } from "./HIstoryLine";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import { SubmitTxMessage } from "../../../shared/components/SubmitTxMessage";

export const whiteListForHistory = [
  "mocked-casino-bm-hidden-bonus-test",
  "6ca84929-a0ed-489b-8c43-3b74dc9cd953",
  "account67MM",
  "account19",
  "accountMetamask24",
  "1f788a88-3e67-4d5f-bdd3-3811410796ec",

  "48dd8758-0487-4e47-9421-8a777618f2f6",

  "0baec8a2-4399-4a78-b436-c0338c2fe678",

  "80f50ff4-7f6d-42bd-816e-2e534fbfa8ac",
  "f091055b-a494-4276-b8a0-8ea0399c1826",
  "TestHistory",
  "123",
  "0123",
  "account1a",
  "account111a",
  "09d44fd0-bde6-441d-8924-d67f22beb46c",
  "daf7d0eb-a0cb-4fdc-a05e-00feb426749a",
  "cdbb1a5c-3a45-4938-a7bb-fa6f0644dd6a",
  "account2cb",
  "account8cb",
  // "account12cb"
];

const InfoBlock = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 100%;
  background: rgba(90, 171, 229, 0.05);
  border: 1px solid rgba(90, 171, 229, 0.1);
  border-radius: 8px;

  ::after {
    content: "";
    background-color: ${Color.LINE_BALANCE};
    width: 48px;

    position: absolute;
    bottom: 3px;
    left: calc(50% - 24px);
    height: 1px;
  }
`;

const IconRewind = styled(Icon)`
  margin-right: 8px;

  svg path {
    fill: ${Color.SECONDARY};
  }
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-bottom: 12px;
`;

const BalanceLine = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 170px;
`;

const RwdWrap = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonStyled = styled(Button)`
  margin-top: 16px;
`;

const TextSecond = styled(Text)`
  line-height: 28px;
`;

const TextWithMarginTop = styled(Text)`
  margin-top: 4px;
`;

const ButtonWithMarginTop = styled(Button)`
  margin-top: 12px;
`;

export enum ProcessStatus {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
}

export const ClaimTokens = ({
  setShowNavLinks,
  hideExchange,
  exchangeType,
  showClaimButton,
}: {
  setShowNavLinks: (status: boolean) => void;
  hideExchange?: (state: boolean) => void;
  exchangeType?: ExchangeType,
  showClaimButton?: boolean,
}) => {
  const [processStatus, setProcessStatus] = useState<ProcessStatus>(
    ProcessStatus.INITIAL
  );
  const { sid, claimTokens } = useQueryParams();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const balanceIsPending = useAppSelector(
    (state) => state.userInfo.balanceIsPending
  );
  const { timeLeft, amount: tokensExpiredAmount } = useAppSelector(
    (state) => state.history.lastRecordWithStatusTwo
  );

  const tokenExpiredDays = useMemo(
    () => timeLeft && Math.floor(Number(timeLeft) / 86400000),
    [timeLeft]
  );

  const [balanceIsPendingWith5000, setBalanceIsPendingWith5000] =
    useState(false);

  useEffect(() => {
    if (balanceIsPending) {
      setBalanceIsPendingWith5000(balanceIsPending);
    } else {
      const timeout = setTimeout(() => {
        // Update the value after the timeout
        setBalanceIsPendingWith5000(!!balanceIsPending);
      }, SUCCESS_MESSAGE_PROCESSING);

      return () => clearTimeout(timeout);
    }
  }, [balanceIsPending]);

  const showSubmiTxMessage = useAppSelector(state => state.userInfo.showSubmiTxMessage)

  const dispatch = useAppDispatch();
  const [hashCreated, setHashCreated] = useState();
  const [error, setError] = useState("");
  const { t } = useCustomTranslation();

  useEffect(() => {
    setShowNavLinks(processStatus === ProcessStatus.INITIAL);
  }, [processStatus]);

  const ClaimTokens = async () => {
    hideExchange && hideExchange(true);
    try {
      setProcessStatus(ProcessStatus.LOADING);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/clients/claim-client-tokens`,
        {
          sid,
        }
      );

      if (response.data.hash) {
        setProcessStatus(ProcessStatus.WAITING_CONFIRMATION);
        setHashCreated(response.data.hash);
        dispatch(
          setBalanceIsPending({
            status: true,
            txHash: response.data.hash,
            txString: t('tokensClaimed'),
          })
        );
        dispatch(resetLastRecordWithStatusTwo());
      }
    } catch (err) {
      console.log(err, "ERROR");
      setProcessStatus(ProcessStatus.ERROR);
      //@ts-ignore
      setError(err.response.data.message);

      console.error(err);
    }
  };

  //TO DO - separate hook useTxHashSpy
  const activeTxHash = useAppSelector((state) => state.userInfo.activeTxHash);
  const activeTxHashStatus = useAppSelector(
    (state) => state.userInfo.activeTxHashStatus
  );
  const activeTxHashUsage = useAppSelector(
    (state) => state.userInfo.activeTxHashUsage
  );
  useEffect(() => {
    dispatch(getEventJournal({ sid, page: 1, limit: 9999 }));
  }, []);

  useEffect(() => {
    if (processStatus !== ProcessStatus.INITIAL) {
      if (
        activeTxHash &&
        activeTxHashStatus === TxStatus.MINED &&
        activeTxHashUsage === TxHashEnum.CLAIM
      ) {
        setProcessStatus(ProcessStatus.SUCCESS);
      }

      if (
        activeTxHashStatus === TxStatus.FAILED &&
        activeTxHashUsage === TxHashEnum.CLAIM
      ) {
        setProcessStatus(ProcessStatus.ERROR);
        setError("Transaction Error");
      }
    }

    if (
      processStatus === ProcessStatus.SUCCESS ||
      processStatus === ProcessStatus.ERROR
    ) {
      if (!activeTxHash && !activeTxHashStatus && !activeTxHashUsage) {
        setProcessStatus(ProcessStatus.INITIAL);
      }
    }
  }, [activeTxHash, activeTxHashUsage, activeTxHashStatus]);

  useEffect(() => {
    if (processStatus === ProcessStatus.WAITING_CONFIRMATION && hashCreated) {
      dispatch(
        setBalanceIsPending({
          status: true,
          txHash: hashCreated,
          txHashUsage: TxHashEnum.CLAIM,
          txString:  t('tokensClaimed'),
        })
      );
    }
  }, [processStatus]);

  const increaseButtonRef = useRef<HTMLDivElement>(null);
  const [increaseButtonWidth, setIncreaseButtonWidth] = useState<number>(0);

  useEffect(() => {
    const getElementWidth = () => {
      if (increaseButtonRef.current) {
        const width = increaseButtonRef.current.offsetWidth;
        setIncreaseButtonWidth(width);
      }
    };

    getElementWidth();
    window.addEventListener("resize", getElementWidth);

    return () => {
      window.removeEventListener("resize", getElementWidth);
    };
  }, []);

  useEffectSkipFirst(() => {
    if (claimTokens === 'true') {
      ClaimTokens();
    }
  }, [claimTokens])

  return (
    <>
      {(processStatus === ProcessStatus.INITIAL && (
        <InfoBlock>
          <BalanceWrapper>
            {/* {whiteListForHistory.find((el) => el === userInfo?.userId) && ( */}
            <HistoryLine />
            {/*  )} */}
            <BalanceLine>
              <Container>
                <RwdWrap>
                  <IconRewind
                    icon={IconType.rewind}
                    size={24}
                    color={Color.BUTTON_BLUE}
                  ></IconRewind>
                  {/* <Text
                    size={TextSize.XXL}
                    weight={TextWeight.BOLD}
                    color={Color.SECONDARY}
                  >
                    FWD
                  </Text> */}
                </RwdWrap>

                <TextWithMarginTop
                  size={TextSize.XS}
                  weight={TextWeight.REGULAR}
                  color={Color.COLOR_BLUE_TEXT_60}
                  align={TextAlign.LEFT}
                >
                  1 FWD = ${userInfo?.poolRate}
                </TextWithMarginTop>
              </Container>

              <DoubleCurrency
                value={exchangeType === ExchangeType.OFFCHAIN ? String(
                  userInfo?.offChainBalance &&
                    userInfo?.offChainBalance / DECIMALS_CONST
                ):String(
                  userInfo?.onChainBalance &&
                    userInfo?.onChainBalance / DECIMALS_CONST
                )}
                valueUsd={exchangeType === ExchangeType.OFFCHAIN ? 
                  String(
                    userInfo?.offChainBalance &&
                      ((userInfo?.offChainBalance / DECIMALS_CONST) *  Number(userInfo?.poolRate))
                  )
                  : String(
                  userInfo?.usdtOnChainBalance &&
                    userInfo?.usdtOnChainBalance / DECIMALS_CONST
                )}
                textSizeUsd={TextSize.XS}
                withFwdSign={true}
                fwdSignColor={Color.COLOR_BLUE_TEXT}
                // marginRight={`${increaseButtonWidth + 4}px`}
                // withIncreaseTooltip={true}
                // margin={12}
                // increaseTooltipMargin={"8px"}
                marginLeft={"auto"}
                cutZeros={true}
                texSizeFwd={TextSize.L}
                TextWeightFWD={TextWeight.SEMIBOLD}
                isUSDT={false}
                textColorUsd={Color.COLOR_BLUE_TEXT_60}
              ></DoubleCurrency>

              {/* <IncreaseButtonWrap ref={increaseButtonRef}>
                <IncreaseWithTooltip />
              </IncreaseButtonWrap> */}
            </BalanceLine>
            {/* <ClaimLine>
              <Text
                size={TextSize.M}
                weight={TextWeight.REGULAR}
                color={Color.SECONDARY}
              >
                {t("availableToClaim")}
              </Text>
              {!balanceIsPending && (
                <Text
                  size={TextSize.M}
                  weight={TextWeight.BOLD}
                  color={Color.GREEN_CLAIM}
                >
                  +{" "}
                  {formatPriceNumberWithCurrency({
                    number: `${
                      userInfo?.offChainBalance &&
                      +userInfo?.offChainBalance / 10e17
                    }`,
                  })}
                </Text>
              )}
            </ClaimLine> */}
          </BalanceWrapper> 
          {balanceIsPendingWith5000 ? (
            <BalanceLoader />
          ) : (showClaimButton && (
            showSubmiTxMessage && <SubmitTxMessage />
            // <>
            //   <ButtonWithMarginTop
            //     fullWidth={true}
            //     icon={IconType.claimPlus}
            //     iconSize={24}
            //     size={ButtonSize.XL}
            //     onClick={() => {
            //       ClaimTokens();
            //     }}
            //     buttonType={
            //       !+`${userInfo?.offChainBalance}` || !!balanceIsPending
            //         ? ButtonType.CLAIM_DISABLED
            //         : ButtonType.CLAIM
            //     }
            //     disabled={
            //       !+`${userInfo?.offChainBalance}` || !!balanceIsPending
            //     }
            //   >
            //     <>
            //       <Text
            //         size={TextSize.L}
            //         weight={TextWeight.BOLD}
            //         color={
            //           !!Number(userInfo?.offChainBalance)
            //             ? Color.SECONDARY
            //             : Color.SECONDARY40
            //         }
            //       >
            //         {t("claim")}{" "}
            //         {formatPriceNumberWithCurrency({
            //           number: `${
            //             userInfo?.offChainBalance &&
            //             +userInfo?.offChainBalance / 10e17
            //           }`,
            //         }) + " FWD"}
            //       </Text>
            //     </>
            //   </ButtonWithMarginTop>

            // </>
          ))}
        </InfoBlock>
      )) ||
        (processStatus === ProcessStatus.LOADING && (
          <AppLoading message={t("loading")} fullHeight={true}></AppLoading>
        )) ||
        (processStatus === ProcessStatus.SUCCESS && (
          <AppSuccess message={t("tokensClaimed")} extraMessage={""}>
            {hashCreated && <HashCopy hash={hashCreated} />}
            <ButtonStyled
              customHeight={48}
              size={ButtonSize.XL}
              onClick={() => {
                hideExchange && hideExchange(false);
                setProcessStatus(ProcessStatus.INITIAL);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                OK
              </Text>
            </ButtonStyled>
          </AppSuccess>
        )) ||
        (processStatus === ProcessStatus.WAITING_CONFIRMATION && (
          <AppLoading message={t("claimingInProgress")} fullHeight={true}>
            <TextSecond size={TextSize.M} color={Color.SECONDARY}>
              {t("tokensClaimed")}
            </TextSecond>

            {hashCreated && <HashCopy hash={hashCreated} />}
            <ButtonStyled
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                hideExchange && hideExchange(false);
                setProcessStatus(ProcessStatus.INITIAL);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonStyled>
          </AppLoading>
        )) ||
        (processStatus === ProcessStatus.ERROR && (
          <AppError errorMessage={""} extraMessage={""}>
            <Text size={TextSize.M} color={Color.SECONDARY50}>
              {error}
            </Text>
            <ButtonStyled
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                hideExchange && hideExchange(false);
                setProcessStatus(ProcessStatus.INITIAL);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonStyled>
          </AppError>
        ))}
    </>
  );
};
