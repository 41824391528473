import moment from "moment";
import ReactQuill from "react-quill";
import styled from "styled-components";
import { Color } from "../../../../constants/ColorEnum";
import { TextSize } from "../../../../constants/TextSize";
import { TextWeight } from "../../../../constants/TextWeight";
import { HashCopy } from "../../../../shared/components/HashCopy";
import { Text } from "../../../../shared/components/Text";
import { formatPriceNumberWithCurrency } from "../../../../utils/utils";
import { useCustomTranslation } from "../../../locale/hooks/useCustomTranslation";
import { CashbackGameTypeEnum } from "../../components/CashbackSecondLevelDetailedItem";
import { IPurchase } from "../../components/PurchaseFirstLevel";

const InfoBlock = styled.div`
  position: relative;
  box-sizing: border-box;
  background-color: ${Color.NAVIGATION_WRAPPER};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  width: 100%;
  margin-bottom: 4px;
  background: ${Color.NAVIGATION_WRAPPER};
  border-radius: 8px;
`;

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 8px;

  ::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
  }
`;

const InfoWrapper2 = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 12px;
  margin-bottom: 8px;

  ::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
  }
`;

const InfoWrapper3 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 4px;
  background: rgba(255, 255, 255, 0.05);
`;

const Flex = styled.div`
  display: flex;
`;

const BreadcrumbsWrap = styled.div`
  display: flex;
  margin: 16px auto 16px 0;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: ${Color.SECONDARY80};
`;

const TextQuill = styled(Text)`
  &.ql-editor {
    padding: 0 !important;
  }
`;

const InfoWrapperPrice1 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const InfoWrapperPrice2 = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 8px;

  ::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
  }
`;

export enum ClaimedStatusEnum {
  CLAIMED = "CLAIMED",
  UNCLAIMED = "UNCLAIMED",
  EXPIRED = "EXPIRED",
}

export interface IInfoItem {
  date: Date;
  type: CashbackGameTypeEnum.LIVE_CASINO; // bet type
  bet: number; // bet Placed
  amount: number; // cashback Earned
  FWDPrice: number; // 1 FWD = 0.56 USDT
  earnedFWDPrice: number;
  todaysPrice: number;
  claimedStatus: ClaimedStatusEnum;
  expired: boolean;
  claimedAmount: number;
  hash: string;
}

export const PurchaseSecondLevel = ({ infoItem }: { infoItem: IPurchase }) => {
  const { t } = useCustomTranslation();

  return (
    <>
      <InfoBlock>
        <InfoWrapper>
          <Text
            size={TextSize.M}
            weight={TextWeight.BOLD}
            color={Color.SECONDARY}
            ellipsis={true}
            lineClamp={1}
            uppercase={true}
          >
            {infoItem.title}
            {/* {moment(infoItem?.createdat).format("DD.MM.YYYYTHH:mm:ssZ")} */}
          </Text>
        </InfoWrapper>
        <InfoWrapper2>
          <TextQuill
            size={TextSize.M}
            weight={TextWeight.REGULAR}
            color={Color.SECONDARY60}
          >
            <ReactQuill
              value={infoItem.description}
              readOnly
              modules={{
                toolbar: false,
                clipboard: {
                  matchVisual: false,
                },
              }}
              theme="bubble"
            />
          </TextQuill>
        </InfoWrapper2>
        <InfoWrapperPrice1>
          <Text
            size={TextSize.M}
            weight={TextWeight.MEDIUM}
            color={Color.SECONDARY60}
          >
            {t("price")}:
          </Text>
        </InfoWrapperPrice1>
        <InfoWrapperPrice2>
          <Text
            size={TextSize.M}
            weight={TextWeight.BOLD}
            color={Color.SECONDARY}
          >
            {formatPriceNumberWithCurrency({
              number: String(infoItem?.fwdamount),
            }) + " FWD"}
          </Text>
          &nbsp;
          <Text
            size={TextSize.M}
            weight={TextWeight.REGULAR}
            color={Color.SECONDARY60}
          >
            {"(" +
              formatPriceNumberWithCurrency({
                number: String(infoItem.usdtamount),
              }) +
              " USDT)"}
          </Text>
        </InfoWrapperPrice2>

        <HashCopy hash={infoItem.txhash} isAddress={false}></HashCopy>
        {/* </InfoWrapper2> */}
      </InfoBlock>
    </>
  );
};
