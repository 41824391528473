import React from "react";

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="128"
    height="18"
    viewBox="0 0 128 18"
    fill="none"
  >
    <path
      d="M107.328 9.0949C106.425 8.49268 105.412 8.0547 104.454 7.53459C103.824 7.17873 103.167 6.87762 102.647 6.43964C101.744 5.70055 101.935 4.22236 102.866 3.56539C104.235 2.66205 106.507 3.15478 106.753 5.0162C106.753 5.04357 106.78 5.07095 106.835 5.07095H108.916C108.97 5.07095 108.998 5.04357 108.998 4.98883C108.888 3.70226 108.395 2.63468 107.492 1.95033C106.616 1.29336 105.631 0.9375 104.563 0.9375C99.0884 0.9375 98.5956 6.71338 101.552 8.54742C101.88 8.76641 104.782 10.2172 105.795 10.8468C106.808 11.4764 107.136 12.6261 106.698 13.5568C106.288 14.378 105.247 14.9529 104.207 14.8981C103.058 14.8434 102.182 14.2138 101.88 13.2557C101.826 13.0915 101.798 12.7356 101.798 12.5987C101.798 12.5714 101.771 12.5166 101.716 12.5166H99.4716C99.4442 12.5166 99.3895 12.544 99.3895 12.5987C99.3895 14.2138 99.8001 15.1171 100.895 15.9384C101.935 16.7048 103.058 17.0333 104.235 17.0333C107.301 17.0333 108.888 15.3088 109.189 13.5021C109.408 11.7228 108.888 10.1351 107.328 9.0949Z"
      fill="white"
    />
    <path
      d="M11.3005 1.23828H7.90616C7.87878 1.23828 7.85141 1.26565 7.82404 1.29303L5.98999 7.37002C5.96262 7.45214 5.85313 7.45214 5.82575 7.37002L3.9917 1.29303C3.9917 1.26565 3.96433 1.23828 3.90959 1.23828H0.487854C0.46048 1.23828 0.405731 1.26565 0.405731 1.3204V16.814C0.405731 16.8414 0.433106 16.8961 0.487854 16.8961H2.73251C2.75988 16.8961 2.81463 16.8687 2.81463 16.814V5.04324C2.81463 4.96112 2.9515 4.93375 2.97887 5.01587L4.84029 11.1202L4.97716 11.5582C4.97716 11.5856 5.00453 11.613 5.05928 11.613H6.78384C6.81121 11.613 6.83858 11.5856 6.86596 11.5582L7.00282 11.1202L8.86424 5.01587C8.89161 4.93375 9.02849 4.96112 9.02849 5.04324V16.814C9.02849 16.8414 9.05586 16.8961 9.11061 16.8961H11.3553C11.3826 16.8961 11.4374 16.8687 11.4374 16.814V1.3204C11.3826 1.26565 11.3553 1.23828 11.3005 1.23828Z"
      fill="white"
    />
    <path
      d="M73.0011 1.23828C72.9737 1.23828 72.9463 1.26565 72.919 1.29303L71.0849 7.37002C71.0576 7.45214 70.9481 7.45214 70.9207 7.37002L69.0866 1.29303C69.0866 1.26565 69.0593 1.23828 69.0045 1.23828H65.5828C65.5554 1.23828 65.5007 1.26565 65.5007 1.3204V16.814C65.5007 16.8414 65.528 16.8961 65.5828 16.8961H67.8274C67.8548 16.8961 67.9096 16.8687 67.9096 16.814V5.04324C67.9096 4.96112 68.0464 4.93375 68.0738 5.01587L69.9352 11.1202L70.0721 11.5582C70.0721 11.5856 70.0995 11.613 70.1542 11.613H71.8788C71.9061 11.613 71.9335 11.5856 71.9609 11.5582L72.0978 11.1202L73.9592 5.01587C73.9865 4.93375 74.1234 4.96112 74.1234 5.04324V16.814C74.1234 16.8414 74.1508 16.8961 74.2055 16.8961H76.4502C76.4776 16.8961 76.5323 16.8687 76.5323 16.814V1.3204C76.5323 1.29303 76.5049 1.23828 76.4502 1.23828H73.0011Z"
      fill="white"
    />
    <path
      d="M44.0943 1.23828H33.5007C33.4733 1.23828 33.4185 1.26565 33.4185 1.3204V3.26394C33.4185 3.29132 33.4459 3.34606 33.5007 3.34606H37.6067V16.814C37.6067 16.8414 37.6341 16.8961 37.6889 16.8961H39.9335C39.9609 16.8961 40.0156 16.8687 40.0156 16.814V3.34606H44.1217C44.1491 3.34606 44.2038 3.31869 44.2038 3.26394V1.3204C44.1765 1.26565 44.1491 1.23828 44.0943 1.23828Z"
      fill="white"
    />
    <path
      d="M51.9507 10.0254L53.5931 3.97575C53.6205 3.89363 53.7299 3.89363 53.7573 3.97575L55.3997 10.0254C55.3997 10.0801 55.3724 10.1349 55.3176 10.1349H52.0328C51.978 10.1349 51.9233 10.0801 51.9507 10.0254ZM57.3159 16.8688H59.369C59.4237 16.8688 59.4511 16.8141 59.4511 16.7593L55.2355 1.211C55.2355 1.18362 55.2081 1.15625 55.1534 1.15625H52.2244C52.197 1.15625 52.1696 1.18362 52.1423 1.211L47.9267 16.7593C47.9267 16.8141 47.9541 16.8688 48.0088 16.8688H50.0619C50.0892 16.8688 50.1166 16.8414 50.144 16.8141L51.3758 12.2974C51.3758 12.27 51.4032 12.2426 51.4579 12.2426H55.9746C56.002 12.2426 56.0294 12.27 56.0567 12.2974L57.2885 16.8141C57.2338 16.8414 57.2885 16.8688 57.3159 16.8688Z"
      fill="white"
    />
    <path
      d="M86.7428 10.0254L88.3852 3.97575C88.4126 3.89363 88.5221 3.89363 88.5494 3.97575L90.1919 10.0254C90.1919 10.0801 90.1645 10.1349 90.1097 10.1349H86.8249C86.7701 10.1349 86.7154 10.0801 86.7428 10.0254ZM92.108 16.8688H94.1611C94.2158 16.8688 94.2432 16.8141 94.2432 16.7593L90.0276 1.211C90.0276 1.18362 90.0002 1.15625 89.9455 1.15625H87.0165C86.9891 1.15625 86.9618 1.18362 86.9344 1.211L82.6914 16.7593C82.6914 16.8141 82.7188 16.8688 82.7736 16.8688H84.8266C84.854 16.8688 84.8813 16.8414 84.9087 16.8141L86.1405 12.2974C86.1405 12.27 86.1679 12.2426 86.2227 12.2426H90.7393C90.7667 12.2426 90.7941 12.27 90.8215 12.2974L92.0533 16.8141C92.0259 16.8414 92.0533 16.8688 92.108 16.8688Z"
      fill="white"
    />
    <path
      d="M20.4981 14.5967V9.77894C20.4981 9.75157 20.5255 9.69682 20.5803 9.69682H26.5477C26.5751 9.69682 26.6299 9.66945 26.6299 9.6147V7.67116C26.6299 7.64379 26.6025 7.58904 26.5477 7.58904H20.5803C20.5529 7.58904 20.4981 7.56166 20.4981 7.50692V3.40084C20.4981 3.37347 20.5255 3.31872 20.5803 3.31872H27.369C27.3963 3.31872 27.4511 3.29135 27.4511 3.2366V1.29306C27.4511 1.26569 27.4237 1.21094 27.369 1.21094H18.1714C18.144 1.21094 18.0892 1.23831 18.0892 1.29306V16.7593C18.0892 16.7866 18.1166 16.8414 18.1714 16.8414H27.6427C27.6701 16.8414 27.7248 16.814 27.7248 16.7593V14.7062C27.7248 14.6789 27.6974 14.6241 27.6427 14.6241H20.5529C20.5255 14.6789 20.4981 14.6515 20.4981 14.5967Z"
      fill="white"
    />
    <path
      d="M127.803 16.7592L120.057 8.73871C120.029 8.71133 120.029 8.65659 120.057 8.62921L127.037 1.37515C127.092 1.3204 127.037 1.23828 126.982 1.23828H124.135C124.108 1.23828 124.108 1.23828 124.081 1.26565L118.168 7.42477C118.113 7.47951 118.031 7.42476 118.031 7.37002V1.3204C118.031 1.29303 118.004 1.23828 117.949 1.23828H115.704C115.677 1.23828 115.622 1.26565 115.622 1.3204V16.814C115.622 16.8414 115.65 16.8961 115.704 16.8961H117.949C117.976 16.8961 118.031 16.8687 118.031 16.814V9.9979C118.031 9.94316 118.113 9.88841 118.168 9.94316L124.874 16.8687C124.874 16.8687 124.902 16.8961 124.929 16.8961H127.776C127.803 16.8961 127.858 16.7866 127.803 16.7592Z"
      fill="white"
    />
  </svg>
);
