import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export interface LoginShema {
  email: string;
  password: string;
}

export const setAuth = createAsyncThunk(
  "auth/setAuth",
  async ({ email, password }: LoginShema) => {
    const {
      data: { token, expiresIn },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/operator/login`, {
      email,
      password,
    });
    return { token, expiresIn, email };
  }
);
